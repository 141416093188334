<template>
  <form @submit.prevent="guardarPais">
    <div class="columns is-desktop">
      <div class="column">
        <b-field label="Nombre">
          <b-input
            v-model="pais.nombre"
            autofocus
            maxlength="50"
            icon="account"
            required
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Estado">
          <b-switch v-model="pais.estado"> Activo </b-switch>
        </b-field>
      </div>
    </div>

    <b-button type="is-primary" native-type="submit" class="mt-5 is-right">
      Guardar
      <span class="icon ml-2">
        <i class="fas fa-save"></i>
      </span>
    </b-button>
  </form>
</template>
<script>
import apiPaises from "@/services/paises.js";

export default {
  name: "FormularioPais",
  props: {
    id: Number,
  },
  data() {
    return {
      pais: Object,
      mensajeError: "",
      tipoMensaje: "",
    };
  },
  methods: {
    cargar(id) {
      return apiPaises.cargar(id).then((resultado) => {
        this.pais = resultado.pais;
      });
    },
    guardarPais() {
      apiPaises
        .actualizar(this.pais)
        .then((resultado) => {
          var msg = "Pais actualizado.";
          var tipo = "is-success";
          if (!this.pais.id) msg = "Pais creado";
          if (resultado.error !== undefined) {
            msg = "Se ha producido un error: <br>" + resultado.error;
            tipo = "is-danger";
            this.mostrarMensaje(msg, tipo);
            return;
          }
          this.mostrarMensaje(msg, tipo);
          this.$router.push({ path: "/paises" });
        });
    },
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
  },
  async mounted() {
    if (this.id) {
      this.cargar(this.id);
    } else {
      this.pais = apiPaises.crear();
    }
  },
};
</script>
