<template>
  <b-modal
    v-model="barraProgreso"
    has-modal-card
    trap-focus
    :can-cancel="false"
    aria-role="dialog"
    aria-label="Procesando..."
    aria-modal
    class="progreso-overlay"
  >
    <template>
      <div class="card" style="width: 500px; height: 300px">
        <div class="card-content pt-6">
          <p class="is-size-4 has-text-weight-bold mb-6">
            Realizando {{ faseProceso }}...
          </p>
          <p><strong>Procesando:</strong></p>
          <p class="pb-6">{{ textoProgreso }}</p>
          <b-progress
            type="is-primary"
            :value="valorProgreso"
            show-value
            format="percent"
            size="is-large"
          ></b-progress>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalProgreso",
  props: {
    faseProceso: String,
    textoProgreso: String,
    valorProgreso: Number,
    mostrarBarraProgreso: Boolean,
  },
  computed: {
    barraProgreso() {
      return this.mostrarBarraProgreso;
    },
  },
  methods: {
    mostrarOcultarLoader() {
      if (this.mostrarBarraProgreso) {
        document.querySelector(".loading-overlay").style.opacity = 0;
      } else {
        setTimeout(function () {
          document.querySelector(".loading-overlay").style.opacity = 1;
        }, 1000);
      }
    },
  },
  watch: {
    mostrarBarraProgreso: function () {
      this.mostrarOcultarLoader();
    },
  },
};
</script>
