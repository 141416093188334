import { listado } from "./listado";
import { backend } from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    endpoint: "",
    estado: 0,
    datosSeguimiento: 0,
    apiKey: "",
  };
}

function listar(params) {
  return listado("entornos/listar", params);
}

function listarActivos(params) {
  return listado("entornos/listarActivos", params);
}

function listarActivosEstadisticas(params) {
  return listado("entornos/listarActivosEstadisticas", params);
}

function cargar(id) {
  return backend.get("entornos/cargar&id=" + id).then((response) => {
    var data = response;

    data.entorno.estado = Boolean(data.entorno.estado);
    data.entorno.datosSeguimiento = Boolean(data.entorno.datosSeguimiento);

    return data;
  });
}

function insertar(entorno) {
  return backend.post("entornos/insertar", {
    entorno,
  });
}

function actualizar(entorno) {
  return backend.post("entornos/actualizar", {
    entorno,
  });
}

function eliminar(id) {
  return backend.post("entornos/eliminar", { id });
}

export default {
  crear,
  listar,
  listarActivos,
  listarActivosEstadisticas,
  cargar,
  insertar,
  actualizar,
  eliminar,
};
