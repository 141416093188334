<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="datosChart"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "GraficoAccesos",
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    titulo: String,
    labelsCentros: [],
    datosTasa: [],
  },
  computed: {
    datosChart: function () {
      return this.chartData;
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labelsCentros,
        datasets: [
          {
            data: this.datosTasa,
            backgroundColor: ["rgba(236, 109, 38, 0.4)"],
            borderColor: ["rgba(236, 109, 38, 1)"],
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
          title: {
            display: true,
            text: this.titulo,
          },
        },
      },
    };
  },
  watch: {
    datosTasa: function () {
      this.chartData.datasets[0].data = this.datosTasa;
      this.chartData.labels = this.labelsCentros;
    },
  },
};
</script>
