<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/significancias" active
            >Significancia</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <div class="columns">
        <div class="column is-3">
          <h2 class="title is-5 borde-puntos">Columna</h2>
        </div>
        <div class="column is-2">
          <h2 class="title is-5 borde-puntos">Valor recomendado</h2>
        </div>
        <div class="column is-2">
          <h2 class="title is-5 borde-puntos">Valor riesgo</h2>
        </div>
      </div>
      <div>
        <div class="columns" v-for="columna in columnas" :key="columna.campo">
          <div class="column is-3" v-if="columna.comparar">
            <b-checkbox v-model="columna.seleccionado">
              {{ columna.titulo }}
            </b-checkbox>
          </div>
          <b-field
            class="column is-2 mb-0 pt-0 pb-0"
            v-if="columna.comparar"
            type="is-success"
          >
            <b-input
              placeholder="Introduzca el valor..."
              type="number"
              :disabled="!columna.seleccionado"
              v-model="columna.recomendado"
              step="0.01"
            ></b-input>
          </b-field>
          <b-field
            class="column is-2 mb-0 pt-0 pb-0"
            v-if="columna.comparar"
            type="is-danger"
          >
            <b-input
              placeholder="Introduzca el valor..."
              type="number"
              :disabled="!columna.seleccionado"
              v-model="columna.riesgo"
              step="0.01"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="level-right mt-5">
        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-1 is-left"
          @click="guardar"
        >
          Aceptar
          <span class="icon ml-2 alinearBot">
            <b-icon icon="cog"></b-icon>
          </span>
        </b-button>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { esLector } from "@/services/session";
import apiSeguimiento from "@/services/seguimientos";
import apiSignificancia from "@/services/significancia";

export default {
  components: {},
  name: "SeguimientoPage",
  computed: {
    esLector,
    columnasIndicadores() {
      return this.columnas;
    },
  },
  data() {
    return {
      columnas: [],
      indicadores: [],
      mensajeError: "",
    };
  },
  methods: {
    mostrarMensaje(msg, tipo, resumen) {
      if (tipo == "is-danger") {
        this.$buefy.toast.open({
          message: msg,
          type: tipo,
        });
        return;
      }
      this.$buefy.dialog.alert({
        title: msg,
        message: resumen,
        type: tipo,
        hasIcon: true,
        icon: "info-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    async guardar() {
      if (!this.comprobarColumnas()) {
        this.mostrarMensaje("Atención", "is-primary", this.mensajeError);
        return;
      }
      await apiSignificancia.guardarSignificanciaIndicadores(this.columnas);
      this.columnas = await this.obtenerIndicadores();
    },
    async procesarIndicadores(columnas) {
      apiSignificancia.obtenerSignificanciaIndicadores().then((resultado) => {
        this.indicadores = resultado;
        for (var indicador in this.indicadores) {
          for (var columna in columnas) {
            if (
              this.indicadores[indicador].indicador == columnas[columna].campo
            ) {
              columnas[columna]["riesgo"] = this.indicadores[indicador].riesgo;
              columnas[columna]["recomendado"] =
                this.indicadores[indicador].recomendado;
              columnas[columna]["seleccionado"] = true;
            }
          }
        }
        this.columnas = columnas;
      });
    },
    async obtenerIndicadores() {
      var columnas = await apiSeguimiento.obtenerColumnasComparativa();
      this.procesarIndicadores(columnas);
    },
    comprobarColumnas() {
      for (var columna in this.columnas) {
        if (
          this.columnas[columna].seleccionado &&
          (this.columnas[columna].recomendado == "" ||
            this.columnas[columna].recomendado === undefined ||
            this.columnas[columna].riesgo == "" ||
            this.columnas[columna].riesgo === undefined)
        ) {
          this.mensajeError = "Debe introducir los valores";
          return false;
        }
      }
      this.mensajeError = "";
      return true;
    },
  },
  async mounted() {
    this.columnas = await this.obtenerIndicadores();
  },
  watch: {},
};
</script>
<style>
.borde-puntos {
  border-bottom: 1px dotted;
  padding: 0 8px 8px 4px;
}
</style>
