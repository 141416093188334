export const ACTIVO = 0;
export const BLOQUEADO = 1;

export const nombres = Object.freeze({
  [ACTIVO]: "Activo",
  [BLOQUEADO]: "Bloqueado",
});

export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});

export default { ACTIVO, BLOQUEADO, nombres, combo };
