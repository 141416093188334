import { backend } from "./backend";
import Importaciones from "@/enums/Importaciones";

function crear() {
  return {
    id: 0,
    usuarioId: 0,
    entornoOrigenId: 0,
    centroOrigenId: 0,
    entornoDestinoId: 0,
    centroDestinoId: 0,
    centroOrigen: "",
    centroDestino: "",
    aplicacion: "",
    importacion: "",
    resultado: "",
  };
}

function listar(id, entornoId, aplicacion) {
  return backend.get("historicoImportaciones/listar&centroDestinoId=" + id + "&entornoDestinoId=" + entornoId + "&aplicacion=" + aplicacion).then((response) => {
    var data = response;
    data.forEach(element => {
      element.importacion = normalizarImportacion(element.importacion, aplicacion);
    });
    return data;
  });
}

function insertar(historicoImportacion) {
  return backend.post("historicoImportaciones/insertar", {
    historicoImportacion,
  });
}

function cargarHistoricoPersonas(id, entornoId) {
  return backend.get("historicoImportaciones/cargarHistoricoPersonas&id=" + id + "&entornoId=" + entornoId).then((response) => {
    return response;
  });
}

function normalizarImportacion(importacion, aplicacion) {
  var data = JSON.parse(importacion);
  var res = [];
  for (const item in data) {
    res.push({
      'nombre': obtenerNombre(data, item, aplicacion),
      'accion': Importaciones.importacionesAcciones[data[item].accion],
      'datos': obtenerDatos(data, item, aplicacion),
      'tipoCopia': obtenerTipoCopia(data, item, aplicacion),
      'tipoParcial': obtenerTipoParcial(data, item, aplicacion),
      'resultado': data[item].resultado,
  });
  }
  return res;
}

function obtenerNombre(data, item, aplicacion) {
  if(aplicacion == "personas"){
    return Importaciones.importacionesPersonasNombres[item];
  }
  if(aplicacion == "etiquetasPermisos"){
    return Importaciones.importacionesEtiquetasPermisosNombres[item];
  }
  return data[item].nombre;
}

function obtenerDatos(data, item, aplicacion) {
  if(aplicacion != "mapasProcesos"){
    return false;
  }
  return data[item].datos;
}

function obtenerTipoCopia(data, item, aplicacion) {
  if(aplicacion != "bpm"){
    return false;
  }
  var tiposCopia = {
    'completa': "Completa",
    'parcial': "Parcial"
  };

  return tiposCopia[data[item].tipoCopia];
}

function obtenerTipoParcial(data, item, aplicacion) {
  if(aplicacion != "bpm"){
    return false;
  }
  var tiposParcial = {
    'tiposProcesos': "Procesos",
    'tiposFases': "Fases"
  };

  return tiposParcial[data[item].tipoParcial];
}

export default {
  crear,
  listar,
  insertar,
  cargarHistoricoPersonas,
};
