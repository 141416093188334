import { backend } from "./backend";

function exportar(entornoOrigen, centroOrigen, importacion, uuid) {
  return backend.post("etiquetaspermisos/exportar", {
    entornoOrigen: entornoOrigen,
    centroOrigen: centroOrigen,
    importacion: importacion,
    uuid: uuid,
  });
}

function importar(entornoDestino, centroDestino, exportacion, uuid) {
  return backend.post("etiquetaspermisos/importar", {
    entornoDestino: entornoDestino,
    centroDestino: centroDestino,
    exportacion: exportacion,
    uuid: uuid,
  });
}

export default {
  exportar,
  importar,
};
