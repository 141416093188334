<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="datosChart"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "GraficoAccesosMes",
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: "bar-chart-mes",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    titulo: String,
    labelsCentros: Array,
    labelsMeses: Array,
    datosTasa: Array,
    ordenMeses: Object,
    mostrarMas: Boolean,
  },
  computed: {
    datosChart: function () {
      return this.chartData;
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: this.titulo,
          },
        },
      },
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      colores: [
        "236,109,38,",
        "71,198,141,",
        "41,111,168,",
        "192,192,192,",
        "44,172,247,",
        "167,51,173,",
        "106,83,123,",
        "163,200,30,",
        "159,59,55,",
        "26,74,159,",
      ],
      labelsMesesEje: [],
    };
  },
  methods: {
    rellenarDatasets() {
      var datosLabels = [];
      var datosDatasets = [];
      this.labelsMesesEje = [];
      var numMes = 0;
      var datosEstructurados = [];
      var datosEstructuradosMeses = [];
      this.chartData.datasets = [];

      for (var mes in this.meses) {
        var mesActual = this.ordenMeses[this.meses[mes]];
        if (this.labelsMeses.includes(this.meses[mes])) {
          var datosMes = [];
          for (var centro in this.datosTasa) {
            if (
              Object.prototype.hasOwnProperty.call(
                this.datosTasa[centro],
                mesActual
              ) &&
              this.datosTasa[centro][mesActual] > 0
            ) {
              datosMes.push(this.datosTasa[centro][mesActual]);
            } else {
              datosMes.push(null);
            }
            if (numMes == 0) {
              datosEstructuradosMeses.push(
                this.labelsCentros[centro][
                  this.labelsCentros[centro].findIndex(
                    (element) => typeof element !== "undefined"
                  )
                ]
              );
            }
          }
          datosEstructurados[numMes] = datosMes;
          numMes++;
          this.labelsMesesEje.push(this.meses[mes]);
        }
      }

      var datosDataSet = [];
      for (var datoEstructurado in datosEstructurados) {
        for (var tasa in datosEstructurados[datoEstructurado]) {
          if (!Object.prototype.hasOwnProperty.call(datosDataSet, tasa))
            datosDataSet[tasa] = [];
          datosDataSet[tasa].push(datosEstructurados[datoEstructurado][tasa]);
        }
      }

      var ordenados = {};
      this.chartData.labels = this.labelsMesesEje;
      if (this.mostrarMas) {
        ordenados = this.ordenarGraficoMas(
          datosEstructuradosMeses,
          datosDataSet
        );
      } else {
        ordenados = this.ordenarGraficoMenos(
          datosEstructuradosMeses,
          datosDataSet
        );
      }
      datosLabels = ordenados.labels;
      datosDatasets = ordenados.datosTasa;

      for (var datoDataset in datosDatasets) {
        var color = this.colores[datoDataset];
        var dataset = {
          label: datosLabels[datoDataset],
          data: datosDatasets[datoDataset],
          backgroundColor: ["rgba(" + color + "0.4)"],
          borderColor: ["rgba(" + color + "1)"],
          borderWidth: 1,
          borderRadius: 5,
          skipNull: true,
        };
        this.chartData.datasets.push(dataset);
      }
    },
    random_rgb() {
      var o = Math.round,
        r = Math.random,
        s = 255;
      return o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ",";
    },
    ordenarGraficoMas(labels, datos) {
      var labelsOrdenadas = [];

      var mapped = datos.map(function (el, i) {
        return { index: i, value: el };
      });

      mapped.sort(function (e1, e2) {
        if (e1.value[0] < e2.value[0]) {
          return 1;
        }
        if (e1.value[0] > e2.value[0]) {
          return -1;
        }
        return 0;
      });

      var datosOrdenados = mapped.map(function (el) {
        labelsOrdenadas.push(labels[el.index]);
        return el.value;
      });

      return {
        labels: labelsOrdenadas,
        datosTasa: datosOrdenados,
      };
    },
    ordenarGraficoMenos(labels, datos) {
      var labelsOrdenadas = [];

      var mapped = datos.map(function (el, i) {
        return { index: i, value: el };
      });

      mapped.sort(function (e1, e2) {
        if (e1.value[0] > e2.value[0]) {
          return 1;
        }
        if (e1.value[0] < e2.value[0]) {
          return -1;
        }
        return 0;
      });

      var datosOrdenados = mapped.map(function (el) {
        labelsOrdenadas.push(labels[el.index]);
        return el.value;
      });

      return {
        labels: labelsOrdenadas,
        datosTasa: datosOrdenados,
      };
    },
  },
  mounted() {
    this.rellenarDatasets();
  },
  watch: {
    datosTasa: function () {
      this.rellenarDatasets();
    },
  },
};
</script>
