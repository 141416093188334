<template>
  <div>
    <div class="columns is-desktop">
      <div class="column">
        <b-field label="Nombre">
          <b-input
            v-model="centro.dc_centro"
            autofocus
            maxlength="50"
            icon="form-textbox"
            required
            ref="dc_centro"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Descripcion">
          <b-input
            v-model="centro.dl_centro"
            autofocus
            maxlength="50"
            icon="card-text"
            required
            ref="dl_centro"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns is-desktop">
      <div class="column is-2">
        <b-field label="Centro Padre">
          <b-select
            placeholder="Centro Padre"
            icon="office-building"
            v-model="centro.nivelHomologado"
            required
            ref="nivelHomologado"
          >
            <option
              v-for="centro in datosAuxiliares.centrosPadre"
              :key="centro.valor"
              :value="centro.valor"
            >
              {{ centro.texto }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Responsable cartera">
          <b-select
            placeholder="Responsable"
            icon="account-lock"
            v-model="centro.id_responsable_cartera"
            required
            ref="id_responsable_cartera"
          >
            <option
              v-for="responsable in datosAuxiliares.responsablesCartera"
              :key="responsable.id"
              :value="responsable.id"
            >
              {{ responsable.nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="País">
          <b-select
            placeholder="País"
            icon="earth"
            v-model="centro.id_pais"
            required
            ref="paisId"
          >
            <option v-for="pais in paises" :key="pais.id" :value="pais.id">
              {{ pais.nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Idioma">
          <multiselect
            v-model="centro.lista_idiomas"
            label="dc_idioma"
            track-by="id_idioma"
            placeholder="Buscar idioma..."
            selectLabel="Pulsa enter para seleccionar"
            open-direction="bottom"
            :preserve-search="true"
            :options="idiomas"
            :multiple="true"
            :searchable="true"
            :loading="isLoading"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="1000"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="selectTag">
                <span>{{ option.dc_idioma }}</span>
                <span class="ml-2" @click="remove(option)">
                  <i class="fas fa-times-circle"></i>
                </span>
              </span>
            </template>
            <span slot="noResult">No se ha encontrado ningún idioma.</span>
          </multiselect>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Zona horaria">
          <b-select
            placeholder="Zona horaria"
            icon="web-clock"
            v-model="centro.horario"
            required
            ref="horario"
          >
            <option value="-12">
              (GMT -12:00) Línea internacional de fecha del Oeste
            </option>
            <option value="-11">(GMT -11:00) Isla Midway</option>
            <option value="-10">(GMT -10:00) Hawai</option>
            <option value="-9">(GMT -9:00) Alaska</option>
            <option value="-8">
              (GMT -8:00) Hora del Pacifico (EEUU y Canadá)
            </option>
            <option value="-7">(GMT -7:00) Arizona</option>
            <option value="-6">(GMT -6:00) America Central</option>
            <option value="-5">(GMT -5:00) Bogota</option>
            <option value="-4">(GMT -4:00) Caracas</option>
            <option value="-3">(GMT -3:00) Brasilia</option>
            <option value="-2">(GMT -2:00) Atlantico Central</option>
            <option value="-1">(GMT -1:00) Azores</option>
            <option value="0">(GMT 0:00) Londres</option>
            <option value="1">(GMT +1:00) Madrid</option>
            <option value="2">(GMT +2:00) Atenas</option>
            <option value="3">(GMT +3:00) Baghdad</option>
            <option value="4">(GMT +4:00) Abu Dhabi</option>
            <option value="5">(GMT +5:00) Ekaterimburgo</option>
            <option value="6">(GMT +6:00) Almaty</option>
            <option value="7">(GMT +7:00) Bangkok</option>
            <option value="8">(GMT +8:00) Hong Kong</option>
            <option value="9">(GMT +9:00) Seul</option>
            <option value="10">(GMT +10:00) Sidney</option>
            <option value="11">(GMT +11:00) Magadï¿œn</option>
            <option value="12">(GMT +12:00) Hong Kong</option>
            <option value="13">(GMT +13:00) Auckland</option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-2">
        <b-field label="Fecha de Alta">
          <b-datepicker
            v-model="fechaIni"
            locale="es-ES"
            placeholder="Click para seleccionar..."
            icon="calendar-today"
            :icon-right="centro.fechaIni ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            trap-focus
            required
            ref="fechaIni"
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Fecha de Renovación">
          <b-datepicker
            v-model="fechaFin"
            locale="es-ES"
            placeholder="Click para seleccionar..."
            icon="calendar-today"
            :icon-right="centro.fechaFin ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            trap-focus
            required
            ref="fechaFin"
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-2">
        <b-field label="Modalidad">
          <b-select
            placeholder="Modalidad"
            icon="ab-testing"
            v-model="centro.tipo_centro"
            required
            ref="tipo_centro"
          >
            <option value="0" selected="">Estandar</option>
            <option value="1">Enterprise</option>
            <option value="2" disabled="">Basic</option>
            <option value="3" disabled="">Professional</option>
            <option value="4" disabled="">Corporate</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Nº de usuarios">
          <b-numberinput
            v-model="centro.numUsr"
            :min="1"
            icon="account-group"
            placeholder="Número de usuarios"
            required
            ref="numUsr"
          ></b-numberinput>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop" v-if="centro.tipo_centro == 1">
      <div class="column is-2">
        <b-field label="Nº de Administradores">
          <b-numberinput
            v-model="centro.numAdm"
            :min="1"
            icon="account-group"
            placeholder="Número de Administradores"
            required
            ref="numAdm"
          ></b-numberinput>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Nº de Responsables">
          <b-numberinput
            v-model="centro.numResp"
            :min="1"
            icon="account-group"
            placeholder="Número de Responsables"
            required
            ref="numResp"
          ></b-numberinput>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Nº de Operativos">
          <b-numberinput
            v-model="centro.numOper"
            :min="1"
            icon="account-group"
            placeholder="Número de Operativos"
            required
            ref="numOper"
          ></b-numberinput>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Nº de Lectores">
          <b-numberinput
            v-model="centro.numLec"
            :min="1"
            icon="account-group"
            placeholder="Número de Lectores"
            required
            ref="numLec"
          ></b-numberinput>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-2">
        <b-field label="Chat">
          <b-switch
            v-model="centro.tieneChat"
            true-value="Activo"
            false-value="Inactivo"
            >{{ centro.tieneChat }}</b-switch
          >
        </b-field>
      </div>
      <div class="column is-2" v-if="centro.tieneChat == 'Activo'">
        <b-field label="Canal">
          <b-select
            placeholder="Canal"
            icon="chat-processing"
            v-model="centro.numCanal"
            required
            ref="numCanal"
          >
            <option value="90_ISOTools_Colombia">90_ISOTools_Colombia</option>
            <option value="90_ISOTools_Chile">90_ISOTools_Chile</option>
            <option value="90_ISOTools_Peru">90_ISOTools_Peru</option>
            <option value="90_ISOTools_Mexico">90_ISOTools_Mexico</option>
            <option value="90_ISOTools_Argentina_Uruguay">
              90_ISOTools_Argentina_Uruguay
            </option>
            <option value="90_ISOTools_Brasil">90_ISOTools_Brasil</option>
            <option value="90_ISOTools_America">90_ISOTools_America</option>
            <option value="90_ISOTools_Espana_Portugal">
              90_ISOTools_Espana_Portugal
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Editor Only Office">
          <b-switch
            v-model="centro.editor_only_office"
            true-value="Activo"
            false-value="Inactivo"
            >{{ centro.editor_only_office }}</b-switch
          >
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Modo editor">
          <b-radio v-model="centro.modo_editor" name="editor" native-value="0">
            Ninguno
          </b-radio>
          <b-radio v-model="centro.modo_editor" name="editor" native-value="1">
            Base
          </b-radio>
          <b-radio v-model="centro.modo_editor" name="editor" native-value="2">
            Avanzado
          </b-radio>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-2">
        <b-field label="Tipología">
          <b-select
            placeholder="Tipología"
            icon="select-group"
            v-model="centro.tipologia_centro"
            required
            ref="tipologia_centro"
          >
            <option
              v-for="tipologia in datosAuxiliares.tipologias"
              :key="tipologia.valor"
              :value="tipologia.valor"
            >
              {{ tipologia.texto }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Tipo">
          <b-select
            placeholder="Tipo"
            icon="bookmark-multiple"
            v-model="centro.centroFacturable"
            required
            ref="centroFacturable"
          >
            <option value="0">Demo</option>
            <option value="1">Soporte</option>
            <option value="2">Nuevo</option>
            <option value="3">Soporte incremental</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field>
          <template #label>
            Tipología Ex
            <b-tooltip
              position="is-bottom"
              multilined
              type="is-white is-light"
              size="is-large"
            >
              <b-icon icon="information" size="is-small" type="is-primary">
              </b-icon>
              <template v-slot:content>
                <div class="has-text-left">
                  <strong class="text-primary"><span>EX6 :</span></strong> El
                  centro entero funcionará con la interfaz EX6.<br />
                  <strong class="text-primary"><span>Mixto :</span></strong>
                  Centro Mixto, combina apps EX5 con apps EX6.<br />
                  <strong class="text-primary"><span>EX5 :</span></strong> El
                  centro entero funcionará con la interfaz EX5.<br />
                </div>
              </template>
            </b-tooltip>
          </template>
          <b-select
            placeholder="Tipología Ex"
            icon="cards-variant"
            v-model="centro.tipologia_ex"
            required
            ref="tipologia_ex"
          >
            <option value="1">EX6</option>
            <option value="2">Mixto</option>
            <option value="3">EX5</option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-2">
        <b-field label="Alcance">
          <b-select placeholder="Alcance" icon="strategy" v-model="alcance">
            <option value="1">Gestión Estratégica</option>
          </b-select>
          <b-button
            type="is-text"
            @click="quitarAlcance"
            v-if="alcance !=''"
          >
            <b-icon icon="close-circle" type="is-grey"> </b-icon>
          </b-button>
        </b-field>
      </div>
      <div class="column is-2" v-if="alcance">
        <b-field label="Modelo predeterminado">
          <b-select
            placeholder="Modelo predeterminado"
            icon="file-tree"
            v-model="centro.modeloPredeterminado"
            required
            ref="modeloPredeterminado"
          >
            <option value="3">Modelo en tres niveles</option>
            <option value="4">Modelo en cuatro niveles</option>
            <option value="2">Modelo estándar en tres niveles</option>
            <option value="5">Modelo en cuatro niveles con procesos</option>
            <option value="1">Modelo estándar en dos niveles</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Suscripción">
          <b-select
            placeholder="Suscripción"
            v-model="centro.estadoSuscripcion"
            icon="cash"
            required
            ref="estadoSuscripcion"
          >
            <option
              v-for="suscripcion in datosAuxiliares.suscripciones"
              :key="suscripcion.VALOR"
              :value="suscripcion.VALOR"
            >
              {{ suscripcion.TEXTO }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Estado">
          <b-field>
            <b-switch
              v-model="centro.estado"
              true-value="Activo"
              false-value="Inactivo"
              >{{ centro.estado }}</b-switch
            >
          </b-field>
        </b-field>
      </div>
    </div>
  </div>
</template>
<script>
import apiPaises from "@/services/paises.js";
import EstadosCentros from "@/enums/EstadosCentros";
import Multiselect from "vue-multiselect";

export default {
  name: "FichaFormulario",
  components: { Multiselect },
  props: {
    id: Number,
    datosAuxiliares: {
      idiomas: Array,
      responsablesCartera: Array,
      centrosPadre: Array,
    },
    centroOrigen: Object,
  },
  computed: {
    estados() {
      return EstadosCentros.combo;
    },
    alcance: {
      get() {
        return this.centro.alcance;
      },
      set(value) {
        if (value == undefined) {
          return;
        }
        this.centro.alcance = value;
      },
    },
    fechaIni: {
      get() {
        if (this.centro.fechaIni != "") return new Date(this.centro.fechaIni);
        return new Date();
      },
      set(value) {
        this.centro.fechaIni = value;
      },
    },
    fechaFin: {
      get() {
        if (this.centro.fechaFin != "") {
          return new Date(this.centro.fechaFin);
        }
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        return new Date(year + 1, month, day);
      },
      set(value) {
        this.centro.fechaFin = value;
      },
    },
    idiomas() {
      if (
        this.datosAuxiliares == undefined ||
        this.datosAuxiliares.idiomas == undefined
      ) {
        return [];
      }
      return this.datosAuxiliares.idiomas;
    },
    centro() {
      return this.centroOrigen;
    },
  },
  data() {
    return {
      sedes: [],
      paises: [],
      mensajeError: "",
      tipoMensaje: "",
      isLoading: false,
      pagina: 0,
      tienePaginaSiguiente: true,
      responsablesCartera: [],
    };
  },
  methods: {
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
    clearDate() {
      this.centro.fechaIni = null;
      this.centro.fechaFin = null;
    },
    quitarAlcance() {
      this.alcance = "";
      this.centro.modeloPredeterminado = "";
    },
  },
  async mounted() {
    this.paises = await apiPaises.listar({ filtros: { estado: "1" } });
  },
};
</script>
<style>
.datepicker.control .dropdown-item:hover {
  background: #fff;
}

.datepicker.control .dropdown-content {
  width: 320px;
}
</style>
