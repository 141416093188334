<template>
  <b-table
    :data="isEmpty ? [] : data"
    :bordered="isBordered"
    :striped="isStriped"
    :narrowed="isNarrowed"
    :hoverable="isHoverable"
    :loading="isLoading"
    :focusable="isFocusable"
    :mobile-cards="hasMobileCards"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="isPaginationSimple"
    :pagination-position="paginationPosition"
    :default-sort-direction="defaultSortDirection"
    :pagination-rounded="isPaginationRounded"
    :sort-icon="sortIcon"
    :sort-icon-size="sortIconSize"
    default-sort="id"
    aria-next-label="Siguiente"
    aria-previous-label="Anterior"
    aria-page-label="Página"
    aria-current-label="Actual"
    :page-input="hasInput"
    :pagination-order="paginationOrder"
    :page-input-position="inputPosition"
    :debounce-page-input="inputDebounce"
  >

    <b-table-column
      field="significancia"
      label="Significancia"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ nombreColumna(props.row.significancia) }}
    </b-table-column>
    <b-table-column
      field="valorReal"
      label="Valor Real"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.valorReal }}
    </b-table-column>

    <b-table-column
      field="valorRiesgo"
      label="Valor Riesgo"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.valorRiesgo }}
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">No hay resultados</div>
    </template>
  </b-table>
</template>
<script>
import { formatDateTabla } from "@/services/date";
import apiSignificancia from "@/services/seguimientos";

export default {
  props: {
    resultado: {
      avisos: [],
      resultadosPorPagina: 10,
      pagina: 1,
    },
    mostrarFiltros: Boolean,
  },
  computed: {
    data() {
      return JSON.parse(this.resultado.avisos);
    },
    currentPage: {
      get() {
        return this.paginaActual;
      },
      set(value) {
        this.paginaActual = value;
      },
    },
    perPage() {
      return this.resultado.resultadosPorPagina;
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      paginaActual: null,
      nombresColumnas: [],
    };
  },
  methods: {
    leerAviso(aviso) {
      this.$emit("leerAviso", aviso);
    },
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    estaLeido(estado) {
      return Boolean(estado);
    },
    claseFila(){
      return "fila-enlace";
    },
    nombreColumna(columna) {
      var nombre = columna;
      for (var col in this.nombresColumnas){
        if( this.nombresColumnas[col].campo == columna){
          return this.nombresColumnas[col].titulo;
        }

      }
      return nombre;
    }
  },
  mounted() {
    this.paginaActual = this.resultado.paginaActual;
    this.nombresColumnas = apiSignificancia.obtenerColumnasTabla();
  },
};
</script>
