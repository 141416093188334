<template>
  <form @submit.prevent="guardarSede">
    <div class="columns is-desktop">
      <div class="column">
        <b-field label="Nombre">
          <b-input
            v-model="sede.nombre"
            autofocus
            maxlength="50"
            icon="account"
            required
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Estado">
          <b-switch v-model="sede.estado"> Activo </b-switch>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column">
        <label class="label">Países</label>
        <multiselect
          v-model="paisesSeleccionados"
          id="ajax"
          label="nombre"
          track-by="id"
          placeholder="Buscar país..."
          selectLabel="Pulsa enter para seleccionar"
          open-direction="bottom"
          :preserve-search="true"
          :options="paises"
          :multiple="true"
          :searchable="true"
          :loading="isLoading"
          :internal-search="true"
          :clear-on-select="false"
          :close-on-select="false"
          :options-limit="1000"
          :max-height="600"
          :show-no-results="true"
          :hide-selected="true"
        >
          <template slot="tag" slot-scope="{ option, remove }"
            ><span class="selectTag"
              ><span>{{ option.nombre }}</span
              ><span class="ml-2" @click="remove(option)">
                <i class="fas fa-times-circle"></i> </span></span
          ></template>
          <span slot="noResult">No se ha encontrado ningún país.</span>
        </multiselect>
      </div>
    </div>
    <b-button type="is-primary" native-type="submit" class="mt-5 is-right">
      Guardar
      <span class="icon ml-2">
        <i class="fas fa-save"></i>
      </span>
    </b-button>
  </form>
</template>
<script>
import apiSedes from "@/services/sedes.js";
import apiPaises from "@/services/paises.js";
import Multiselect from "vue-multiselect";

export default {
  name: "FormularioSede",
  components: { Multiselect },
  props: {
    id: Number,
  },
  data() {
    return {
      sede: Object,
      mensajeError: "",
      tipoMensaje: "",
      paisesSeleccionados: [],
      paises: [],
      isLoading: false,
    };
  },
  methods: {
    cargar(id) {
      return apiSedes.cargar(id).then((resultado) => {
        this.sede = resultado.sede;
        this.paisesSeleccionados = this.cargarPaisesSede(
          resultado.paises
        );
      });
    },
    guardarSede() {
      apiSedes
        .actualizar(this.sede, this.paisesSeleccionados)
        .then((resultado) => {
          var msg = "Sede actualizada.";
          var tipo = "is-success";
          if (!this.sede.id) msg = "Sede creada";
          if (resultado.error !== undefined) {
            msg = "Se ha producido un error: <br>" + resultado.error;
            tipo = "is-danger";
            this.mostrarMensaje(msg, tipo);
            return;
          }
          this.mostrarMensaje(msg, tipo);
          this.$router.push({ path: "/sedes" });
        });
    },
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
    async listarPaises() {
      return apiPaises.autocompletar().then((respuesta) => {
        this.paises = respuesta;
      });
    },
    async cargarPaisesSede(paises) {
      var params = {
        paisesIds: paises,
      };
      return apiPaises.cargarConIds(params).then((respuesta) => {
        this.paisesSeleccionados = respuesta;
      });
    },
  },
  async mounted() {
    if (this.id) {
      this.cargar(this.id);
    } else {
      this.sede = apiSedes.crear();
    }
    this.listarPaises();
  },
};
</script>
