import { listado } from "./listado";
import { backend } from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    apellidos: "",
    email: "",
    clave: "",
    rol: 0,
    sedeId: 0,
    bloqueado: 0,
  };
}

function listar(params) {
  return listado("usuarios/listar", params);
}

function cargar(id) {
  return backend.get("usuarios/cargar&id=" + id).then((response) => {
    var data = response;

    data.usuario.bloqueado = Boolean(data.usuario.bloqueado);

    return data;
  });
}

function insertar(usuario) {
  return backend.post("usuarios/insertar", {
    usuario,
  });
}

function actualizar(usuario, entornos, entornosResponsable, miCuenta = false) {
  return backend.post("usuarios/guardar", {
    usuario,
    entornos,
    entornosResponsable,
    miCuenta,
  });
}

function eliminar(id) {
  return backend.post("usuarios/eliminar", { id });
}

function comprobarClave(clave) {
  return backend.post("usuarios/comprobarClave", { clave });
}

function cargarCentros() {
  return backend.get("usuarios/cargarCentros").then((response) => {
    return response;
  });
}

export default {
  crear,
  listar,
  cargar,
  insertar,
  actualizar,
  eliminar,
  comprobarClave,
  cargarCentros,
};
