<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/gestion-centros"
            >Centros</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" to="/gestion-centros/crear" active
            >Crear</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <formulario :entornoId="entornoId"></formulario>
    </div>
  </app-layout>
</template>

<script>
import Formulario from '../gestionCentros/Formulario.vue';

export default {
  components: { Formulario },
  name: "CrearCentro",
  data() {
    return {
      entornoId: parseInt(this.$route.params.entornoId),
    };
  },
};
</script>
