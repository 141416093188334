<template>
  <div class="container is-fluid">
    <div class="columns columnas-body">
      <div class="column app-sidebar">
        <page-sidebar @logout="cerrarSesion" :numeroAvisos="numeroAvisos"></page-sidebar>
      </div>
      <div class="column is-fullheight app-content">
        <banner-pruebas v-if="mostrarBannerDePruebas"></banner-pruebas>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import PageSidebar from "@/views/common/sidebar.vue";
import BannerPruebas from "@/components/BannerPruebas.vue";
import { mostrarBannerDePruebas } from "@/settings";
import { logout } from "@/services/login";
export default {
  components: { PageSidebar, BannerPruebas },
  props: {
    numeroAvisos: Number,
  },
  computed: {
    mostrarBannerDePruebas: () => mostrarBannerDePruebas,
  },
  methods: {
    async cerrarSesion() {
      await this.$parent.$parent.cerrarSesion();
      await logout();
      this.$router.push("/login");
    },
  },
};
</script>
