<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/paises"
            >Paises</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" to="/paises/crear" active
            >Crear</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <formulario-pais></formulario-pais>
    </div>
  </app-layout>
</template>

<script>
import FormularioPais from "./Formulario.vue";

export default {
  components: { FormularioPais },
  name: "EditarPais",
  data() {
    return {};
  },
};
</script>
