<template>
  <section class="mb-6">
    <div class="card p-5 mt-3 mb-3" v-if="seleccionarColumnas">
      <b-field grouped group-multiline>
        <div v-for="(columna, index) in columnas" :key="index" class="control">
          <b-checkbox v-model="columna.visible">
            {{ columna.titulo }}
          </b-checkbox>
        </div>
      </b-field>
    </div>
    <b-table
      :data="isEmpty ? [] : dataTabla"
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :pagination-rounded="isPaginationRounded"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :default-sort="defaultSort"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      :page-input="hasInput"
      :pagination-order="paginationOrder"
      :page-input-position="inputPosition"
      :debounce-page-input="inputDebounce"
      :scrollable="isScrollable"
    >
      <b-table-column
        v-for="columna in columnas"
        :key="columna.campo"
        :field="columna.campo"
        :label="columna.titulo"
        :visible="columna.visible"
        :centered="columna.centrada"
        v-slot="props"
        sortable
        :searchable="mostrarFiltros"
      >
        <span v-if="columna.tipoFecha">{{
          fechaFormateada(props.row[columna.campo])
        }}</span>
        <span v-if="!columna.tipoFecha">{{ props.row[columna.campo] }}</span>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">No hay resultados</div>
      </template>
    </b-table>
  </section>
</template>
<script>
import { formatDateTabla } from "@/services/date";
import apiDashboard from "@/services/dashboard";

export default {
  props: {
    datos: [],
    mostrarFiltros: Boolean,
    seleccionarColumnas: Boolean,
    ordenColumna: Array,
  },
  computed: {
    dataTabla() {
      return this.datos;
    },
    currentPage: {
      get() {
        return this.paginaActual;
      },
      set(value) {
        this.paginaActual = value;
      },
    },
    perPage() {
      return this.resultadosPorPagina;
    },
    defaultSort() {
      return this.ordenColumna;
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      isScrollable: true,
      hasMobileCards: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      paginaActual: 1,
      resultadosPorPagina: 10,
      pagina: 1,
      columnas: [],
    };
  },
  methods: {
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
  },
  async mounted() {
    this.columnas = apiDashboard.obtenerColumnasTabla();
  },
  watch: {
    columnas: {
      handler: function () {
        if (this.columnas.length > 0)
          localStorage.columnasTabla = JSON.stringify(this.columnas);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
