<template>
  <login-layout>
    <logotipo-login />
    <login-form @ok="ok" />
  </login-layout>
</template>
<script>
  import LogotipoLogin from '@/components/LogotipoLogin';
  import LoginForm from '@/components/LoginForm';
  export default {
    components: {LogotipoLogin, LoginForm},
    methods: {
      ok(){
        this.$buefy.toast.open("Sesión iniciada correctamente.");
        const r = this.$route.query.redirect || "/"
        this.$router.push(r);
      }
    }
  }
</script>
