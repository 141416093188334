<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/centros"
            >Configurador de Centros</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" :to="`/centros/editar/${id}`">{{
            centro.nombre
          }}</b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            :to="`/centros/${aplicacion}/${id}/${entornoId}`"
            >Importar configuración de {{ aplicacion }}</b-breadcrumb-item
          >
          <b-breadcrumb-item
            tag="router-link"
            :to="`/centros/${aplicacion}/historico/${id}/${entornoId}`"
            active
            >Histórico</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <div class="level-right">
        <b-switch v-model="mostrarFiltros">Mostrar filtros</b-switch>
      </div>
      <tabla-historico
        :resultado="resultado"
        :mostrarFiltros="mostrarFiltros"
      ></tabla-historico>
    </div>
  </app-layout>
</template>

<script>
import apiCentros from "@/services/centros.js";
import apiHistoricoImportaciones from "@/services/historicoImportaciones.js";
import TablaHistorico from "./TablaHistorico.vue";

export default {
  components: { TablaHistorico },
  data() {
    return {
      aplicacion: this.$route.params.aplicacion,
      id: parseInt(this.$route.params.id),
      entornoId: parseInt(this.$route.params.entorno),
      centro: Object,
      resultado: {
        historicos: [],
        resultadosPorPagina: 10,
        pagina: 1,
      },
      mostrarFiltros: false,
    };
  },
  methods: {
    cargar(id, entornoId) {
      var params = {
        entornoId: this.entornoId,
        path: "centros/cargarConIds",
        centrosIds: [id],
      };
      apiCentros.cargarConIds(params).then((respuesta) => {
        this.centro = respuesta.centros[0];
      });
      apiHistoricoImportaciones
        .listar(id, entornoId, this.aplicacion)
        .then((resultado) => {
          this.resultado.historicos = resultado;
        });
    },
  },
  mounted() {
    this.cargar(this.id, this.entornoId);
  },
};
</script>
