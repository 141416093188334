<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/centros" active
            >Listado de Centros</b-breadcrumb-item
          >
        </b-breadcrumb>
        <div class="level-right mr-2">
          <b-button type="is-primary">
            <span class="icon mr-2">
              <i class="far fa-arrow-alt-circle-down"></i>
            </span>
            Exportar
          </b-button>
        </div>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <div class="columns is-desktop">
        <div class="column">
          <div class="level-left">
            <b-field label="Entorno" horizontal>
              <b-select
                placeholder="Entorno"
                icon="server"
                v-model="entorno"
                @input="listarCentros"
              >
                <option
                  v-for="entorno in entornos"
                  :key="entorno.id"
                  :value="entorno.id"
                >
                  {{ entorno.nombre }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="column">
          <div class="level-right">
            <b-switch v-model="mostrarFiltros">Mostrar filtros</b-switch>
          </div>
        </div>
      </div>
      <tabla
        :resultado="resultado"
        @eliminarCentro="eliminarCentro"
        :mostrarFiltros="mostrarFiltros"
        :entorno="entorno"
      ></tabla>
    </div>
  </app-layout>
</template>

<script>
import { esLector } from "@/services/session";
import api from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import Tabla from "./TablaCentros.vue";

export default {
  components: { Tabla },
  name: "ListarCentros",
  computed: {
    esLector,
  },
  data() {
    return {
      resultado: {
        centros: [],
        resultadosPorPagina: 10,
        pagina: 1,
      },
      mostrarFiltros: false,
      entorno: apiEntornos.crear(),
      entornos: [],
    };
  },
  methods: {
    listarCentros() {
      var params = {
        entornoId: this.entorno,
        path: "centros/listar",
        limite: 0,
        pagina: 0,
        term: "",
      };
      return api.listarTabla(params).then((respuesta) => {
        this.resultado.centros = respuesta.centros;
      });
    },
    eliminarCentro(centroId) {
      this.confirmarEliminar(centroId);
    },
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
    confirmarEliminar(centroId) {
      this.$buefy.dialog.confirm({
        title: "Atención",
        message: "¿Desea eliminar el centro?",
        confirmText: "Si",
        cancelText: "No",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          api.eliminar(centroId).then((respuesta) => {
            if (respuesta.error !== undefined) {
              var msg = "Se ha producido un error: <br>" + respuesta.error;
              var tipo = "is-danger";
              this.mostrarMensaje(msg, tipo);
              return;
            }
            this.mostrarMensaje(respuesta.msg, "is-success");
            this.listarCentros();
          });
        },
      });
    },
    async cargarEntornos() {
      // this.entornos = await apiEntornos.listar({'filtros': {'sufijos': ['.biz', '.xyz', '.test', '.eu']}});
      this.entornos = await apiEntornos.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.cargarEntornos();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
