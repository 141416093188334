<template>
  <div>
    <form @submit.prevent="importarMapasProcesos">
      <div class="columns is-desktop">
        <div class="column is-3">
          <b-field label="Entorno de Referencia">
            <b-select
              placeholder="Entorno"
              icon="server"
              required
              v-model="importacion.entornoOrigen"
            >
              <option
                v-for="entorno in entornos"
                :key="entorno.id"
                :value="entorno.id"
              >
                {{ entorno.nombre }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Centro de Referencia">
            <multiselect
              v-model="importacion.centroOrigen"
              id="ajax"
              label="nombre"
              track-by="id"
              placeholder="Buscar centro..."
              selectLabel="Pulsa enter para seleccionar"
              open-direction="bottom"
              :preserve-search="true"
              :options="centros"
              :multiple="false"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="1000"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="false"
              :disabled="importacion.entornoOrigen == 0"
              @search-change="listarCentros"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="selectTag"
                  ><span>{{ option.nombre }}</span
                  ><span class="ml-2" @click="remove(option)">
                    <i class="fas fa-times-circle"></i> </span></span
              ></template>
              <template slot="afterList" v-if="tienePaginaSiguiente">
                <div v-observe-visibility="estaAlFinal" />
                Cargando...
              </template>
              <span slot="noResult">No se ha encontrado ningún centro.</span>
            </multiselect>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop" v-if="importacion.mapasProcesos.length">
        <div class="column p-0">
          <b-field class="mt-1">
            <b-checkbox v-model="importarTodos"
              ><strong class="is-size-5">Seleccionar</strong></b-checkbox
            >
          </b-field>
        </div>
        <div class="column p-0">
          <b-field class="mt-1">
            <b-checkbox v-model="datosTodos"
              ><strong class="is-size-5">Datos</strong></b-checkbox
            >
          </b-field>
        </div>
        <div class="column p-0">
          <strong class="is-size-5">Acción al importar</strong>
        </div>
      </div>
      <div
        v-for="entidad in importacion.mapasProcesos"
        :key="entidad.id"
        :entidad="entorno.id"
        class="columns is-desktop is-vcentered"
      >
        <div class="column p-0">
          <b-field class="mt-1">
            <b-checkbox v-model="entidad.importar" @input="puedeImportar">{{
              entidad.nombre
            }}</b-checkbox>
          </b-field>
        </div>
        <div class="column p-0">
          <b-field class="mt-1">
            <b-checkbox v-model="entidad.datos">Importar datos</b-checkbox>
          </b-field>
        </div>
        <div class="column p-0">
          <b-field class="mt-1">
            <b-select
              placeholder="Elija acción..."
              icon="file-replace"
              v-model="entidad.accion"
              :required="entidad.importar"
            >
              <option value="sustituir">Sustituir</option>
              <option value="anadir">Añadir</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div class="level-right mr-2">
        <b-button
          type="is-danger"
          outlined
          @click="cancelar"
          class="mt-1 is-right mr-2"
        >
          Cancelar
          <span class="icon ml-2">
            <i class="fas fa-save"></i>
          </span>
        </b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-1 is-right"
          :disabled="disableImportar"
        >
          Aceptar
          <span class="icon ml-2 alinearBot">
            <b-icon icon="cog"></b-icon>
          </span>
        </b-button>
      </div>
    </form>
    <modal-progreso
      :faseProceso="faseProceso"
      :textoProgreso="textoProgreso"
      :valorProgreso="valorProgreso"
      :mostrarBarraProgreso="mostrarBarraProgreso"
    ></modal-progreso>
  </div>
</template>
<script>
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import apiMapasProcesos from "@/services/mapasProcesos.js";
import apiHistoricoImportaciones from "@/services/historicoImportaciones.js";
import apiEstatusImportaciones from "@/services/estatusImportaciones.js";
import Multiselect from "vue-multiselect";
import ModalProgreso from "@/components/ModalProgreso.vue";
import { uuid } from "vue-uuid";

export default {
  name: "FormularioMapasProcesos",
  components: { Multiselect, ModalProgreso },
  props: {
    id: Number,
    entornoId: Number,
    centroDestino: String,
  },
  computed: {
    hayCentros() {
      var centros = Boolean(this.centros.length);
      return !centros;
    },
  },
  data() {
    return {
      centros: [],
      mensajeError: "",
      tipoMensaje: "",
      entorno: apiEntornos.crear(),
      entornos: [],
      centroOrigenSeleccionado: "",
      isLoading: false,
      pagina: 0,
      tienePaginaSiguiente: true,
      importacion: {
        mapasProcesos: [],
        entornoOrigen: 0,
        centroOrigen: apiCentros.crear(),
        entornoDestino: this.entornoId,
        centroDestino: this.id,
        uuid: "",
      },
      disableImportar: true,
      importarTodos: false,
      datosTodos: false,
      mostrarBarraProgreso: false,
      textoProgreso: "",
      valorProgreso: 0,
      faseProceso: "",
    };
  },
  methods: {
    mostrarMensaje(msg, tipo, resumen) {
      if (tipo == "is-danger") {
        this.$buefy.toast.open({
          message: msg,
          type: tipo,
        });
        return;
      }
      this.$buefy.dialog.alert({
        title: msg,
        message: resumen,
        type: tipo,
        hasIcon: false,
        icon: "info-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    cancelar() {
      this.$router.push({ path: "/centros" });
    },
    async listarCentros(term) {
      if (term != undefined && term == "") {
        this.centros = [];
      } else if (term == undefined) {
        term = "";
      }
      var params = {
        entornoId: this.importacion.entornoOrigen,
        path: "centros/listar",
        limite: 50,
        pagina: this.pagina,
        term: term,
      };
      if (term != "") {
        params = {
          entornoId: this.importacion.entornoOrigen,
          path: "centros/listar",
          limite: 50,
          pagina: 0,
          term: term,
        };
      }

      return apiCentros.listar(params).then((respuesta) => {
        if (!respuesta.centros.length) {
          this.tienePaginaSiguiente = false;
        }
        if (term != "") {
          this.centros = respuesta.centros;
          this.tienePaginaSiguiente = false;
          return;
        }
        this.tienePaginaSiguiente = true;
        this.centros = this.centros.concat(respuesta.centros);
      });
    },
    estaAlFinal(alfinal) {
      if (alfinal) {
        this.pagina++;
        this.listarCentros();
      }
    },
    actualizarEntorno() {
      this.pagina = 0;
      this.centros = [];
    },
    importarMapasProcesos() {
      this.$buefy.dialog.confirm({
        title: "Atención",
        message:
          "Va a importar la información en el centro <b>" +
          this.centroDestino +
          "</b>",
        confirmText: "Importar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.procesarImportarMapasProcesos(),
      });
    },
    cargarMapasProcesos() {
      apiMapasProcesos.listarTipos(this.importacion).then((resultado) => {
        this.importacion.mapasProcesos = resultado;
      });
    },
    async procesarImportarMapasProcesos() {
      var msg = "Importación realizada.";
      var tipo = "is-success";
      var resumen = "Se han importado: ";
      this.importacion.uuid = uuid.v1();
      this.faseProceso = "Exportación";
      this.valorProgreso = 100 / this.importacion.mapasProcesos.length;
      this.textoProgreso = this.importacion.mapasProcesos[0].nombre;
      var importacionNormalizada = this.normalizarImportacion();

      this.mostrarProgreso();

      var resultadoExportacion = await apiMapasProcesos.exportar(
        this.importacion.entornoOrigen,
        this.importacion.centroOrigen.id,
        importacionNormalizada.mapasProcesos,
        this.importacion.uuid
      );
      if (resultadoExportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoExportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }

      this.importacion.uuid = uuid.v1();
      this.faseProceso = "Importación";
      this.valorProgreso = 100 / this.importacion.mapasProcesos.length;
      this.textoProgreso = this.importacion.mapasProcesos[0].nombre;
      this.mostrarProgreso(false);

      var resultadoImportacion = await apiMapasProcesos.importar(
        this.importacion.entornoDestino,
        this.importacion.centroDestino,
        resultadoExportacion,
        this.importacion.uuid
      );
      if (resultadoImportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoImportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }
      this.guardarHistorico(importacionNormalizada, resultadoImportacion);
      resumen = this.maquetarResumen(resultadoImportacion);
      this.mostrarBarraProgreso = false;
      this.mostrarMensaje(msg, tipo, resumen);
      // this.$router.push({ path: "/centros" });
    },
    normalizarImportacion() {
      var res = {};
      var mapasProcesos = [];
      var entidad = {};
      res["entornoOrigen"] = this.importacion.entornoOrigen;
      res["centroOrigen"] = this.importacion.centroOrigen;
      res["entornoDestino"] = this.importacion.entornoDestino;
      res["centroDestino"] = this.importacion.centroDestino;
      for (let i = 0; i < this.importacion.mapasProcesos.length; i++) {
        if (
          !!Object.getOwnPropertyDescriptor(
            this.importacion.mapasProcesos[i],
            "importar"
          ) &&
          this.importacion.mapasProcesos[i].importar
        ) {
          entidad = {
            id: this.importacion.mapasProcesos[i].id,
            datos:
              !!Object.getOwnPropertyDescriptor(
                this.importacion.mapasProcesos[i],
                "datos"
              ) && this.importacion.mapasProcesos[i].datos
                ? this.importacion.mapasProcesos[i].datos
                : false,
            accion: this.importacion.mapasProcesos[i].accion,
            nombre: this.importacion.mapasProcesos[i].nombre,
          };
          mapasProcesos.push(entidad);
        }
      }
      res["mapasProcesos"] = mapasProcesos;
      return res;
    },
    guardarHistorico(importacion, resultado) {
      var historicoImportaciones = apiHistoricoImportaciones.crear();
      var res = JSON.stringify(resultado);
      historicoImportaciones.aplicacion = "mapasProcesos";
      historicoImportaciones.entornoDestinoId = importacion.entornoDestino;
      historicoImportaciones.centroDestinoId = importacion.centroDestino;
      historicoImportaciones.centroDestino = this.centroDestino;
      historicoImportaciones.entornoOrigenId = importacion.entornoOrigen;
      historicoImportaciones.centroOrigenId = importacion.centroOrigen.id;
      historicoImportaciones.centroOrigen = this.centroOrigenSeleccionado;
      historicoImportaciones.importacion = this.limpiarImportacion(
        importacion,
        resultado
      );
      historicoImportaciones.resultado = res;

      apiHistoricoImportaciones.insertar(historicoImportaciones);
    },
    cambiarCentroOrigen() {
      this.centroOrigenSeleccionado = this.importacion.centroOrigen.nombre;
      this.cargarMapasProcesos();
    },
    limpiarImportacion(importacion, res) {
      var resultado = {};
      for (var prop in importacion) {
        for (let i = 0; i < importacion[prop].length; i++) {
          resultado[i] = {
            accion: importacion[prop][i].accion,
            datos: importacion[prop][i].datos,
            nombre: importacion[prop][i].nombre,
            resultado: res[i].resultado,
          };
        }
      }
      return JSON.stringify(resultado);
    },
    puedeImportar() {
      var res =
        Boolean(
          this.importacion.entornoOrigen &&
            this.importacion.centroOrigen.id != undefined &&
            this.importacion.centroOrigen.id
        ) && this.hayMapasProcesosSeleccionadas();
      this.disableImportar = !res;
    },
    hayMapasProcesosSeleccionadas() {
      for (let i = 0; i < this.importacion.mapasProcesos.length; i++) {
        if (
          !!Object.getOwnPropertyDescriptor(
            this.importacion.mapasProcesos[i],
            "importar"
          ) &&
          this.importacion.mapasProcesos[i].importar
        ) {
          return true;
        }
      }
      return false;
    },
    maquetarResumen(resumen) {
      var res = "";
      var fila = "";
      var icono = "";
      for (var aplicacion in resumen) {
        icono = '<i class="fas fa-check has-text-success is-size-3"></i>';
        if (resumen[aplicacion].resultado != "ok")
          icono =
            '<i class="fas fa-exclamation-triangle has-text-danger is-size-3"></i>';
        fila =
          '<div class="columns"><div class="column is-1">' +
          icono +
          '</div><div class="column is-11">' +
          resumen[aplicacion].mensaje +
          "</div></div>";
        res += fila;
      }
      return res;
    },
    marcarImportarTodos() {
      for (let i = 0; i < this.importacion.mapasProcesos.length; i++) {
        this.importacion.mapasProcesos[i].importar = this.importarTodos;
      }
      this.puedeImportar();
    },
    marcarDatosTodos() {
      for (let i = 0; i < this.importacion.mapasProcesos.length; i++) {
        this.importacion.mapasProcesos[i].datos = this.datosTodos;
      }
      this.puedeImportar();
    },
    async mostrarProgreso(importacion = true, that = this) {
      apiEstatusImportaciones
        .cargarProgreso(this.importacion.uuid)
        .then((respuesta) => {
          if (
            (importacion &&
              (respuesta.total == 0 || respuesta.progreso < respuesta.total)) ||
            (!importacion && that.mostrarBarraProgreso)
          ) {
            that.mostrarBarraProgreso = true;
            if (respuesta.mensaje != "") that.textoProgreso = respuesta.mensaje;
            if (respuesta.valorProgreso != 0)
              that.valorProgreso = (100 * respuesta.progreso) / respuesta.total;
            setTimeout(function () {
              that.mostrarProgreso(importacion);
            }, 1000);
          }
        });
    },
  },
  async mounted() {
    this.entornos = await apiEntornos.listar();
  },
  watch: {
    "importacion.centroOrigen": function () {
      this.puedeImportar();
      this.cambiarCentroOrigen();
    },
    "importacion.entornoOrigen": function () {
      this.puedeImportar();
      this.actualizarEntorno();
    },
    "importacion.mapasProcesos": {
      handler: function () {
        this.puedeImportar();
      },
      deep: true,
      immediate: true,
    },
    importarTodos: function () {
      this.marcarImportarTodos();
    },
    datosTodos: function () {
      this.marcarDatosTodos();
    },
  },
};
</script>
<style>
.dialog.modal .modal-card {
  width: 50vw;
  max-width: 50vw;
}
</style>
