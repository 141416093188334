import { CONSULTOR, LECTOR } from "@/enums/Roles";

export default [
  {
    path: "/",
    redirect: "/seguimientos",
    meta: {roles: [CONSULTOR, LECTOR]},
  },
  {
    path: "/login",
    component: require("@/views/LoginPage.vue").default,
  },
];
