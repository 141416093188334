<template>
  <b-table
    :data="isEmpty ? [] : data"
    :bordered="isBordered"
    :striped="isStriped"
    :narrowed="isNarrowed"
    :hoverable="isHoverable"
    :loading="isLoading"
    :focusable="isFocusable"
    :mobile-cards="hasMobileCards"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="isPaginationSimple"
    :pagination-position="paginationPosition"
    :default-sort-direction="defaultSortDirection"
    :pagination-rounded="isPaginationRounded"
    :sort-icon="sortIcon"
    :sort-icon-size="sortIconSize"
    default-sort="id"
    aria-next-label="Siguiente"
    aria-previous-label="Anterior"
    aria-page-label="Página"
    aria-current-label="Actual"
    :page-input="hasInput"
    :pagination-order="paginationOrder"
    :page-input-position="inputPosition"
    :debounce-page-input="inputDebounce"
    :row-class="claseFila"
    @click="(row) => leerAviso(row)"
  >
    <b-table-column
      v-slot="props"
      width="40"
      sortable
      :searchable="mostrarFiltros"
      label="Estado"
      field="estado"
    >
      <b-button
        v-if="!estaLeido(props.row.estado)"
        type="is-ghost"
        icon-left="envelope"
        icon-pack="fas"
        class="ml-1 boton-aviso"
      ></b-button>
      <b-button
        v-if="estaLeido(props.row.estado)"
        type="is-ghost"
        icon-left="envelope-open"
        icon-pack="far"
        class="ml-1 boton-aviso"
      ></b-button>
    </b-table-column>

    <b-table-column
      field="fecha"
      label="Fecha"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ fechaFormateada(props.row.fecha) }}
    </b-table-column>
    <b-table-column
      field="centro"
      label="Centro"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.centro }}
    </b-table-column>

    <b-table-column
      field="pais"
      label="País"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.pais }}
    </b-table-column>

    <b-table-column
      field="responsable"
      label="Responsable"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.responsable }}
    </b-table-column>

    <b-table-column
      field="fechaLectura"
      label="Leído el"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ fechaFormateada(props.row.fechaLectura) }}
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">No hay resultados</div>
    </template>
  </b-table>
</template>
<script>
import { formatDateTabla } from "@/services/date";
export default {
  props: {
    resultado: {
      avisos: [],
      resultadosPorPagina: 10,
      pagina: 1,
    },
    mostrarFiltros: Boolean,
  },
  computed: {
    data() {
      return this.resultado.avisos;
    },
    currentPage: {
      get() {
        return this.paginaActual;
      },
      set(value) {
        this.paginaActual = value;
      },
    },
    perPage() {
      return this.resultado.resultadosPorPagina;
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      paginaActual: null,
    };
  },
  methods: {
    leerAviso(aviso) {
      this.$emit("leerAviso", aviso);
    },
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    estaLeido(estado) {
      return Boolean(estado);
    },
    claseFila(){
      return "fila-enlace";
    }
  },
  mounted() {
    this.paginaActual = this.resultado.paginaActual;
  },
};
</script>
