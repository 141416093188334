import dayjs from "dayjs";

const date_format_tabla = "DD/MM/YYYY";
const date_format_form = "YYYY-MM-DD";
const datetime_format = "YYYY-MM-DD HH:mm:ss";
const datetime_format_es = "DD/MM/YYYY HH:mm:ss";
const date_format_es = "DD/MM/YYYY";
const date_format_year = "YYYY";

export function formatDateTabla(date) {
  return dayjs(date).format(date_format_tabla);
}
export function formatDateForm(date) {
  return dayjs(date).format(date_format_form);
}
export function formatDatetime(datetime) {
  return dayjs(datetime).format(datetime_format);
}
export function formatDatetimeEs(date) {
  return dayjs(date).format(datetime_format_es);
}
export function formatDateEs(date) {
  return dayjs(date).format(date_format_es);
}
export function formatDateAddDaysForm(date, days) {
  return dayjs(date).add(days, "day").format(date_format_form);
}
export function formatDateYear(date) {
  return dayjs(date).format(date_format_year);
}
