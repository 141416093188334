export default [
  {
    path: "/sedes",
    component: require("@/views/sedes/ListarPage").default,
  },
  {
    path: "/sedes/editar/:id",
    component: require("@/views/sedes/EditarPage").default,
  },
  {
    path: "/sedes/crear",
    component: require("@/views/sedes/CrearPage").default,
  },
];
