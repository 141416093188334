<template>
  <section class="mb-6">
    <div class="card p-5 mt-3 mb-3" v-if="seleccionarColumnas">
      <b-field grouped group-multiline>
        <div v-for="(columna, index) in columnas" :key="index" class="control">
          <b-checkbox v-model="columna.visible">
            {{ columna.titulo }}
          </b-checkbox>
        </div>
      </b-field>
    </div>
    <b-table
      :data="isEmpty ? [] : dataTabla"
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :pagination-rounded="isPaginationRounded"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :default-sort="defaultSort"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      :page-input="hasInput"
      :pagination-order="paginationOrder"
      :page-input-position="inputPosition"
      :debounce-page-input="inputDebounce"
      :scrollable="isScrollable"
    >
      <b-table-column
        v-for="columna in columnas"
        :key="columna.campo"
        :field="columna.campo"
        :label="columna.titulo"
        :visible="columna.visible"
        :centered="columna.centrada"
        v-slot="props"
        sortable
        :searchable="mostrarFiltros"
      >
        <span v-if="columna.tipoFecha">{{
          fechaFormateada(props.row[columna.campo])
        }}</span>
        <span v-if="!columna.tipoFecha && !columna.subTabla">
          <span
            v-if="columna.columnaTabla == ''"
            :class="
              obtenerClaseSignificancia(columna.campo, props.row[columna.campo])
            "
          >
            <b-tooltip
              v-if="obtenerTooltipSignificancia(columna.campo) != ''"
              :label="obtenerTooltipSignificancia(columna.campo)"
              type="is-light"
              position="is-top"
            >
              {{ props.row[columna.campo] }}
            </b-tooltip>
            <span v-if="obtenerTooltipSignificancia(columna.campo) == ''">
              {{ props.row[columna.campo] }}
            </span>
          </span>
          <span
            v-if="
              columna.columnaTabla != '' &&
              props.row[columna.columnaTabla].length > 0
            "
          >
            <template>
              <a @click="cambiarVisibilidad(props.row['idCentro'])">
                {{ props.row[columna.campo] }}
              </a>
            </template>
          </span>
        </span>
        <div
          v-if="
            columna.columnaTabla != '' &&
            props.row[columna.columnaTabla].length > 0
          "
        >
          <div
            class="columns is-desktop subtabla"
            v-show="visibilidadTabla[props.row['idCentro']]"
          >
            <div class="column is-offset-0">
              <b-table
                :data="isEmpty ? [] : props.row[columna.columnaTabla]"
                :bordered="isBordered"
                :striped="isStriped"
                :narrowed="isNarrowed"
                :hoverable="isHoverable"
                :loading="isLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
                :paginated="false"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :pagination-rounded="isPaginationRounded"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="tipo"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Actual"
                :page-input="hasInput"
                :pagination-order="paginationOrder"
                :page-input-position="inputPosition"
                :debounce-page-input="inputDebounce"
                detailed
                detail-key="tipo"
                detail-transition="fade"
                :show-detail-icon="showDetailIcon"
              >
                <b-table-column
                  field="tipo"
                  label="Tipo"
                  v-slot="propsFila"
                  :centered="false"
                  header-class="headerAcciones"
                >
                  {{ propsFila.row.tipo }}
                </b-table-column>

                <b-table-column
                  field="numExpedientes"
                  label="Nº Expedientes"
                  v-slot="propsFila"
                  header-class="headerAcciones"
                >
                  {{ propsFila.row.numExpedientes }}
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">No hay resultados</div>
      </template>
    </b-table>
  </section>
</template>
<script>
import Vue from "vue";
import { formatDateTabla } from "@/services/date";
import apiSeguimiento from "@/services/seguimientos";
import apiSignificancia from "@/services/significancia";

export default {
  props: {
    datos: [],
    mostrarFiltros: Boolean,
    seleccionarColumnas: Boolean,
    ordenColumna: Array,
  },
  computed: {
    dataTabla() {
      return this.datos;
    },
    currentPage: {
      get() {
        return this.paginaActual;
      },
      set(value) {
        this.paginaActual = value;
      },
    },
    perPage() {
      return this.resultadosPorPagina;
    },
    defaultSort() {
      return this.ordenColumna;
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      isScrollable: true,
      hasMobileCards: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      paginaActual: 1,
      resultadosPorPagina: 10,
      pagina: 1,
      columnas: [],
      defaultSortDirection: "desc",
      showDetailIcon: false,
      visibilidadTabla: [],
      significancia: [],
    };
  },
  methods: {
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    cambiarVisibilidad(fila) {
      if (fila in this.visibilidadTabla) {
        Vue.set(this.visibilidadTabla, fila, !this.visibilidadTabla[fila]);
        return;
      }
      Vue.set(this.visibilidadTabla, fila, true);
    },
    obtenerClaseSignificancia(columna, valor) {
      for (var significanciaIndex in this.significancia) {
        var significancia = this.significancia[significanciaIndex];
        if (significancia.indicador == columna) {
          if (valor < significancia.riesgo) {
            return "has-text-danger";
          } else if (valor < significancia.recomendado) {
            return "has-text-warning";
          } else {
            return "has-text-success";
          }
        }
      }
      return "";
    },
    obtenerTooltipSignificancia(columna) {
      for (var significanciaIndex in this.significancia) {
        var significancia = this.significancia[significanciaIndex];
        if (significancia.indicador == columna) {
          return (
            "Valor riesgo: " +
            significancia.riesgo +
            " - Valor recomendado: " +
            significancia.recomendado
          );
        }
      }
      return "";
    },
  },
  async mounted() {
    this.columnas = await apiSeguimiento.obtenerColumnasTabla();
    this.significancia =
      await apiSignificancia.obtenerSignificanciaIndicadores();
  },
  watch: {
    columnas: {
      handler: function () {
        if (this.columnas.length > 0)
          localStorage.columnasTablaSeguimiento = JSON.stringify(this.columnas);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
