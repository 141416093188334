export const mostrarBannerDePruebas = false;
export const version = process.env.VUE_APP_VERSION;

export const VUE_APP_FCM_PUBLIC_APIKEY = process.env.VUE_APP_FCM_PUBLIC_APIKEY;
export const VUE_APP_FCM_APIKEY = process.env.VUE_APP_FCM_APIKEY;
export const VUE_APP_FCM_AUTHDOMAIN = process.env.VUE_APP_FCM_AUTHDOMAIN;
export const VUE_APP_FCM_PROJECTID = process.env.VUE_APP_FCM_PROJECTID;
export const VUE_APP_FCM_STORAGEBUCKET = process.env.VUE_APP_FCM_STORAGEBUCKET;
export const VUE_APP_FCM_MESSAGINSENDERID = process.env.VUE_APP_FCM_MESSAGINSENDERID;
export const VUE_APP_FCM_APPID = process.env.VUE_APP_FCM_APPID;

