import { CONSULTOR, LECTOR } from "@/enums/Roles";

export default [
  {
    path: "/usuarios",
    component: require("@/views/usuarios/ListarPage").default,
  },
  {
    path: "/usuarios/editar/:id",
    component: require("@/views/usuarios/EditarPage").default,
  },
  {
    path: "/usuarios/crear",
    component: require("@/views/usuarios/CrearPage").default,
  },
  {
    path: "/mi-cuenta",
    component: require("@/views/usuarios/MiCuentaPage").default,
    meta: {roles: [CONSULTOR, LECTOR]},
  },
];
