<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/usuarios"
            >Usuarios</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" to="/usuarios/crear" active
            >Crear</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <formulario-usuario :nuevo="true"></formulario-usuario>
    </div>
  </app-layout>
</template>

<script>
import FormularioUsuario from "./Formulario.vue";

export default {
  components: { FormularioUsuario },
  name: "EditarUsuario",
  data() {
    return {};
  },
};
</script>
