<template>
  <div>
    <div
      v-if="
        conjuntoTiposProcesosGenericos.length == 0 &&
        filtroTiposProcesosGenerico == 0
      "
    >
      <p class="text-center pt-6 pb-6">
        No hay aplicaciones con Expedientes genéricos seleccionadas.
      </p>
    </div>
    <div
      v-if="
        conjuntoTiposProcesosGenericos.length > 0 ||
        filtroTiposProcesosGenerico != ''
      "
    >
      <div class="columns is-desktop">
        <div class="column is-4 is-offset-1">
          <b-field label="Buscar Tipo Expediente Genérico">
            <b-input
              v-model="filtroTiposProcesosGenerico"
              icon-right="close-circle"
              icon-right-clickable
              @icon-right-click="limpiarFiltroTiposProcesosGenerico"
            >
              ></b-input
            >
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column is-6 is-offset-1 pr-6">
          <h4 class="is-title-4 has-text-weight-bold">Nombre</h4>
        </div>
        <div class="column is-2 pr-6">
          <h4 class="is-title-4 has-text-weight-bold">Código</h4>
        </div>
        <div class="column is-3 pr-6">
          <h4 class="is-title-4 has-text-weight-bold">Tipo</h4>
        </div>
      </div>
      <div
        class="columns is-desktop mb-0"
        v-for="tipoProcesoGenerico in conjuntoTiposProcesosGenericos"
        :key="tipoProcesoGenerico.id"
        :value="tipoProcesoGenerico.id"
      >
        <div class="column is-6 is-offset-1 pr-6 mb-0">
          <b-switch
            v-model="tipoProcesoGenerico.seleccionado"
          >
            {{ tipoProcesoGenerico.nombre }}
          </b-switch>
        </div>
        <div class="column is-2 pr-6 mb-0">
          {{ tipoProcesoGenerico.codigo }}
        </div>
        <div class="column is-3 pr-6 mb-0">
          <b-select
            placeholder="Seleccione..."
            v-model="tipoProcesoGenerico.codigoSuperTipo"
            :disabled="!tipoProcesoGenerico.seleccionado"
            :required="tipoProcesoGenerico.seleccionado"
            ref="superTipo"
          >
            <option
              v-for="supertipo in supertiposNoCrear"
              :value="supertipo.CODIGO"
              :key="supertipo.CODIGO"
            >
              {{ supertipo.NOMBRE }}
            </option>
          </b-select>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-10 is-offset-1">
        <h5 class="mt-6 title is-6">Tipos de Expediente específicos</h5>
        <table class="table" style="width: 100%">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Código</th>
              <th>Aplicación</th>
              <th>Supertipo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="tiposExpedientes.length == 0">
              <td colspan="5" class="has-text-centered pt-4">
                No existen tipos de Expedientes creados.
              </td>
            </tr>
            <tr
              v-for="(tipoExpediente, index) in tiposExpedientes"
              :key="index"
              :value="tipoExpediente.codigo"
            >
              <td>{{ tipoExpediente.nombre }}</td>
              <td>{{ tipoExpediente.codigo }}</td>
              <td>
                {{ obtenerNombreAplicacion(tipoExpediente.aplicacion) }}
              </td>
              <td>
                {{ obtenerNombreSuperTipo(tipoExpediente.supertipo) }}
              </td>
              <td>
                <b-button
                  type="is-success"
                  outlined
                  icon-left="clipboard-edit-outline"
                  class="mr-3"
                  @click="editarTipoExpediente(tipoExpediente, index)"
                  >Editar</b-button
                >
                <b-button
                  type="is-danger"
                  outlined
                  icon-left="delete"
                  @click="eliminarTipoExpediente(index)"
                  >Eliminar</b-button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="columns">
      <div class="column is-10 is-offset-1">
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <b-button
              label="Nuevo Tipo Expediente"
              type="is-primary"
              icon-right="plus-circle"
              @click="nuevoTipoExpediente"
              class="level-item"
            />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="mostrarModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Tipo Expediente"
      close-button-aria-label="Cerrar"
      aria-modal
      :width="900"
    >
      <div class="card" style="width: 900px">
        <div class="card-content">
          <div class="media">
            <div class="media-content columns">
              <div class="column is-6">
                <p class="title is-4">{{ tituloModal }}</p>
              </div>
              <div class="column is-6">
                <p class="subtitle is-6 is-pulled-right"></p>
              </div>
            </div>
          </div>
          <div class="content columns is-multiline">
            <div class="column is-6">
              <b-field label="Nombre">
                <b-input
                  v-model="tipoExpediente.nombre"
                  autofocus
                  maxlength="50"
                  icon="form-textbox"
                  @input="puedeGuardarTipoExpediente"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Descripción">
                <b-input
                  v-model="tipoExpediente.descripcion"
                  autofocus
                  maxlength="100"
                  icon="card-text"
                  @input="puedeGuardarTipoExpediente"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="Código">
                <b-input
                  v-model="tipoExpediente.codigo"
                  autofocus
                  maxlength="10"
                  icon="barcode"
                  @input="puedeGuardarTipoExpediente"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Aplicación">
                <b-select
                  placeholder="Aplicación"
                  icon="application"
                  v-model="tipoExpediente.aplicacion"
                  @input="puedeGuardarTipoExpediente"
                >
                  <option
                    v-for="aplicacion in conjuntoAplicaciones"
                    :key="aplicacion.id_aplicacion"
                    :value="aplicacion.id_aplicacion"
                  >
                    {{ aplicacion.dc_aplicacion }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Supertipo">
                <b-select
                  placeholder="Supertipo"
                  icon="call-merge"
                  v-model="tipoExpediente.supertipo"
                  @input="puedeGuardarTipoExpediente"
                >
                  <option
                    v-for="supertipo in supertiposNoCrear"
                    :key="supertipo.CODIGO"
                    :value="supertipo.CODIGO"
                  >
                    {{ supertipo.NOMBRE }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Editor">
                <b-radio
                  v-model="tipoExpediente.editor"
                  native-value="0"
                  name="editor"
                  @input="puedeGuardarTipoExpediente"
                >
                  Básico
                </b-radio>
                <b-radio
                  v-model="tipoExpediente.editor"
                  native-value="1"
                  name="editor"
                  @input="puedeGuardarTipoExpediente"
                >
                  Avanzado
                </b-radio>
              </b-field>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button label="Cancelar" @click="cerrarModal" />
          <b-button
            :label="tituloBotonModal"
            @click="guardarTipoExpediente"
            type="is-primary"
            :disabled="puedeGuardarTipoExpedienteFlag"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "TiposExpedienteFormulario",
  props: {
    tiposProcesosGenericos: [],
    aplicaciones: [],
    supertiposNoCrear: [],
    tiposExpedientesGuardados: [],
    modoEdicionTipoExp: Boolean,
  },
  computed: {
    tiposProcesosGenericos1() {
      return this.partirTiposProcesosGenericos(1);
    },
    tiposProcesosGenericos2() {
      return this.partirTiposProcesosGenericos(2);
    },
    tiposProcesosGenericos3() {
      return this.partirTiposProcesosGenericos(3);
    },
    conjuntoTiposProcesosGenericos() {
      if (this.tiposProcesosGenericos == undefined) {
        return [];
      }
      return this.tiposProcesosGenericos.filter(
        this.buscarTipoProcesosGenerico
      );
    },
    codigosAplicaciones() {
      var res = [];
      var aplicacionesSeleccionadas = this.aplicaciones.filter(
        this.buscarAplicacionesSeleccionadas
      );
      for (var app in aplicacionesSeleccionadas) {
        var codigos = this.codigosAplicacion(
          aplicacionesSeleccionadas[app].configuracion
        );
        if (codigos !== undefined && codigos.length > 0) {
          res = res.concat(codigos);
        }
      }
      return res;
    },
    conjuntoAplicaciones() {
      if (this.aplicaciones == undefined) {
        return [];
      }
      return this.aplicaciones.filter(this.filtrarAplicacionesSeleccionadas);
    },
    tiposExpedientes() {
      if (this.tiposExpedientesGuardados == undefined) {
        return [];
      }
      return this.tiposExpedientesGuardados;
    },
  },
  data() {
    return {
      filtroTiposProcesosGenerico: "",
      tipoExpediente: {},
      mostrarModal: false,
      tituloModal: "Nuevo Tipo Expediente",
      tituloBotonModal: "Añadir",
      modoEdicion: false,
      indexTiposExpedientes: 0,
      puedeGuardarTipoExpedienteFlag: true,
      arrayTipos: [],
    };
  },
  methods: {
    partirTiposProcesosGenericos(seccion) {
      var numTiposProcesosGenericosTotal =
        this.conjuntoTiposProcesosGenericos.length;
      var numTiposProcesosGenericosColumna = Math.ceil(
        numTiposProcesosGenericosTotal / 3
      );

      var inicio = 0 + (seccion - 1) * numTiposProcesosGenericosColumna;
      var fin = numTiposProcesosGenericosColumna * seccion;

      return this.conjuntoTiposProcesosGenericos.slice(inicio, fin);
    },
    buscarTipoProcesosGenerico(tipoProcesosGenerico) {
      if (this.modoEdicionTipoExp) {
        return (
          tipoProcesosGenerico.nombre
            .toUpperCase()
            .includes(this.filtroTiposProcesosGenerico.toUpperCase()) ||
          tipoProcesosGenerico.codigo
            .toUpperCase()
            .includes(this.filtroTiposProcesosGenerico.toUpperCase()) ||
          this.filtroTiposProcesosGenerico == ""
        );
      }
      return (
        this.codigosAplicaciones.includes(
          tipoProcesosGenerico.codigo.toUpperCase()
        ) &&
        (tipoProcesosGenerico.nombre
          .toUpperCase()
          .includes(this.filtroTiposProcesosGenerico.toUpperCase()) ||
          tipoProcesosGenerico.codigo
            .toUpperCase()
            .includes(this.filtroTiposProcesosGenerico.toUpperCase()) ||
          this.filtroTiposProcesosGenerico == "")
      );
    },
    codigosAplicacion(configuracion) {
      if (configuracion == "") return [];
      configuracion = JSON.parse(configuracion);
      if (configuracion.array_codigos !== undefined) {
        return configuracion.array_codigos;
      }
      if (configuracion.ARRAY_CODIGOS !== undefined) {
        return configuracion.ARRAY_CODIGOS;
      }
    },
    buscarAplicacionesSeleccionadas(aplicacion) {
      return aplicacion.seleccionada != undefined && aplicacion.seleccionada;
    },
    obtenerNombreAplicacion(aplicacion) {
      for (var app in this.aplicaciones) {
        if (this.aplicaciones[app].id_aplicacion == aplicacion) {
          return this.aplicaciones[app].dc_aplicacion;
        }
      }
    },
    obtenerNombreSuperTipo(supertipo) {
      for (var supert in this.supertiposNoCrear) {
        if (this.supertiposNoCrear[supert].CODIGO == supertipo) {
          return this.supertiposNoCrear[supert].NOMBRE;
        }
      }
    },
    limpiarFiltroTiposProcesosGenerico() {
      this.filtroTiposProcesosGenerico = "";
    },
    filtrarAplicacionesSeleccionadas(aplicacion) {
      return aplicacion.seleccionada;
    },
    nuevoTipoExpediente() {
      this.tipoExpediente.descripcion = "";
      this.tipoExpediente.nombre = "";
      this.tipoExpediente.codigo = "";
      this.tipoExpediente.aplicacion = "";
      this.tipoExpediente.supertipo = "";
      this.tipoExpediente.editor = "";
      this.tituloModal = "Nuevo Tipo Expediente";
      this.tituloBotonModal = "Añadir";
      this.modoEdicion = false;
      this.mostrarModal = true;
    },
    cerrarModal() {
      this.mostrarModal = false;
    },
    guardarTipoExpediente() {
      if (!this.modoEdicion) {
        this.tiposExpedientes.push({
          descripcion: this.tipoExpediente.descripcion,
          nombre: this.tipoExpediente.nombre,
          codigo: this.tipoExpediente.codigo,
          aplicacion: this.tipoExpediente.aplicacion,
          supertipo: this.tipoExpediente.supertipo,
          editor: this.tipoExpediente.editor,
        });
      } else {
        this.tiposExpedientes[this.indexTiposExpedientes] = {
          descripcion: this.tipoExpediente.descripcion,
          nombre: this.tipoExpediente.nombre,
          codigo: this.tipoExpediente.codigo,
          aplicacion: this.tipoExpediente.aplicacion,
          supertipo: this.tipoExpediente.supertipo,
          editor: this.tipoExpediente.editor,
          idTipoEntrada: this.tiposExpedientes[this.indexTiposExpedientes].idTipoEntrada,
          idSuperTipo: this.tiposExpedientes[this.indexTiposExpedientes].idSuperTipo,
        };
      }
      this.mostrarModal = false;
    },
    editarTipoExpediente(tipoExpediente, index) {
      this.tipoExpediente.descripcion = tipoExpediente.descripcion;
      this.tipoExpediente.nombre = tipoExpediente.nombre;
      this.tipoExpediente.codigo = tipoExpediente.codigo;
      this.tipoExpediente.aplicacion = tipoExpediente.aplicacion;
      this.tipoExpediente.supertipo = tipoExpediente.supertipo;
      this.tipoExpediente.editor = tipoExpediente.editor;

      this.tituloModal = "Editar Tipo Expediente";
      this.tituloBotonModal = "Modificar";
      this.modoEdicion = true;
      this.mostrarModal = true;
      this.indexTiposExpedientes = index;
      this.puedeGuardarTipoExpediente();
    },
    eliminarTipoExpediente(index) {
      this.tiposExpedientes.splice(index, 1);
    },
    puedeGuardarTipoExpediente() {
      this.puedeGuardarTipoExpedienteFlag =
        Object.keys(this.tipoExpediente).length === 0 ||
        this.tipoExpediente.descripcion == "" ||
        this.tipoExpediente.nombre == "" ||
        this.tipoExpediente.codigo == "" ||
        this.tipoExpediente.aplicacion == "" ||
        this.tipoExpediente.supertipo == "" ||
        this.tipoExpediente.editor === "";
    },
  },
  mounted() {
    this.arrayTipos = this.tiposProcesosGenericos;
  },
};
</script>
