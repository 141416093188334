<template>
  <div>
    <b-progress
      :type="tipo"
      :value="valor"
      :show-value="mostrarValor"
    ></b-progress>
    <div v-if="!valido" class="is-size-7" :class="tipoMensaje" v-html="mostrarMensajesError(mensajeError)"></div>
  </div>
</template>

<script>
import apiUsuarios from "@/services/usuarios.js";

export default {
  props: {
    clave: String,
    mostrarValor: Boolean,
  },
  data() {
    return {
      valor: 0,
      valido: false,
      mensajeError: [],
      tipoMensaje: "",
      tipo: "",
    };
  },
  methods: {
    mostrarMensajesError(mensaje) {
      return mensaje.join(" <br> ");
    },
    comprobarClave() {
      apiUsuarios.comprobarClave(this.clave).then((resultado) => {
        var claveComprobada = resultado;
        this.valor = claveComprobada.strength;
        this.valido = claveComprobada.is_valid;
        this.mensajeError = claveComprobada.error;
        if (this.valido) {
          this.tipoMensaje = "has-text-success";
        } else {
          this.tipoMensaje = "has-text-danger";
        }
        if (this.valor < 30) {
          this.tipo = "is-danger";
        } else if (this.valor < 60) {
          this.tipo = "is-warning";
        } else if (!this.valido) {
          this.tipo = "is-warning";
        } else {
          this.tipo = "is-success";
        }
        this.$emit("actualizarClave", this.valido);
      });
    },
  },
  watch: {
    clave: {
      handler: function () {
        if (this.clave !== undefined) this.comprobarClave();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
