import ListarPage from "@/views/gestionCentros/ListarPage.vue";

export default [
  {
    path: "/gestion-centros",
    component: ListarPage,
  },
  {
    path: "/gestion-centros/editar/:seccion/:centroId/:entornoId",
    component: require("@/views/gestionCentros/EditarPage").default,
  },
  {
    path: "/gestion-centros/crear/:entornoId",
    component: require("@/views/gestionCentros/CrearPage").default,
  },
];
