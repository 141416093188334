<template>
  <div>
    <div class="columns is-desktop">
      <div class="column is-4 is-offset-1">
        <b-field label="Buscar aplicación">
          <b-input
            v-model="filtroAplicacion"
            icon-right="close-circle"
            icon-right-clickable
            @icon-right-click="limpiarFiltroAplicacion"
          >
            ></b-input
          >
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-4 is-offset-1 pr-6">
        <div
          class="columns is-desktop mb-0"
          v-for="aplicacion in aplicaciones1"
          :key="aplicacion.id_aplicacion"
          :value="aplicacion.id_aplicacion"
        >
          <div class="column is-12">
            <b-switch v-model="aplicacion.seleccionada">
              {{ aplicacion.dc_aplicacion }}
              <strong>{{ codigosAplicacion(aplicacion.configuracion) }}</strong>
              <b-tooltip
                position="is-bottom"
                multilined
                type="is-white is-light"
                size="is-large"
              >
                <b-icon icon="information" size="is-small" type="is-primary">
                </b-icon>
                <template v-slot:content>
                  <div class="has-text-left has-text-black">
                    {{ aplicacion.dl_aplicacion }}
                    <h3 class="mt-3"><strong>Paquetes:</strong></h3>
                    {{ obtenerNombrePaquete(aplicacion) }}
                  </div>
                </template>
              </b-tooltip>
            </b-switch>
          </div>
        </div>
      </div>
      <div class="column is-4 pr-6">
        <div
          class="columns is-desktop mb-0"
          v-for="aplicacion in aplicaciones2"
          :key="aplicacion.id_aplicacion"
          :value="aplicacion.id_aplicacion"
        >
          <div class="column is-12">
            <b-switch v-model="aplicacion.seleccionada">
              {{ aplicacion.dc_aplicacion }}
              <strong>{{ codigosAplicacion(aplicacion.configuracion) }}</strong>
              <b-tooltip
                position="is-bottom"
                multilined
                type="is-white is-light"
                size="is-large"
              >
                <b-icon icon="information" size="is-small" type="is-primary">
                </b-icon>
                <template v-slot:content>
                  <div class="has-text-left has-text-black">
                    {{ aplicacion.dl_aplicacion }}
                    <h3 class="mt-3"><strong>Paquetes:</strong></h3>
                    {{ obtenerNombrePaquete(aplicacion) }}
                  </div>
                </template>
              </b-tooltip>
            </b-switch>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div
          class="columns is-desktop mb-0"
          v-for="aplicacion in aplicaciones3"
          :key="aplicacion.id_aplicacion"
          :value="aplicacion.id_aplicacion"
        >
          <div class="column is-12">
            <b-switch v-model="aplicacion.seleccionada">
              {{ aplicacion.dc_aplicacion }}
              <strong>{{ codigosAplicacion(aplicacion.configuracion) }}</strong>
              <b-tooltip
                position="is-bottom"
                multilined
                type="is-white is-light"
                size="is-large"
              >
                <b-icon icon="information" size="is-small" type="is-primary">
                </b-icon>
                <template v-slot:content>
                  <div class="has-text-left has-text-black">
                    {{ aplicacion.dl_aplicacion }}
                    <h3 class="mt-3"><strong>Paquetes:</strong></h3>
                    {{ obtenerNombrePaquete(aplicacion) }}
                  </div>
                </template>
              </b-tooltip>
            </b-switch>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AplicacionesFormulario",
  props: {
    paquetes: Array,
    aplicaciones: Array,
  },
  computed: {
    aplicaciones1() {
      return this.partirAplicaciones(1);
    },
    aplicaciones2() {
      return this.partirAplicaciones(2);
    },
    aplicaciones3() {
      return this.partirAplicaciones(3);
    },
    conjuntoAplicaciones() {
      if (this.aplicaciones == undefined) {
        return [];
      }
      if (this.filtroAplicacion != "") {
        return this.aplicaciones.filter(this.buscarAplicacion);
      }
      return this.aplicaciones;
    },
  },
  data() {
    return {
      filtroAplicacion: "",
    };
  },
  methods: {
    partirAplicaciones(seccion) {
      var numAplicacionesTotal = this.conjuntoAplicaciones.length;
      var numAplicacionesColumna = Math.ceil(numAplicacionesTotal / 3);

      var inicio = 0 + (seccion - 1) * numAplicacionesColumna;
      var fin = numAplicacionesColumna * seccion;

      return this.conjuntoAplicaciones.slice(inicio, fin);
    },
    buscarAplicacion(aplicacion) {
      return (
        aplicacion.dc_aplicacion
          .toUpperCase()
          .includes(this.filtroAplicacion.toUpperCase()) ||
        aplicacion.configuracion
          .toUpperCase()
          .includes(this.filtroAplicacion.toUpperCase()) ||
        this.obtenerNombrePaquete(aplicacion)
          .toUpperCase()
          .includes(this.filtroAplicacion.toUpperCase())
      );
    },
    codigosAplicacion(configuracion) {
      if (configuracion == "") return;
      configuracion = JSON.parse(configuracion);
      if (configuracion.array_codigos !== undefined) {
        return "(" + configuracion.array_codigos.toString() + ")";
      }
      if (configuracion.ARRAY_CODIGOS !== undefined) {
        return "(" + configuracion.ARRAY_CODIGOS.toString() + ")";
      }
    },
    obtenerNombrePaquete(aplicacion) {
      var res = [];
      for (var paquete in this.paquetes) {
        if (this.paquetes[paquete].id_paquete == aplicacion.id_paquete) {
          res.push(this.paquetes[paquete].dc_paquete);
        }
      }
      return res.toString();
    },
    limpiarFiltroAplicacion() {
      this.filtroAplicacion = "";
    },
  },
  mounted() {},
};
</script>
