<template>
  <div id="app">
    <router-view></router-view>
    <b-loading v-model="isLoading" :is-full-page="true" :can-cancel="true" />
  </div>
</template>
<script>
import { backend } from "./services/backend";
import LoginForm from "@/components/LoginForm";
import { recuperar } from "@/services/login";
import apiNotificaciones from "@/services/notificaciones";
import {
  VUE_APP_FCM_PUBLIC_APIKEY,
  VUE_APP_FCM_APIKEY,
  VUE_APP_FCM_AUTHDOMAIN,
  VUE_APP_FCM_PROJECTID,
  VUE_APP_FCM_STORAGEBUCKET,
  VUE_APP_FCM_MESSAGINSENDERID,
  VUE_APP_FCM_APPID,
} from "@/settings";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: VUE_APP_FCM_APIKEY,
  authDomain: VUE_APP_FCM_AUTHDOMAIN,
  projectId: VUE_APP_FCM_PROJECTID,
  storageBucket: VUE_APP_FCM_STORAGEBUCKET,
  messagingSenderId: VUE_APP_FCM_MESSAGINSENDERID,
  appId: VUE_APP_FCM_APPID,
};

export default {
  components: {},
  async mounted() {
    const haySesion = await recuperar();

    if (!haySesion && this.$route.path != "/login") {
      if (location.pathname != "/") {
        location.href = "/login?redirect=" + location.pathname;
      } else {
        location.href = "/login";
      }
      return;
    } else if (haySesion && this.$route.path == "/login") {
      location.href = "/";
    }

    // Siempre que se lance una peticion ajax, ANTES quiero mostrar el loading
    backend.onRequest(() => this.showLoading());
    backend.onResponse(() => this.hideLoading());

    // Si al hacer una petición, se recibe respuesta un error
    //   - Si es 405: Mostrar un mensaje modal
    //   - Si es 401: Mostrar un modal con la autenticación
    backend.onResponse((response) => {
      if (response.status == 401) {
        if (this.$route.path != "/login") {
          this.showUserLogin();
        }
      } else if (response.status != 200) {
        const msg = response.headers.get("x-error-message");
        this.showUserMessage(msg);
      }
    });
    this.notifyMe();

    if (haySesion) {
      this.inicializarNotificaciones();
    }
  },
  methods: {
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    },
    showUserMessage(msg) {
      this.$buefy.toast.open({
        message: msg,
        type: "is-danger",
      });
    },
    showUserLogin() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: LoginForm,
        hasModalCard: false,
        width: 350,
        trapFocus: true,
        events: {
          ok() {
            modal.close();
          },
        },
      });
    },
    login() {},
    inicializarNotificaciones() {
      initializeApp(firebaseConfig);
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey: VUE_APP_FCM_PUBLIC_APIKEY,
      }).then((currentToken) => {
        this.tokenActual = currentToken;
        apiNotificaciones.guardarToken(currentToken);
      });
      onMessage(messaging, (payload) => {
        console.log(payload);
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          click_action: payload.notification.click_action
        };
        new Notification(notificationTitle, notificationOptions);
        this.mostrarNotificacion(notificationOptions.body);
      });
    },
    notifyMe() {
      if (!("Notification" in window)) {
        alert("El navegador no soporta las notificaciones");
      } else if (Notification.permission === "granted") {
        // Tenemos permiso para mostrar notificaciones
      } else if (Notification.permission !== "denied") {
        // No tenemos permiso ni esta denegado
        Notification.requestPermission().then(() => {});
      }
    },
    mostrarNotificacion(mensaje) {
      this.$buefy.snackbar.open({
        indefinite: true,
        message: mensaje,
        type: "is-primary",
        position: "is-bottom-left",
        actionText: "Ir Avisos",
        cancelText: "Aceptar",
        queue: true,
        onAction: () => {
          this.$router.push({ path: "/avisos" });
        },
      });
    },
    iniciarSesion() {
      try {
        var configuracionFCM = getMessaging();
      } catch (error) {
        this.inicializarNotificaciones();
        configuracionFCM = getMessaging();
      }

      const messaging = configuracionFCM;
      if (this.tokenActual != "") {
        getToken(messaging, {
          vapidKey: VUE_APP_FCM_PUBLIC_APIKEY,
        }).then((currentToken) => {
          this.tokenActual = currentToken;
          apiNotificaciones.guardarToken(currentToken);
        });
      }
    },
    async cerrarSesion() {
      try {
        const messaging = getMessaging();
        if (this.tokenActual != "") {
          await apiNotificaciones.borrarToken(this.tokenActual);
          this.tokenActual == "";
        }
        deleteToken(messaging, {
          vapidKey: VUE_APP_FCM_PUBLIC_APIKEY,
        }).then(() => {});
      } catch (error) {
        console.log(error);
      }
    },
  },
  data() {
    return {
      isLoading: false,
      tokenActual: "",
    };
  },
};
</script>
