<template>
  <div>
    <div class="columns is-desktop">
      <div class="column is-10 is-offset-1 pr-6">
        <b-collapse
          class="card"
          animation="slide"
          :key="1"
          :open="isOpen == 1"
          @open="isOpen = 1"
          :aria-id="'qlik'"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'qlik'"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">Conexiones QLIK</p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <div class="columns is-vcentered">
                <div class="column is-3">
                  <b-field>
                    <b-switch v-model="centro.qlik_toggle"
                      >Activar Qlik</b-switch
                    >
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-button
                    label="Nueva conexión"
                    type="is-primary"
                    icon-right="plus-circle"
                    @click="nuevaConexion"
                    v-if="centro.qlik_toggle"
                    class="level-right"
                  />
                </div>
              </div>
              <div v-if="centro.qlik_toggle">
                <h5 class="mt-6">Cuentas de QLIK</h5>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Tipo</th>
                      <th>Usuario</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="conexionesQlik.length == 0">
                      <td colspan="4">Sin cuentas configuradas</td>
                    </tr>
                    <tr
                      v-for="(conexion, index) in conexionesQlik"
                      :key="index"
                      :value="conexion.qlik_descripcion"
                    >
                      <td>{{ conexion.qlik_descripcion }}</td>
                      <td>
                        <span v-if="conexion.qlik_tipo == 'ams'">AMS</span>
                        <span v-if="conexion.qlik_tipo != 'ams'"
                          >{{obtenerNombreTipo(conexion.qlik_tipo)}}</span
                        >
                      </td>
                      <td>
                        {{ conexion.qlik_usuarioQlik }}
                      </td>
                      <td>
                        <b-button
                          type="is-success"
                          outlined
                          icon-left="clipboard-edit-outline"
                          class="mr-3"
                          @click="editarConexion(conexion, index)"
                          >Editar</b-button
                        >
                        <b-button
                          type="is-danger"
                          outlined
                          icon-left="delete"
                          @click="eliminarConexion(index)"
                          >Eliminar</b-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-collapse>
        <b-collapse
          class="card"
          animation="slide"
          :key="2"
          :open="isOpen == 2"
          @open="isOpen = 2"
          :aria-id="'office-365'"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'office-365'"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">Conexión Office 365</p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <b-field>
                <b-switch v-model="centro.estado_office365"
                  >Activar Office 365
                  <b-tooltip
                    position="is-bottom"
                    multilined
                    type="is-white is-light"
                    size="is-large"
                  >
                    <b-icon
                      icon="information"
                      size="is-small"
                      type="is-primary"
                    >
                    </b-icon>
                    <template v-slot:content>
                      <div class="has-text-left has-text-black">
                        <span>
                          <strong class="has-text-success">Activo:</strong>
                        </span>
                        Activa Office 365 en el centro y la gestión de usuarios
                        de Office 365
                        <br />
                        <br />
                        <div>
                          <span>
                            <strong class="has-text-danger">Inactivo:</strong>
                          </span>
                          No aplica
                        </div>
                      </div>
                    </template>
                  </b-tooltip>
                </b-switch>
              </b-field>
              <div v-if="centro.estado_office365">
                <b-field label="Appid">
                  <b-input
                    v-model="centro.appid"
                    autofocus
                    icon="application"
                    :required="centro.estado_office365"
                  ></b-input>
                </b-field>
                <b-field label="Tenantid">
                  <b-input
                    v-model="centro.tenantid"
                    autofocus
                    icon="identifier"
                    :required="centro.estado_office365"
                  ></b-input>
                </b-field>
                <b-field label="Secretkey">
                  <b-input
                    v-model="centro.secretkey"
                    autofocus
                    type="password"
                    password-reveal
                    icon="key"
                    :required="centro.estado_office365"
                  ></b-input>
                </b-field>
                <b-field label="Scope">
                  <b-input
                    v-model="centro.scope"
                    autofocus
                    icon="dev-to"
                    :required="centro.estado_office365"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </b-collapse>
        <b-collapse
          class="card"
          animation="slide"
          :key="3"
          :open="isOpen == 3"
          @open="isOpen = 3"
          :aria-id="'helpdesk'"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'helpdesk'"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">Conexión Helpdesk</p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <b-field label="Usuario HelpDesk">
                <b-input
                  v-model="centro.helpdesk_customer_user_id"
                  autofocus
                  icon="account"
                ></b-input>
              </b-field>
              <b-field label="Cola destino helpdesk">
                <b-select
                  placeholder="Cola destino"
                  icon="family-tree"
                  v-model="centro.combo_cola_helpdesk"
                >
                  <option value="42">N1: Servidesk</option>
                  <option value="82">Premium Chile</option>
                  <option value="81">Premium Colombia</option>
                  <option value="83">Premium España</option>
                  <option value="84">Premium General</option>
                  <option value="80">Premium Perú</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </b-collapse>
        <b-collapse
          class="card"
          animation="slide"
          :key="4"
          :open="isOpen == 4"
          @open="isOpen = 4"
          :aria-id="'firma-digital'"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'firma-digital'"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">Conexión Firma Digital</p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <b-field>
                <b-switch v-model="centro.vs_activo"
                  >Activar Firma Digital
                </b-switch>
              </b-field>
              <div v-if="centro.vs_activo">
                <b-field label="Usuario">
                  <b-input
                    v-model="centro.vs_username"
                    autofocus
                    icon="account"
                    :required="centro.vs_activo"
                  ></b-input>
                </b-field>
                <b-field label="Contraseña">
                  <b-input
                    v-model="centro.vs_password"
                    autofocus
                    type="password"
                    password-reveal
                    icon="form-textbox-password"
                    :required="centro.vs_activo"
                  ></b-input>
                </b-field>
                <b-field label="Subscription Name">
                  <b-input
                    v-model="centro.vs_subscription_name"
                    autofocus
                    icon="form-textbox"
                    :required="centro.vs_activo"
                  ></b-input>
                </b-field>
                <b-field label="Suscripcion Pass">
                  <b-input
                    v-model="centro.vs_subscription_pass"
                    autofocus
                    type="password"
                    password-reveal
                    icon="lock"
                    :required="centro.vs_activo"
                  ></b-input>
                </b-field>
                <b-field label="Enrollment Code">
                  <b-input
                    v-model="centro.vs_enrollment_code"
                    autofocus
                    icon="barcode"
                    :required="centro.vs_activo"
                  ></b-input>
                </b-field>
                <b-field label="Base URL">
                  <b-input
                    v-model="centro.vs_base_url"
                    autofocus
                    icon="search-web"
                    :required="centro.vs_activo"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

    <b-modal
      v-model="mostrarModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Nueva Conexión"
      close-button-aria-label="Cerrar"
      aria-modal
      :width="900"
    >
      <div class="card" style="width: 900px">
        <div class="card-content">
          <div class="media">
            <div class="media-content columns">
              <div class="column is-6">
                <p class="title is-4">{{ tituloModal }}</p>
              </div>
              <div class="column is-6">
                <p class="subtitle is-6 is-pulled-right"></p>
              </div>
            </div>
          </div>
          <div class="content">
            <b-field label="Descripción">
              <b-input
                v-model="conexion.qlik_descripcion"
                autofocus
                maxlength="50"
                icon="form-textbox"
                @input="puedeGuardarConexion"
              ></b-input>
            </b-field>
            <b-field label="Tipo">
              <b-select
                placeholder="Tipo"
                icon="office-building"
                v-model="conexion.qlik_tipo"
                @input="puedeGuardarConexion"
              >
                <option value="ams">AMS</option>
                <option
                      v-for="tipo in tiposQlik"
                      :key="tipo.id"
                      :value="tipo.id"
                    >
                      {{ tipo.nombre }}
                    </option>
              </b-select>
            </b-field>

            <b-field label="Usuario QLIK">
              <b-input
                v-model="conexion.qlik_usuarioQlik"
                autofocus
                maxlength="50"
                icon="account"
                @input="puedeGuardarConexion"
              ></b-input>
            </b-field>

            <b-field label="Directorio QLIK" v-if="!esAms">
              <b-input
                v-model="conexion.qlik_qlikUserDirectory"
                autofocus
                icon="folder"
                @input="puedeGuardarConexion"
              ></b-input>
            </b-field>

            <b-field label="Usuario Servicio" v-if="esAms">
              <b-input
                v-model="conexion.usuarioServicio"
                autofocus
                maxlength="50"
                icon="account"
                @input="puedeGuardarConexion"
              ></b-input>
            </b-field>

            <b-field label="Clave Servicio" v-if="esAms">
              <b-input
                v-model="conexion.claveServicio"
                autofocus
                type="password"
                password-reveal
                icon="lock"
                @input="puedeGuardarConexion"
              ></b-input>
            </b-field>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button label="Cancelar" @click="cerrarModal" />
          <b-button
            :label="tituloBotonModal"
            @click="guardarConexionQlik"
            type="is-primary"
            :disabled="puedeGuardarConexionQlik"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "IntegracionesFormulario",
  props: {
    centroOrigen: {},
    conexiones: [],
    tiposQlik: [],
  },
  computed: {
    centro() {
      return this.centroOrigen;
    },
    conexionesQlik() {
      if (this.conexiones == undefined) {
        return [];
      }
      return this.conexiones;
    },
  },
  data() {
    return {
      isOpen: 1,
      mostrarModal: false,
      qlik_descripcion: "",
      qlik_tipo: "",
      qlik_usuarioQlik: "",
      qlik_qlikUserDirectory: "",
      tituloModal: "Nueva conexión",
      tituloBotonModal: "Añadir",
      modoEdicion: false,
      conexion: {},
      indexConexiones: 0,
      puedeGuardarConexionQlik: true,
      esAms: false,
    };
  },
  methods: {
    nuevaConexion() {
      this.conexion.qlik_descripcion = "";
      this.conexion.qlik_tipo = "";
      this.conexion.qlik_usuarioQlik = "";
      this.conexion.qlik_qlikUserDirectory = "";
      this.conexion.usuarioServicio = "";
      this.conexion.claveServicio = "";
      this.tituloModal = "Nueva conexión";
      this.tituloBotonModal = "Añadir";
      this.modoEdicion = false;
      this.mostrarModal = true;
    },
    cerrarModal() {
      this.mostrarModal = false;
    },
    guardarConexionQlik() {
      if (!this.modoEdicion) {
        this.conexionesQlik.push({
          qlik_descripcion: this.conexion.qlik_descripcion,
          qlik_tipo: this.conexion.qlik_tipo,
          qlik_usuarioQlik: this.conexion.qlik_usuarioQlik,
          qlik_qlikUserDirectory: this.conexion.qlik_qlikUserDirectory,
          usuarioServicio: this.conexion.usuarioServicio,
          claveServicio: this.conexion.claveServicio,
        });
      } else {
        this.conexionesQlik[this.indexConexiones] = {
          qlik_descripcion: this.conexion.qlik_descripcion,
          qlik_tipo: this.conexion.qlik_tipo,
          qlik_usuarioQlik: this.conexion.qlik_usuarioQlik,
          qlik_qlikUserDirectory: this.conexion.qlik_qlikUserDirectory,
          usuarioServicio: this.conexion.usuarioServicio,
          claveServicio: this.conexion.claveServicio,
        };
      }
      this.mostrarModal = false;
    },
    editarConexion(conexion, index) {
      this.conexion.qlik_descripcion = conexion.qlik_descripcion;
      this.conexion.qlik_tipo = conexion.qlik_tipo;
      this.conexion.qlik_usuarioQlik = conexion.qlik_usuarioQlik;
      this.conexion.qlik_qlikUserDirectory = conexion.qlik_qlikUserDirectory;
      this.conexion.usuarioServicio = conexion.usuarioServicio;
      this.conexion.claveServicio = conexion.claveServicio;
      this.esAms = this.conexion.qlik_tipo == "ams";
      this.tituloModal = "Editar conexión";
      this.tituloBotonModal = "Modificar";
      this.modoEdicion = true;
      this.mostrarModal = true;
      this.indexConexiones = index;
      this.puedeGuardarConexion();
    },
    async eliminarConexion(index) {
      this.conexionesQlik.splice(index, 1);
    },
    puedeGuardarConexion() {
      this.puedeGuardarConexionQlik =
        Object.keys(this.conexion).length === 0 ||
        this.conexion.qlik_descripcion == "" ||
        this.conexion.qlik_tipo == "" ||
        (this.conexion.qlik_tipo == 1 &&
          (this.conexion.qlik_usuarioQlik == "" ||
            this.conexion.qlik_qlikUserDirectory == "")) ||
        (this.conexion.qlik_tipo == "ams" &&
          (this.conexion.qlik_usuarioQlik == "" ||
            this.conexion.usuarioServicio == "" ||
            this.conexion.claveServicio == ""));
      this.esAms = this.conexion.qlik_tipo == "ams";
    },
    obtenerNombreTipo(tipoId){
      for(var tipo in this.tiposQlik){
        if(this.tiposQlik[tipo].id == tipoId){
          return this.tiposQlik[tipo].nombre;
        }
      }
      return "-";
    }
  },
  mounted() {},
};
</script>
