import { backend } from "./backend";

function guardarToken(token) {
  return backend.post("notificaciones/guardarToken", {
    token,
  });
}

function borrarToken(token) {
  return backend.post("notificaciones/borrarToken", {
    token,
  });
}

export default {
  guardarToken,
  borrarToken,
};
