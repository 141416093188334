<template>
  <form
    @submit.prevent="guardarCentro"
    novalidate="true"
    ref="formularioCentro"
  >
    <b-steps
      size="is-medium"
      v-model="activeStep"
      :animated="isAnimated"
      :rounded="isRounded"
      :has-navigation="hasNavigation"
      class="mt-6"
    >
      <b-step-item step="1" label="Ficha" :clickable="isStepsClickable">
        <!-- <h2 class="title has-text-centered">Ficha</h2> -->
        <div class="pt-6 pb-6">
          <ficha
            :centroOrigen="centro"
            :datosAuxiliares="datosAuxiliares"
            ref="ficha"
          ></ficha>
        </div>
      </b-step-item>

      <b-step-item
        step="2"
        label="Configuración"
        :clickable="isStepsClickable"
        :type="{ 'is-success': isProfileSuccess }"
      >
        <!-- <h2 class="title has-text-centered">Configuración</h2> -->
        <div class="pt-6 pb-6">
          <configuracion
            :centroOrigen="centro"
            :entornoId="entornoId"
            ref="configuracion"
          ></configuracion>
        </div>
      </b-step-item>

      <b-step-item step="3" label="Aplicaciones" :clickable="isStepsClickable">
        <!-- <h2 class="title has-text-centered">Aplicaciones</h2> -->
        <div class="pt-6 pb-6">
          <aplicaciones
            :paquetes="datosAuxiliares.paquetes"
            :aplicaciones="datosAuxiliares.aplicaciones"
          ></aplicaciones>
        </div>
      </b-step-item>

      <b-step-item step="4" label="Tipos Expediente" :clickable="isStepsClickable">
        <!-- <h2 class="title has-text-centered">Aplicaciones</h2> -->
        <div class="pt-6 pb-6">
          <tipos-expediente
            :tiposProcesosGenericos="datosAuxiliares.tiposProcesosGenericos"
            :aplicaciones="datosAuxiliares.aplicaciones"
            :supertiposNoCrear="datosAuxiliares.supertiposNoCrear"
            :tiposExpedientesGuardados="tiposExpedientes"
            ref="tiposExpediente"
          ></tipos-expediente>
        </div>
      </b-step-item>

      <b-step-item step="5" label="Integraciones" :clickable="isStepsClickable">
        <!-- <h2 class="title has-text-centered">Integraciones</h2> -->
        <div class="pt-6 pb-6">
          <integraciones :centroOrigen="centro" :conexiones="conexiones" :tiposQlik="servidoresQlik"></integraciones>
        </div>
      </b-step-item>

      <template #navigation="{ previous, next }">
        <div class="level flotarDcha">
          <div class="level-right">
            <b-button
              outlined
              type="is-info is-dark"
              icon-pack="fas"
              icon-left="backward"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
              class="mr-2 level-item"
            >
              Anterior
            </b-button>
            <b-button
              outlined
              type="is-success is-dark"
              icon-pack="fas"
              icon-right="forward"
              :disabled="next.disabled"
              @click.prevent="next.action"
              class="level-item"
            >
              Siguiente
            </b-button>
            <b-button type="is-primary" native-type="submit" class="is-right">
              Guardar
              <span class="icon ml-2">
                <i class="fas fa-save"></i>
              </span>
            </b-button>
          </div>
        </div>
      </template>
    </b-steps>
  </form>
</template>
<script>
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import EstadosCentros from "@/enums/EstadosCentros";
import Ficha from "./formulario/Ficha.vue";
import Configuracion from "./formulario/Configuracion.vue";
import Aplicaciones from "./formulario/Aplicaciones.vue";
import TiposExpediente from "./formulario/TiposExpediente.vue";
import Integraciones from "./formulario/Integraciones.vue";

export default {
  name: "FormularioCentro",
  components: { Ficha, Configuracion, Aplicaciones, TiposExpediente, Integraciones },
  props: {
    id: Number,
    entornoId: Number,
  },
  computed: {
    estados() {
      return EstadosCentros.combo;
    },
    fechaAlta: {
      get() {
        if (this.centro.fechaAlta != "") return new Date(this.centro.fechaAlta);
        return new Date();
      },
      set(value) {
        this.centro.fechaAlta = value;
      },
    },
    idiomas() {
      if (
        this.datosAuxiliares == undefined ||
        this.datosAuxiliares.idiomas == undefined
      ) {
        return [];
      }
      return this.datosAuxiliares.idiomas;
    },
    servidoresQlik() {
      if (
        this.datosAuxiliares == undefined ||
        this.datosAuxiliares.tiposQlik == undefined ||
        this.datosAuxiliares.tiposQlik.servidores == undefined
      ) {
        return [];
      }
      return this.datosAuxiliares.tiposQlik.servidores;
    },
  },
  data() {
    return {
      isStepsClickable: true,
      isAnimated: true,
      isRounded: true,
      hasNavigation: true,
      activeStep: 0,
      isProfileSuccess: false,
      datosAuxiliares: {},
      centro: {},
      entornoDestino: "",
      erroresCampos: {
        ficha: 0,
        configuracion: 0,
        tiposExpediente: 0,
      },
      conexiones: [],
      tiposExpedientes: [],
    };
  },
  methods: {
    cargar(id) {
      return apiCentros.cargar(id).then((resultado) => {
        this.centro = resultado.centro;
      });
    },
    mostrarMensaje(msg, tipo, resumen) {
      if (tipo == "is-danger") {
        this.$buefy.toast.open({
          message: msg,
          type: tipo,
        });
        return;
      }
      this.$buefy.dialog.alert({
        title: msg,
        message: resumen,
        type: tipo,
        hasIcon: false,
        icon: "info-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    clearDate() {
      this.centro.fechaAlta = null;
    },
    marcarPorDefecto() {
      for (var aplicacion in this.datosAuxiliares.aplicaciones) {
        if (
          this.datosAuxiliares.aplicaciones[aplicacion].id_paquete == 44 &&
          !this.datosAuxiliares.aplicaciones[aplicacion].directorio
            .toUpperCase()
            .includes("TA2017_inicio".toUpperCase()) &&
          this.datosAuxiliares.aplicaciones[aplicacion].seleccionada ==
            undefined
        ) {
          this.datosAuxiliares.aplicaciones[aplicacion].seleccionada = true;
        }
      }
    },
    guardarCentro() {
      var centroForm = document.getElementsByTagName("form");
      this.erroresCampos.ficha = 0;
      this.erroresCampos.configuracion = 0;
      this.erroresCampos.tiposExpediente = 0;
      var res = false;

      if (centroForm[0].checkValidity() == false) {
        var formulario = this.$refs;
        for (var elemento in formulario) {
          var hijos = formulario[elemento].$refs;
          for (var hijo in hijos) {
            if (typeof hijos[hijo] != "undefined") {
              if(Array.isArray(hijos[hijo])){
                for(var nieto in hijos[hijo]){
                  res = hijos[hijo][nieto].checkHtml5Validity();
                  if (!res) {
                    this.erroresCampos[elemento]++;
                  }
                }
              }else{
                res = hijos[hijo].checkHtml5Validity();
                if (!res) {
                  this.erroresCampos[elemento]++;
                }
              }
            }
          }
        }
        if (this.erroresCampos.ficha > 0) {
          this.activeStep = 0;
          return;
        } else if (this.erroresCampos.configuracion > 0) {
          this.activeStep = 1;
          return;
        } else if (this.erroresCampos.tiposExpediente > 0) {
          this.activeStep = 3;
          return;
        }
      }

      this.$buefy.dialog.confirm({
        title: "Atención",
        message:
          "¿Desea guardar el centro en el entorno <b>" +
          this.entornoDestino.nombre +
          "</b> ?",
        confirmText: "Guardar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.procesarGuardar(),
      });
    },
    async procesarGuardar() {
      var res = await apiCentros.guardarCentro({
        entornoId: this.entornoId,
        path: "centros/guardar",
        centro: this.centro,
        paquetes: this.obtenerPaquetesSeleccionados(),
        aplicaciones: this.obtenerAplicacionesSeleccionados(),
        tiposExpedientesGenericos: this.obtenerTiposExpedientesGenericosSeleccionados(),
        conexiones: this.conexiones,
        tiposExpedientes: this.tiposExpedientes,
      });
      this.mostrarMensaje(
        "Centro creado correctamente",
        "is-success",
        res.resultado.MSG
      );
      await apiCentros.guardarRegistroCreacionCentro({
        'entornoId': this.entornoId,
        'centroId': res.resultado.ID_CENTRO,
        'resultado': res.resultado.MSG,
      });
      this.$router.push({ path: "/gestion-centros" });
    },
    obtenerPaquetesSeleccionados() {
      var idsPaquetes = [];
      for (var aplicacion in this.datosAuxiliares.aplicaciones) {
        if (
          this.datosAuxiliares.aplicaciones[aplicacion].seleccionada !=
            undefined &&
          this.datosAuxiliares.aplicaciones[aplicacion].seleccionada
        ) {
          idsPaquetes.push(
            this.datosAuxiliares.aplicaciones[aplicacion].id_paquete
          );
        }
      }

      var paquetes = [];
      for (var paquete in this.datosAuxiliares.paquetes) {
        if (
          idsPaquetes.includes(
            this.datosAuxiliares.paquetes[paquete].id_paquete
          )
        ) {
          paquetes.push(this.datosAuxiliares.paquetes[paquete]);
        }
      }
      return paquetes;
    },
    obtenerTiposExpedientesGenericosSeleccionados() {
      var idsTiposExpedientes = [];
      for (var tipoProcesoGenerico in this.datosAuxiliares.tiposProcesosGenericos) {
        if (
          this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenerico].seleccionado !=
            undefined &&
          this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenerico].seleccionado
        ) {
          idsTiposExpedientes.push(
            this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenerico].id
          );
        }
      }

      var tiposProcesosGenericos = [];
      for (var tipoProcesoGenericoA in this.datosAuxiliares.tiposProcesosGenericos) {
        if (
          idsTiposExpedientes.includes(
            this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenericoA].id
          )
        ) {
          tiposProcesosGenericos.push(this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenericoA]);
        }
      }
      return tiposProcesosGenericos;
    },
    obtenerAplicacionesSeleccionados() {
      var aplicaciones = [];
      for (var aplicacion in this.datosAuxiliares.aplicaciones) {
        if (
          this.datosAuxiliares.aplicaciones[aplicacion].seleccionada !=
            undefined &&
          this.datosAuxiliares.aplicaciones[aplicacion].seleccionada
        ) {
          aplicaciones.push(this.datosAuxiliares.aplicaciones[aplicacion]);
        }
      }
      return aplicaciones;
    },
  },
  async mounted() {
    if (this.id) {
      this.cargar(this.id);
    } else {
      this.centro = await apiCentros.crear();
      this.centro.tipologia_ex = 1;
      this.centro.tipologia_centro = 4;
      this.centro.diversificar_por_entidad = true;
      this.centro.copiar_tipos_expedientes = true;
      this.centro.vincular_entidad_grupo_org = true;
      this.centro.copiar_tipos_expedientes = true;
      this.centro.centro_copia_controlada = true;
    }

    this.entornoDestino = await apiEntornos.cargar(this.entornoId);
    this.entornoDestino = this.entornoDestino.entorno;
    this.datosAuxiliares = await apiCentros.cargarDatosAuxiliares(
      this.entornoId
    );
    this.marcarPorDefecto();
  },
  watch: {
    idiomas: {
      handler: function () {
        for (var idioma in this.datosAuxiliares.idiomas) {
          if (this.datosAuxiliares.idiomas[idioma].pordefecto == 1) {
            this.centro.idiomaId.push(this.datosAuxiliares.idiomas[idioma]);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
.datepicker.control .dropdown-item:hover {
  background: #fff;
}

.datepicker.control .dropdown-content {
  width: 320px;
}

.flotarDcha {
  flex-direction: row-reverse;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  box-shadow: #888 0px 0px 10px;
  padding: 20px;
  border-radius: 10px;
}
</style>
