<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/centros"
            >Centros</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" to="/centros/crear" active
            >Crear</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
        <div class="divider is-primary is-right mt-2"></div>
      <formulario-centro></formulario-centro>
    </div>
  </app-layout>
</template>

<script>
import FormularioCentro from "./Formulario.vue";

export default {
  components: { FormularioCentro },
  name: "CrearCentro",
  data() {
    return {};
  },
};
</script>
