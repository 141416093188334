<template>
  <form>
    <div class="columns is-desktop">
      <div class="column">
        <b-field label="Nombre">
          <b-input
            v-model="centro.nombre"
            autofocus
            maxlength="50"
            icon="office-building"
            required
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Responsable">
          <b-select
            placeholder="Responsable"
            icon="account-lock"
            v-model="centro.usuarioId"
            required
          >
            <option
              v-for="usuario in usuarios"
              :key="usuario.id"
              :value="usuario.id"
            >
              {{ usuario.apellidos }}, {{ usuario.nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns is-desktop">
      <div class="column">
        <b-field label="Sede">
          <b-select
            placeholder="Sede"
            icon="account-lock"
            v-model="centro.sedeId"
            required
          >
            <option v-for="sede in sedes" :key="sede.id" :value="sede.id">
              {{ sede.nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="País">
          <b-select
            placeholder="País"
            icon="account-lock"
            v-model="centro.paisId"
            required
          >
            <option v-for="pais in paises" :key="pais.id" :value="pais.id">
              {{ pais.nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-2">
        <b-field label="Fecha de Alta">
          <b-datepicker
            v-model="fechaAlta"
            locale="es-ES"
            placeholder="Click para seleccionar..."
            icon="calendar-today"
            :icon-right="centro.fechaAlta ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Estado">
          <b-select
            placeholder="Estado"
            v-model="centro.estado"
            icon="office-building"
            required
          >
            <option
              v-for="estado in estados"
              :key="estado.value"
              :value="estado.value"
            >
              {{ estado.text }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <b-button type="is-primary" native-type="submit" class="mt-5 is-right">
      Guardar
      <span class="icon ml-2">
        <i class="fas fa-save"></i>
      </span>
    </b-button>
  </form>
</template>
<script>
import apiSedes from "@/services/sedes.js";
import apiUsuarios from "@/services/usuarios.js";
import apiCentros from "@/services/centros.js";
import apiPaises from "@/services/paises.js";
import EstadosCentros from "@/enums/EstadosCentros";

export default {
  name: "FormularioCentro",
  props: {
    id: Number,
  },
  computed: {
    estados() {
      return EstadosCentros.combo;
    },
    fechaAlta: {
      get() {
        if(this.centro.fechaAlta != "") return new Date(this.centro.fechaAlta);
        return new Date();
      },
      set(value) {
        this.centro.fechaAlta = value;
      }
    }
  },
  data() {
    return {
      usuarios: Array,
      sedes: Array,
      paises: Array,
      centro: Object,
      mensajeError: "",
      tipoMensaje: "",
    };
  },
  methods: {
    cargar(id) {
      return apiCentros.cargar(id).then((resultado) => {
        this.centro = resultado.centro;
      });
    },
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
    clearDate() {
      this.centro.fechaAlta = null;
    },
  },
  async mounted() {
    if (this.id) {
      this.cargar(this.id);
    } else {
      this.centro = apiCentros.crear();
    }
    this.sedes = await apiSedes.autocompletar();
    this.usuarios = await apiUsuarios.listar();
    this.paises = await apiPaises.listar();
  },
};
</script>
<style>
.datepicker.control .dropdown-item:hover {
  background: #fff;
}

.datepicker.control .dropdown-content {
  width: 320px;
}
</style>
