import { backend } from "./backend";

function crear() {
  return {
    id: 0,
    uuid: '',
    progreso: 0,
    total: 0,
    mensaje: ''
  };
}

function cargarProgreso(uuid) {
  return backend.get("estatusImportaciones/cargar&uuid=" + uuid).then((response) => {
    if(response.progreso === undefined) return crear();
    return response;
  });
}


export default {
  crear,
  cargarProgreso,
};
