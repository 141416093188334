export default [
  {
    path: "/paises",
    component: require("@/views/paises/ListarPage").default,
  },
  {
    path: "/paises/editar/:id",
    component: require("@/views/paises/EditarPage").default,
  },
  {
    path: "/paises/crear",
    component: require("@/views/paises/CrearPage").default,
  },
];
