import { listado } from "./listado";
import { backend } from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    estado: 0,
  };
}

function listar(params) {
  return listado("sedes/listar", params);
}

function autocompletar(params) {
  return listado("sedes/autocompletar", params);
}

function cargar(id) {
  return backend.get("sedes/cargar&id=" + id).then((response) => {
    var data = response;
    data.sede.estado = Boolean(response.sede.estado);
    return data;
  });
}

function insertar(sede, paises) {
  return backend.post("sedes/insertar", {
    sede,
    paises,
  });
}

function actualizar(sede, paises) {
  return backend.post("sedes/actualizar", {
    sede,
    paises,
  });
}

function eliminar(id) {
  return backend.post("sedes/eliminar", { id });
}

export default {
  crear,
  listar,
  cargar,
  insertar,
  actualizar,
  eliminar,
  autocompletar,
};
