import { backend } from "./backend";

function listarTipos(params) {
  return backend.get("clienteEntorno&entornoId=" + params.entornoOrigen + "&centroId=" + params.centroOrigen.id + "&path=contratistas/listarTipos").then((response) => {
    for (let i = 0; i < response.tiposContratista.length; i++) {
      response.tiposContratista[i].accion = "anadir";
    }
    return response.tiposContratista;
  });
}

function exportar(entornoOrigen, centroOrigen, importacion) {
  return backend.post("contratistas/exportar", {
    entornoOrigen: entornoOrigen,
    centroOrigen: centroOrigen,
    importacion: importacion,
  });
}

function importar(entornoDestino, centroDestino, exportacion) {
  return backend.post("contratistas/importar", {
    entornoDestino: entornoDestino,
    centroDestino: centroDestino,
    exportacion: exportacion,
  });
}

export default {
  listarTipos,
  exportar,
  importar,
};
