import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins";
import "./styles/main.scss";
import AppLayout from '@/components/AppLayout';
import LoginLayout from '@/components/LoginLayout';

Vue.config.productionTip = false;

// DayJS
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

Vue.component('app-layout', AppLayout);
Vue.component('login-layout', LoginLayout);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
