export const importacionesAcciones = Object.freeze({
  'anadir': "Añadir",
  'sustituir': "Sustituir",
});

export const importacionesPersonasNombres = Object.freeze({
  'fichaPersona': "Ficha de persona",
  'fichaCargo': "Ficha de cargo",
  'fichaRol': "Ficha de rol",
  'fichaGrupoOrganizacion': "Ficha de Grupo de organización",
  'roles': "Roles",
  'vistas': "Vistas",
});

export const importacionesEtiquetasPermisosNombres = Object.freeze({
  'bpm': "BPM",
  'gestorDocumental': "Gestor Documental",
  'entidades': "Entidades",
  'riesgos': "Riesgos",
  'contratistas': "Contratistas",
  'estrategia': "Estrategia",
  'personas': "Personas",
  'encuestas': "Encuestas",
});

export default { importacionesAcciones, importacionesPersonasNombres, importacionesEtiquetasPermisosNombres };
