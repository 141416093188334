export const ADMIN = 3;
export const CONSULTOR = 2;
export const LECTOR = 1;

export const nombres = Object.freeze({
  [ADMIN]: "Administrador",
  [CONSULTOR]: "Consultor",
  [LECTOR]: "Lector",
});

export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});

export default { ADMIN, CONSULTOR, LECTOR, nombres, combo };
