import { backend } from "./backend";
import { formatDatetime } from "@/services/date";

function obtenerCentrosUsuario(fechas, entorno) {
  var fechaDesde = formatDatetime(fechas[0]);
  var fechaHasta = formatDatetime(fechas[1]);

  return backend
    .get(
      "dashboard/obtenerCentros&fecha_desde=" +
        fechaDesde +
        "&fecha_hasta=" +
        fechaHasta +
        "&entornoId=" +
        entorno
    )
    .then((response) => {
      return response;
    });
}

function obtenerDatosTabla(fechas, entorno) {
  var fechaDesde = formatDatetime(fechas[0]);
  var fechaHasta = formatDatetime(fechas[1]);

  return backend
    .get(
      "dashboard/obtenerDatosTabla&fecha_desde=" +
        fechaDesde +
        "&fecha_hasta=" +
        fechaHasta +
        "&entornoId=" +
        entorno
    )
    .then((response) => {
      return response;
    });
}

function obtenerColumnasTabla() {
  if (localStorage.columnasTabla) return JSON.parse(localStorage.columnasTabla);
  var columnas = [
    {
      campo: "nombre",
      titulo: "Centro",
      visible: true,
      tipoFecha: false,
      centrada: false,
    },
    {
      campo: "responsable",
      titulo: "Responsable",
      visible: true,
      tipoFecha: false,
      centrada: false,
    },
    {
      campo: "fechaInicio",
      titulo: "Fecha Inicio",
      visible: true,
      tipoFecha: true,
      centrada: true,
    },
    {
      campo: "fechaRenovacion",
      titulo: "Fecha Renovación",
      visible: false,
      tipoFecha: true,
      centrada: true,
    },
    {
      campo: "tipoAlcance",
      titulo: "Tipo Alcance",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "tipo",
      titulo: "Tipo",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "pais",
      titulo: "País",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "ultimoAcceso",
      titulo: "Último Acceso",
      visible: true,
      tipoFecha: true,
      centrada: true,
    },
    {
      campo: "numUsuarios",
      titulo: "Núm. Usuarios",
      visible: true,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "numPersonasActivas",
      titulo: "Núm. Personas Activas",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "usuariosVsPersonas",
      titulo: "Usuarios vs Personas",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "numUsuariosAccesoMes",
      titulo: "Usuarios Activos Últ. mes",
      visible: true,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "tasaAcceso",
      titulo: "Tasa de Acceso",
      visible: true,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "procesos",
      titulo: "Procesos",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "numTareas",
      titulo: "Núm. Tareas",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "numRegistros",
      titulo: "Núm. Registros",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "numIndicadores",
      titulo: "Núm. Indicadores",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "numEncuestas",
      titulo: "Núm. Encuestas",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "numDiagnosticoRadar",
      titulo: "Núm. Diag. Radar",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "tiempoMedioSesion",
      titulo: "Tiempo medio sesión (min)",
      visible: true,
      tipoFecha: false,
      centrada: true,
    },
    {
      campo: "aplicacionMasUsada",
      titulo: "Aplicación más usada",
      visible: false,
      tipoFecha: false,
      centrada: true,
    },
  ];

  localStorage.columnasTabla = JSON.stringify(columnas);
  return columnas;
}

export default {
  obtenerDatosTabla,
  obtenerCentrosUsuario,
  obtenerColumnasTabla,
};
