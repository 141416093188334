export default [
  {
    path: "/entornos",
    component: require("@/views/entornos/ListarPage").default,
  },
  {
    path: "/entornos/editar/:id",
    component: require("@/views/entornos/EditarPage").default,
  },
  {
    path: "/entornos/crear",
    component: require("@/views/entornos/CrearPage").default,
  },
];
