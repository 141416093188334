import ListarPage from "@/views/centros/ListarPage.vue";
import ImportarPersonasPage from "@/views/centros/personas/ImportarPage.vue";
import ImportarEntidadesPage from "@/views/centros/entidades/ImportarPage.vue";
import ImportarMapasProcesosPage from "@/views/centros/mapasProcesos/ImportarPage.vue";
import ImportarIndicadoresPage from "@/views/centros/indicadores/ImportarPage.vue";
import ImportarBpmPage from "@/views/centros/bpm/ImportarPage.vue";
import ImportarRiesgosPage from "@/views/centros/riesgos/ImportarPage.vue";
import ImportarDashboardsPage from "@/views/centros/dashboards/ImportarPage.vue";
import ImportarEncuestasPage from "@/views/centros/encuestas/ImportarPage.vue";
import ImportarProyectosPage from "@/views/centros/proyectos/ImportarPage.vue";
import ImportarRadarPage from "@/views/centros/radar/ImportarPage.vue";
import ImportarContratistasPage from "@/views/centros/contratistas/ImportarPage.vue";
import ImportarBscPage from "@/views/centros/bsc/ImportarPage.vue";
import ImportarReportsPage from "@/views/centros/reports/ImportarPage.vue";
import ImportarEtiquetasPermisosPage from "@/views/centros/etiquetasPermisos/ImportarPage.vue";
import HistoricoPage from "@/views/centros/historicos/HistoricoPage.vue";

export default [
  {
    path: "/centros",
    component: ListarPage,
  },
  {
    path: "/centros/editar/:id",
    component: require("@/views/centros/EditarPage").default,
  },
  {
    path: "/centros/crear",
    component: require("@/views/centros/CrearPage").default,
  },
  {
    path: "/centros/personas/:id/:entorno",
    component: ImportarPersonasPage,
  },
  {
    path: "/centros/entidades/:id/:entorno",
    component: ImportarEntidadesPage,
  },
  {
    path: "/centros/mapasProcesos/:id/:entorno",
    component: ImportarMapasProcesosPage,
  },
  {
    path: "/centros/indicadores/:id/:entorno",
    component: ImportarIndicadoresPage,
  },
  {
    path: "/centros/bpm/:id/:entorno",
    component: ImportarBpmPage,
  },
  {
    path: "/centros/riesgos/:id/:entorno",
    component: ImportarRiesgosPage,
  },
  {
    path: "/centros/dashboards/:id/:entorno",
    component: ImportarDashboardsPage,
  },
  {
    path: "/centros/encuestas/:id/:entorno",
    component: ImportarEncuestasPage,
  },
  {
    path: "/centros/proyectos/:id/:entorno",
    component: ImportarProyectosPage,
  },
  {
    path: "/centros/radar/:id/:entorno",
    component: ImportarRadarPage,
  },
  {
    path: "/centros/contratistas/:id/:entorno",
    component: ImportarContratistasPage,
  },
  {
    path: "/centros/bsc/:id/:entorno",
    component: ImportarBscPage,
  },
  {
    path: "/centros/reports/:id/:entorno",
    component: ImportarReportsPage,
  },
  {
    path: "/centros/etiquetasPermisos/:id/:entorno",
    component: ImportarEtiquetasPermisosPage,
  },
  {
    path: "/centros/historico/:aplicacion/:id/:entorno",
    component: HistoricoPage,
  },
];
