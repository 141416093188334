<template>
  <div>
    <form @submit.prevent="importarPersonas">
      <div class="columns is-desktop">
        <div class="column is-3">
          <b-field label="Entorno de Referencia">
            <b-select
              placeholder="Entorno"
              icon="server"
              required
              v-model="entornoOrigen"
            >
              <option
                v-for="entorno in entornos"
                :key="entorno.id"
                :value="entorno.id"
              >
                {{ entorno.nombre }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Centro de Referencia">
            <multiselect
              v-model="centroOrigen"
              id="ajax"
              label="nombre"
              track-by="id"
              placeholder="Buscar centro..."
              selectLabel="Pulsa enter para seleccionar"
              open-direction="bottom"
              :preserve-search="true"
              :options="centros"
              :multiple="false"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="1000"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="false"
              :disabled="entornoOrigen == 0"
              @search-change="listarCentros"
              @select="cambiarCentroOrigen"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="selectTag"
                  ><span>{{ option.nombre }}</span
                  ><span class="ml-2" @click="remove(option)">
                    <i class="fas fa-times-circle"></i> </span></span
              ></template>
              <template slot="afterList" v-if="tienePaginaSiguiente">
                <div v-observe-visibility="estaAlFinal" />
                Cargando...
              </template>
              <span slot="noResult">No se ha encontrado ningún centro.</span>
            </multiselect>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <strong>Seleccione la información a importar</strong>
        </div>
        <div class="column">
          <strong>Acción al importar</strong>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <b-field class="mt-5">
            <b-checkbox v-model="importacion.fichaPersona.importar"
              >Ficha de persona: Permitirá copiar la estructura de
              campos</b-checkbox
            >
          </b-field>
        </div>
        <div class="column">
          <b-field class="mt-4">
            <b-select
              placeholder="Elija acción..."
              icon="file-replace"
              v-model="importacion.fichaPersona.accion"
              :disabled="!importacion.fichaPersona.importar"
            >
              <option value="sustituir">Sustituir</option>
              <option value="anadir">Añadir</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <b-field class="mt-2">
            <b-checkbox v-model="importacion.fichaCargo.importar"
              >Ficha de cargo: Permitirá copiar la estructura de
              campos</b-checkbox
            >
          </b-field>
        </div>
        <div class="column">
          <b-field>
            <b-select
              placeholder="Elija acción..."
              icon="file-replace"
              v-model="importacion.fichaCargo.accion"
              :disabled="!importacion.fichaCargo.importar"
            >
              <option value="sustituir">Sustituir</option>
              <option value="anadir">Añadir</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <b-field class="mt-2">
            <b-checkbox v-model="importacion.fichaRol.importar"
              >Ficha de rol: Permitirá copiar la estructura de
              campos</b-checkbox
            >
          </b-field>
        </div>
        <div class="column">
          <b-field>
            <b-select
              placeholder="Elija acción..."
              icon="file-replace"
              v-model="importacion.fichaRol.accion"
              :disabled="!importacion.fichaRol.importar"
            >
              <option value="sustituir">Sustituir</option>
              <option value="anadir">Añadir</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <b-field class="mt-2">
            <b-checkbox v-model="importacion.fichaGrupoOrganizacion.importar"
              >Ficha de Grupo de organización: Permitirá copiar la estructura de
              campos</b-checkbox
            >
          </b-field>
        </div>
        <div class="column">
          <b-field>
            <b-select
              placeholder="Elija acción..."
              icon="file-replace"
              v-model="importacion.fichaGrupoOrganizacion.accion"
              :disabled="!importacion.fichaGrupoOrganizacion.importar"
            >
              <option value="sustituir">Sustituir</option>
              <option value="anadir">Añadir</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <b-field class="mt-2">
            <b-checkbox v-model="importacion.roles.importar"
              >Roles: permite copiar los roles con todos los campos
              rellenos</b-checkbox
            >
          </b-field>
        </div>
        <div class="column">
          <b-field>
            <b-select
              placeholder="Elija acción..."
              icon="file-replace"
              v-model="importacion.roles.accion"
              :disabled="!importacion.roles.importar"
            >
              <option value="sustituir">Sustituir</option>
              <option value="anadir">Añadir</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <b-field class="mt-2">
            <b-checkbox v-model="importacion.vistas.importar"
              >Vistas: Siempre con los campos que existan en el centro
              destino</b-checkbox
            >
          </b-field>
        </div>
        <div class="column">
          <b-field>
            <b-select
              placeholder="Elija acción..."
              icon="file-replace"
              v-model="importacion.vistas.accion"
              :disabled="!importacion.vistas.importar"
            >
              <option value="sustituir">Sustituir</option>
              <option value="anadir">Añadir</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div class="level-right mr-2">
        <b-button
          type="is-danger"
          outlined
          @click="cancelar"
          class="mt-5 is-right mr-2"
        >
          Cancelar
          <span class="icon ml-2">
            <i class="fas fa-save"></i>
          </span>
        </b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5 is-right"
          :disabled="disableImportar"
        >
          Aceptar
          <span class="icon ml-2 alinearBot">
            <b-icon icon="cog"></b-icon>
          </span>
        </b-button>
      </div>
    </form>
    <modal-progreso
      :faseProceso="faseProceso"
      :textoProgreso="textoProgreso"
      :valorProgreso="valorProgreso"
      :mostrarBarraProgreso="mostrarBarraProgreso"
    ></modal-progreso>
  </div>
</template>
<script>
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import apiPersonas from "@/services/personas.js";
import apiHistoricoImportaciones from "@/services/historicoImportaciones.js";
import apiEstatusImportaciones from "@/services/estatusImportaciones.js";
import Multiselect from "vue-multiselect";
import ModalProgreso from "@/components/ModalProgreso.vue";
import { uuid } from "vue-uuid";

export default {
  name: "FormularioPersonas",
  components: { Multiselect, ModalProgreso },
  props: {
    id: Number,
    entornoId: Number,
    centroDestinoNombre: String,
  },
  computed: {
    hayCentros() {
      var centros = Boolean(this.centros.length);
      return !centros;
    },
  },
  data() {
    return {
      centros: [],
      mensajeError: "",
      tipoMensaje: "",
      entorno: apiEntornos.crear(),
      entornos: [],
      centroOrigenSeleccionado: "",
      isLoading: false,
      pagina: 0,
      tienePaginaSiguiente: true,
      importacion: {
        fichaPersona: {
          importar: false,
          accion: "anadir",
        },
        fichaCargo: {
          importar: false,
          accion: "anadir",
        },
        fichaRol: {
          importar: false,
          accion: "anadir",
        },
        fichaGrupoOrganizacion: {
          importar: false,
          accion: "anadir",
        },
        roles: {
          importar: false,
          accion: "anadir",
        },
        vistas: {
          importar: false,
          accion: "anadir",
        },
      },
      entornoOrigen: 0,
      centroOrigen: 0,
      entornoDestino: this.entornoId,
      centroDestino: this.id,
      disableImportar: true,
      mostrarBarraProgreso: false,
      textoProgreso: "",
      valorProgreso: 0,
      faseProceso: "",
      uuid: "",
    };
  },
  methods: {
    mostrarMensaje(msg, tipo, resumen) {
      if (tipo == "is-danger") {
        this.$buefy.toast.open({
          message: msg,
          type: tipo,
        });
        return;
      }
      this.$buefy.dialog.alert({
        title: msg,
        message: resumen,
        type: tipo,
        hasIcon: false,
        icon: "info-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    cancelar() {
      this.$router.push({ path: "/centros" });
    },
    async listarCentros(term) {
      if (term != undefined && term == "") {
        this.centros = [];
      } else if (term == undefined) {
        term = "";
      }
      var params = {
        entornoId: this.entornoOrigen,
        path: "centros/listar",
        limite: 50,
        pagina: this.pagina,
        term: term,
      };
      if (term != "") {
        params = {
          entornoId: this.entornoOrigen,
          path: "centros/listar",
          limite: 50,
          pagina: 0,
          term: term,
        };
      }

      return apiCentros.listar(params).then((respuesta) => {
        if (!respuesta.centros.length) {
          this.tienePaginaSiguiente = false;
        }
        if (term != "") {
          this.centros = respuesta.centros;
          this.tienePaginaSiguiente = false;
          return;
        }
        this.tienePaginaSiguiente = true;
        this.centros = this.centros.concat(respuesta.centros);
      });
    },
    estaAlFinal(alfinal) {
      if (alfinal) {
        this.pagina++;
        this.listarCentros();
      }
    },
    actualizarEntorno() {
      this.pagina = 0;
      this.centros = [];
    },
    importarPersonas() {
      this.$buefy.dialog.confirm({
        title: "Atención",
        message:
          "Va a importar la información en el centro <b>" +
          this.centroDestinoNombre +
          "</b>",
        confirmText: "Importar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.procesarImportarPersonas(),
      });
    },
    async procesarImportarPersonas() {
      var msg = "Importación realizada.";
      var tipo = "is-success";
      var resumen = "Se han importado: ";
      this.uuid = uuid.v1();
      this.faseProceso = "Exportación";
      this.valorProgreso = 100 / Object.keys(this.importacion).length;
      this.textoProgreso = Object.keys(this.importacion)[0];

      this.mostrarProgreso();

      var resultadoExportacion = await apiPersonas.exportar(
        this.entornoOrigen,
        this.centroOrigen.id,
        this.importacion,
        this.uuid
      );
      if (resultadoExportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoExportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }

      this.uuid = uuid.v1();
      this.faseProceso = "Importación";
      this.valorProgreso = 100 / Object.keys(this.importacion).length;
      this.textoProgreso = Object.keys(this.importacion)[0];
      this.mostrarProgreso(false);

      var resultadoImportacion = await apiPersonas.importar(
        this.entornoDestino,
        this.centroDestino,
        resultadoExportacion,
        this.uuid
      );
      if (resultadoImportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoImportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }
      this.guardarHistorico(this.importacion, resultadoImportacion);
      resumen = this.maquetarResumen(resultadoImportacion);
      this.mostrarBarraProgreso = false;
      this.mostrarMensaje(msg, tipo, resumen);
      // this.$router.push({ path: "/centros" });
    },
    guardarHistorico(importacion, resultado) {
      var historicoImportaciones = apiHistoricoImportaciones.crear();
      var res = JSON.stringify(resultado);
      historicoImportaciones.aplicacion = "personas";
      historicoImportaciones.entornoDestinoId = this.entornoDestino;
      historicoImportaciones.centroDestinoId = this.centroDestino;
      historicoImportaciones.centroDestino = this.centroDestinoNombre;
      historicoImportaciones.entornoOrigenId = this.entornoOrigen;
      historicoImportaciones.centroOrigenId = this.centroOrigen.id;
      historicoImportaciones.centroOrigen = this.centroOrigenSeleccionado;
      historicoImportaciones.importacion = this.limpiarImportacion(
        importacion,
        resultado
      );
      historicoImportaciones.resultado = res;

      apiHistoricoImportaciones.insertar(historicoImportaciones);
    },
    cambiarCentroOrigen(opcion) {
      this.centroOrigenSeleccionado = opcion.nombre;
    },
    limpiarImportacion(importacion, res) {
      var resultado = {};
      for (var prop in importacion) {
        if (
          !!Object.getOwnPropertyDescriptor(importacion[prop], "importar") &&
          importacion[prop].importar
        ) {
          resultado[prop] = {
            accion: importacion[prop].accion,
            resultado: res[prop].resultado,
          };
        }
      }
      return JSON.stringify(resultado);
    },
    puedeImportar() {
      var res = Boolean(
        this.entornoOrigen &&
          this.centroOrigen.id != undefined &&
          this.centroOrigen.id &&
          (this.importacion.fichaPersona.importar ||
            this.importacion.fichaCargo.importar ||
            this.importacion.fichaRol.importar ||
            this.importacion.fichaGrupoOrganizacion.importar ||
            this.importacion.roles.importar ||
            this.importacion.vistas.importar)
      );
      this.disableImportar = !res;
    },
    maquetarResumen(resumen) {
      var res = "";
      var fila = "";
      var icono = "";
      for (var aplicacion in resumen) {
        icono = '<i class="fas fa-check has-text-success is-size-3"></i>';
        if (resumen[aplicacion].resultado != "ok")
          icono =
            '<i class="fas fa-exclamation-triangle has-text-danger is-size-3"></i>';
        fila =
          '<div class="columns"><div class="column is-1">' +
          icono +
          '</div><div class="column is-11">' +
          resumen[aplicacion].mensaje +
          "</div></div>";
        res += fila;
      }
      return res;
    },
    async mostrarProgreso(importacion = true, that = this) {
      apiEstatusImportaciones
        .cargarProgreso(this.uuid)
        .then((respuesta) => {
          if (
            (importacion &&
              (respuesta.total == 0 || respuesta.progreso < respuesta.total)) ||
            (!importacion && that.mostrarBarraProgreso)
          ) {
            that.mostrarBarraProgreso = true;
            if (respuesta.mensaje != "") that.textoProgreso = respuesta.mensaje;
            if (respuesta.valorProgreso != 0)
              that.valorProgreso = (100 * respuesta.progreso) / respuesta.total;
            setTimeout(function () {
              that.mostrarProgreso(importacion);
            }, 1000);
          }
        });
    },
  },
  async mounted() {
    this.entornos = await apiEntornos.listar();
  },
  watch: {
    centroOrigen: function () {
      this.puedeImportar();
    },
    entornoOrigen: function () {
      this.puedeImportar();
      this.actualizarEntorno();
    },
    "importacion.fichaPersona.importar": function () {
      this.puedeImportar();
    },
    "importacion.fichaCargo.importar": function () {
      this.puedeImportar();
    },
    "importacion.fichaRol.importar": function () {
      this.puedeImportar();
    },
    "importacion.fichaGrupoOrganizacion.importar": function () {
      this.puedeImportar();
    },
    "importacion.roles.importar": function () {
      this.puedeImportar();
    },
    "importacion.vistas.importar": function () {
      this.puedeImportar();
    },
  },
};
</script>
<style>
.dialog.modal .modal-card {
  width: 50vw;
  max-width: 50vw;
}
</style>
