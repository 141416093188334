import { backend } from "./backend";

function obtenerSignificanciaIndicadores() {
  return backend.get("significancias/listar").then((response) => {
    return response;
  });
}

function guardarSignificanciaIndicadores(significancias) {
  return backend.post("significancias/guardar", {
    significancias,
  });
}

function listarAvisos() {
  return backend.get("significancias/listarAvisos").then((response) => {
    return response;
  });
}

function leerAviso(avisoId) {
  return backend.post("significancias/leerAviso", {
    avisoId,
  });
}

export default {
  obtenerSignificanciaIndicadores,
  guardarSignificanciaIndicadores,
  listarAvisos,
  leerAviso,
};
