<template>
  <b-sidebar
    position="static"
    :mobile="mobile"
    :expand-on-hover="expandOnHover"
    :reduce="reduce"
    :delay="expandWithDelay ? 500 : null"
    type="is-transparent"
    open
  >
    <div class="p-1">
      <div class="block pt-5">
        <img
          src="/images/logoisotools2020.png"
          alt="Herramientas del Consultor"
        />
      </div>
      <p class="p-2 has-text-white mb-4">
        <b>Herramienta del Consultor:</b> <br />
        <b-icon
          icon="account-circle"
          class="mr-1 mt-2 va-sub"
          size="is-large"
        ></b-icon>
        <span class="va-super">
          {{ nombreUsuario }}
        </span>
      </p>
      <b-menu class="is-custom-mobile">
        <b-menu-list label="">
          <b-menu-item
            icon-pack="fas"
            icon="envelope"
            tag="router-link"
            to="/avisos"
          >
            <template #label>
              Mis avisos
              <span
                v-if="numeroAvisos > 0"
                class="is-pulled-right numero-avisos"
                >{{ numeroAvisos }}</span
              >
            </template>
          </b-menu-item>
          <b-menu-item
            icon-pack="fas"
            icon="tachometer-alt"
            label="Cuadro de Mando"
            tag="router-link"
            to="/dashboard"
          ></b-menu-item>
          <b-menu-item
            icon-pack="fas"
            icon="clipboard-check"
            label="Seguimiento"
            tag="router-link"
            to="/seguimientos"
          ></b-menu-item>
          <b-menu-item
            v-if="esAdmin"
            expanded
            icon-pack="fas"
            icon="cogs"
            label="Configurar Centros"
          >
            <b-menu-item
              v-if="esAdmin"
              icon-pack="fas"
              icon="wrench"
              label="Gestión Centros"
              tag="router-link"
              to="/gestion-centros"
            ></b-menu-item>
            <b-menu-item
              v-if="esAdmin"
              icon-pack="fas"
              icon="building"
              label="Copiar Centros"
              tag="router-link"
              to="/centros"
            ></b-menu-item>
            <b-menu-item
              v-if="esAdmin"
              icon-pack="fas"
              icon="globe"
              label="Paises"
              tag="router-link"
              to="/paises"
            ></b-menu-item>
            <b-menu-item
              v-if="esAdmin"
              icon-pack="fas"
              icon="sitemap"
              label="Sedes"
              tag="router-link"
              to="/sedes"
            ></b-menu-item>
            <b-menu-item
              v-if="esAdmin"
              icon-pack="fas"
              icon="server"
              label="Entornos"
              tag="router-link"
              to="/entornos"
            ></b-menu-item>
            <b-menu-item
              v-if="esAdmin"
              icon-pack="fas"
              icon="sliders-h"
              label="Significancia"
              tag="router-link"
              to="/significancias"
            ></b-menu-item>
          </b-menu-item>
          <b-menu-item
            v-if="esAdmin"
            icon-pack="fas"
            icon="user-cog"
            label="Usuarios"
            tag="router-link"
            to="/usuarios"
          ></b-menu-item>
        </b-menu-list>
        <b-menu-list label="">
          <b-menu-item
            icon-pack="fas"
            icon="user"
            label="Mi cuenta"
            tag="router-link"
            to="/mi-cuenta"
          >
          </b-menu-item>
          <b-menu-item
            v-if="haySesionActiva"
            icon-pack="fas"
            icon="sign-out-alt"
            label="Cerrar Sesión"
            @click="logout"
            title="Cerrar Sesión"
          ></b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
  </b-sidebar>
</template>

<script>
import { sessionStore } from "@/store";
import { haySesion, esAdmin } from "@/services/session";
export default {
  name: "sideBar",
  props: {
    numeroAvisos: Number,
  },
  computed: {
    haySesionActiva() {
      return haySesion();
    },
    esAdmin() {
      return esAdmin();
    },
    nombreUsuario() {
      return sessionStore.nombre;
    },
  },
  methods: {
    async logout() {
      this.$buefy.dialog.confirm({
        title: "Cerrar sesión",
        message: "¿Desea cerrar la sesión?",
        confirmText: "Salir",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$buefy.toast.open("Sesión cerrada.");
          this.$emit("logout");
        },
      });
    },
  },
  data() {
    return {
      expandOnHover: false,
      expandWithDelay: false,
      mobile: "reduce",
      reduce: false,
    };
  },
};
</script>
