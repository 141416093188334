<template>
  <b-table
    :data="isEmpty ? [] : data"
    :bordered="isBordered"
    :striped="isStriped"
    :narrowed="isNarrowed"
    :hoverable="isHoverable"
    :loading="isLoading"
    :focusable="isFocusable"
    :mobile-cards="hasMobileCards"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="isPaginationSimple"
    :pagination-position="paginationPosition"
    :default-sort-direction="defaultSortDirection"
    :pagination-rounded="isPaginationRounded"
    :sort-icon="sortIcon"
    :sort-icon-size="sortIconSize"
    default-sort="id"
    aria-next-label="Siguiente"
    aria-previous-label="Anterior"
    aria-page-label="Página"
    aria-current-label="Actual"
    :page-input="hasInput"
    :pagination-order="paginationOrder"
    :page-input-position="inputPosition"
    :debounce-page-input="inputDebounce"
  >
    <b-table-column
      field="id"
      label="Num."
      width="40"
      numeric
      v-slot="props"
      sortable
    >
      {{ props.row.id }}
    </b-table-column>

    <b-table-column
      field="nombre"
      label="Nombre"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.nombre }}
    </b-table-column>

    <b-table-column
      field="apellidos"
      label="Apellidos"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.apellidos }}
    </b-table-column>

    <b-table-column
      field="sede"
      label="Sede"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.sede }}
    </b-table-column>

    <b-table-column
      field="email"
      label="Email"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.email }}
    </b-table-column>

    <b-table-column
      field="fechaAcceso"
      label="Último Acceso"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ fechaFormateada(props.row.fechaAcceso) }}
    </b-table-column>

    <b-table-column
      label="Rol"
      v-slot="props"
      field="rol"
      sortable
      :searchable="mostrarFiltros"
      :custom-search="filtrarRol"
    >
      <span>
        {{ nombreRol(props.row.rol) }}
      </span>
    </b-table-column>

    <b-table-column
      label="Estado"
      v-slot="props"
      field="bloqueado"
      sortable
      :searchable="mostrarFiltros"
      :custom-search="filtrarEstado"
    >
      <span>
        {{ nombreEstado(props.row.bloqueado) }}
      </span>
    </b-table-column>

    <b-table-column label="Acciones" v-slot="props" width="180">
      <b-button
        type="is-primary"
        icon-left="edit"
        icon-pack="far"
        outlined
        tag="router-link"
        :to="`/usuarios/editar/${props.row.id}`"
        >Editar</b-button
      >
      <b-button
        type="is-danger"
        icon-left="trash-alt"
        icon-pack="fas"
        @click="eliminarUsuario(props.row.id)"
        class="ml-1"
      ></b-button>
    </b-table-column>
    <template #empty>
      <div class="has-text-centered">No hay resultados</div>
    </template>
  </b-table>
</template>
<script>
import Roles from "@/enums/Roles";
import EstadosUsuarios from "@/enums/EstadosUsuarios";
import { formatDateTabla } from "@/services/date";
export default {
  props: {
    resultado: {
      usuarios: [],
      resultadosPorPagina: 10,
      pagina: 1,
    },
    mostrarFiltros: Boolean,
  },
  computed: {
    data() {
      return this.resultado.usuarios;
    },
    currentPage: {
      get() {
        return this.paginaActual;
      },
      set(value) {
        this.paginaActual = value;
      },
    },
    perPage() {
      return this.resultado.resultadosPorPagina;
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      paginaActual: null,
    };
  },
  methods: {
    editarUsuario(usuarioId) {
      this.$router.push("/usuarios/editar/" + usuarioId);
    },
    eliminarUsuario(usuarioId) {
      this.$emit("eliminarUsuario", usuarioId);
    },
    nombreRol(rol) {
      return Roles.nombres[rol];
    },
    nombreEstado(estado) {
      return EstadosUsuarios.nombres[estado];
    },
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    filtrarRol(row, input) {
      var rol = this.nombreRol(row.rol).toLowerCase();
      return input && row.rol && rol.includes(input.toLowerCase());
    },
    filtrarEstado(row, input) {
      var estado = this.nombreEstado(row.bloqueado).toLowerCase();
      return input && estado.includes(input.toLowerCase());
    },
  },
  mounted() {
    this.paginaActual = this.resultado.paginaActual;
  },
};
</script>
