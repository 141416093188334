<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/usuarios"
            >Usuarios</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" to="/usuarios/editar" active
            >Editar</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
        <div class="divider is-primary is-right mt-2"></div>
      <formulario-usuario :id="id"></formulario-usuario>
    </div>
  </app-layout>
</template>

<script>
import FormularioUsuario from "./Formulario.vue";

export default {
  components: { FormularioUsuario },
  name: "EditarUsuario",
  data() {
    return {
      id: this.$route.params.id != undefined ? parseInt(this.$route.params.id) : 0,
    };
  },
};
</script>
