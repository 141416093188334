import { backend } from "./backend";

function listarTipos(params) {
  return backend.get("clienteEntorno&entornoId=" + params.entornoOrigen + "&centroId=" + params.centroOrigen.id + "&path=entidades/listarTipos").then((response) => {
    for (let i = 0; i < response.tiposEntidad.length; i++) {
      response.tiposEntidad[i].accion = "anadir";
    }
    return response.tiposEntidad;
  });
}

function exportar(entornoOrigen, centroOrigen, importacion, uuid) {
  return backend.post("entidades/exportar", {
    entornoOrigen: entornoOrigen,
    centroOrigen: centroOrigen,
    importacion: importacion,
    uuid: uuid,
  });
}

function importar(entornoDestino, centroDestino, exportacion, uuid) {
  return backend.post("entidades/importar", {
    entornoDestino: entornoDestino,
    centroDestino: centroDestino,
    exportacion: exportacion,
    uuid: uuid,
  });
}

export default {
  listarTipos,
  exportar,
  importar,
};
