<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/sedes"
            >Sedes</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" to="/sedes/editar" active
            >Editar</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
        <div class="divider is-primary is-right mt-2"></div>
      <formulario-sede :id="id"></formulario-sede>
    </div>
  </app-layout>
</template>

<script>
import FormularioSede from "./Formulario.vue";

export default {
  components: { FormularioSede },
  name: "EditarSede",
  data() {
    return {
      id: parseInt(this.$route.params.id),
    };
  },
};
</script>
