import { backend } from "./backend";
import { formatDatetime } from "@/services/date";

function obtenerDatosTabla(fechas, entorno) {
  var fechaDesde = formatDatetime(fechas[0]);
  var fechaHasta = formatDatetime(fechas[1]);

  return backend
    .get(
      "seguimientos/obtenerDatosTabla&fecha_desde=" +
        fechaDesde +
        "&fecha_hasta=" +
        fechaHasta +
        "&entornoId=" +
        entorno
    )
    .then((response) => {
      return response;
    });
}

function exportarTabla(fechas, entorno) {
  var fechaDesde = formatDatetime(fechas[0]);
  var fechaHasta = formatDatetime(fechas[1]);

  return backend
    .download(
      "seguimientos/exportar&fecha_desde=" +
        fechaDesde +
        "&fecha_hasta=" +
        fechaHasta +
        "&entornoId=" +
        entorno,
      [],
      "datosCentros.csv"
    )
    .then((response) => {
      return response;
    });
}

function obtenerDatosTablaComparativa(mesOrigen, mesComparar, entorno) {
  var fechaDesde = formatDatetime(mesOrigen);
  var fechaHasta = formatDatetime(mesComparar);

  return backend
    .get(
      "seguimientos/obtenerDatosTablaComparativa&fecha_desde=" +
        fechaDesde +
        "&fecha_hasta=" +
        fechaHasta +
        "&entornoId=" +
        entorno
    )
    .then((response) => {
      return response;
    });
}

function obtenerColumnasTabla() {
  if (localStorage.columnasTablaSeguimiento)
    return JSON.parse(localStorage.columnasTablaSeguimiento);
  var columnas = [
    {
      campo: "nombre",
      titulo: "Centro",
      visible: true,
      tipoFecha: false,
      centrada: false,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "descripcion",
      titulo: "Descripción",
      visible: true,
      tipoFecha: false,
      centrada: false,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "responsable",
      titulo: "Responsable",
      visible: true,
      tipoFecha: false,
      centrada: false,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "tipo",
      titulo: "Tipología",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "tipoCentro",
      titulo: "Tipo",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "pais",
      titulo: "País",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "diasDesdeCreacion",
      titulo: "Días desde creación",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "fechaAlta",
      titulo: "Fecha Alta",
      visible: true,
      tipoFecha: true,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numTotalAcceso",
      titulo: "Núm. Total de Accesos",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numUsuarios",
      titulo: "Núm. Usuarios",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "tasaAcceso",
      titulo: "% Acceso Usuario",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "promedioAccesos",
      titulo: "Promedio de accesos",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numExpedientes",
      titulo: "Núm. Expedientes Creados",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "numExpedientesTipo",
    },
    {
      campo: "numExpedientesTipo",
      titulo: "Núm. Expedientes Tipo",
      visible: false,
      tipoFecha: false,
      centrada: true,
      subTabla: true,
      columnaTabla: "",
    },
    {
      campo: "numTipoExpedientes",
      titulo: "Núm. Tipo Expedientes",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numFases",
      titulo: "Núm. Fases",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numTareasExp",
      titulo: "Núm. Tareas Exp.",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numTareasProy",
      titulo: "Núm. Tareas Proy.",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numEncuestasEjecuciones",
      titulo: "Núm. Encuestas Ejec.",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numEncuestasEjecutadasEncuestas",
      titulo: "Encuestas Ej. / Encuesta",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "numIndicadores",
      titulo: "Núm. Indicadores",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
    {
      campo: "estado",
      titulo: "Estado",
      visible: true,
      tipoFecha: false,
      centrada: true,
      subTabla: false,
      columnaTabla: "",
    },
  ];

  localStorage.columnasTablaSeguimiento = JSON.stringify(columnas);
  return columnas;
}

function obtenerColumnasComparativa() {
  if (localStorage.columnasTablaComparativa)
    return JSON.parse(localStorage.columnasTablaComparativa);
  var columnas = [
    {
      campo: "nombre",
      titulo: "Centro",
      visible: true,
      centrada: false,
      comparar: false,
    },
    {
      campo: "numTotalAcceso",
      titulo: "Núm. Total de Accesos",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numUsuarios",
      titulo: "Núm. Usuarios",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "tasaAcceso",
      titulo: "% Acceso Usuario",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "promedioAccesos",
      titulo: "Promedio de accesos",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numExpedientes",
      titulo: "Núm. Expedientes Creados",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numTipoExpedientes",
      titulo: "Núm. Tipo Expedientes",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numFases",
      titulo: "Núm. Fases",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numTareasExp",
      titulo: "Núm. Tareas Exp.",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numTareasProy",
      titulo: "Núm. Tareas Proy.",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numEncuestasEjecuciones",
      titulo: "Núm. Encuestas Ejec.",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numEncuestasEjecutadasEncuestas",
      titulo: "Encuestas Ej. / Encuesta",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "numIndicadores",
      titulo: "Núm. Indicadores",
      visible: true,
      centrada: true,
      comparar: true,
    },
    {
      campo: "estado",
      titulo: "Estado",
      visible: true,
      centrada: true,
      comparar: true,
    },
  ];

  localStorage.columnasTablaComparativa = JSON.stringify(columnas);
  return columnas;
}

export default {
  obtenerDatosTabla,
  obtenerDatosTablaComparativa,
  obtenerColumnasTabla,
  obtenerColumnasComparativa,
  exportarTabla,
};
