<template>
  <form @submit.prevent="guardarUsuario">
    <div class="columns is-desktop">
      <div class="column">
        <b-field label="Nombre">
          <b-input
            v-model="usuario.nombre"
            autofocus
            maxlength="50"
            icon="account"
            required
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Apellidos">
          <b-input
            v-model="usuario.apellidos"
            maxlength="50"
            icon="account"
            required
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column">
        <b-field label="Email">
          <b-input
            type="email"
            maxlength="50"
            icon="email"
            v-model="usuario.email"
            required
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Perfil" v-if="!esMiCuenta">
          <b-select
            placeholder="Perfil"
            icon="account-lock"
            v-model="usuario.rol"
            expanded
            required
          >
            <option v-for="rol in roles" :key="rol.value" :value="rol.value">
              {{ rol.text }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column">
        <div class="columns mb-0">
          <div class="column is-full">
            <b-field
              label="Contraseña"
              :type="tipoMensaje"
              :message="mensajeError"
            >
              <b-input
                type="password"
                maxlength="15"
                icon="lock"
                password-reveal
                v-model="usuario.claveNueva"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="usuario.claveNueva">
          <div class="column is-full">
            <fuerza-password
              :clave="usuario.claveNueva"
              :mostrarValor="true"
              @actualizarClave="actualizarClave"
            ></fuerza-password>
          </div>
        </div>
      </div>
      <div class="column">
        <b-field
          label="Repetir contraseña"
          :type="tipoMensaje"
          :message="mensajeError"
        >
          <b-input
            type="password"
            maxlength="15"
            icon="lock"
            password-reveal
            v-model="clave2"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop" v-if="!esMiCuenta">
      <div class="column">
        <b-field label="Sede">
          <b-select
            placeholder="Sede"
            icon="account-lock"
            v-model="usuario.sedeId"
            expanded
            required
          >
            <option v-for="sede in sedes" :key="sede.id" :value="sede.id">
              {{ sede.nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Estado">
          <b-switch v-model="usuario.bloqueado"> Bloqueado </b-switch>
        </b-field>
      </div>
    </div>
    <div v-if="!esMiCuenta">
      <div class="is-desktop is-vcentered mt-6">
        <b-collapse
          class="card"
          animation="slide"
          aria-id="desplegableResponsables"
          :open="false"
        >
          <template #trigger="props">
            <div
              class="card-header has-background-primary"
              role="button"
              aria-controls="desplegableResponsables"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">
                <span class="is-size-6 has-text-weight-bold has-text-white">
                  <b-icon
                    pack="fas"
                    icon="user-secret"
                    size="is-medium"
                  ></b-icon>
                  Responsable de centros</span
                >
              </p>
              <a class="card-header-icon has-text-white">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <b-tabs
            position="is-centered"
            class="block"
            @input="seleccionarEntornoResponsable"
          >
            <b-tab-item
              v-for="entorno in entornosResponsableRevisados"
              :key="entorno.id"
              :value="entorno.id.toString()"
              :label="nombreResponsableEntorno(entorno)"
            >
              <div class="column is-4 p-0">
                <label class="label">Centros</label>
                <multiselect
                  v-model="entorno.centrosResponsableSeleccionados"
                  :id="`entorno-responsable-${entorno.id}`"
                  label="nombre"
                  track-by="id"
                  placeholder="Buscar centro..."
                  selectLabel="Pulsa enter para seleccionar"
                  :preserve-search="true"
                  :options="centrosResponsable"
                  :multiple="true"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="2000"
                  :max-height="600"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="listarCentrosResponsable"
                  :limit="0"
                  :limit-text="limitText"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="selectTag"
                      ><span>{{ option.nombre }}</span
                      ><span class="ml-2" @click="remove(option)">
                        <i class="fas fa-times-circle"></i> </span></span
                  ></template>
                  <template
                    slot="afterList"
                    v-if="tienePaginaSiguienteResponsable"
                  >
                    <div v-observe-visibility="estaAlFinal" />
                    Cargando...
                  </template>
                  <span slot="noResult"
                    >No se ha encontrado ningún centro.</span
                  >
                </multiselect>
              </div>
              <div class="column">
                <div class="columns is-multiline mt-4">
                  <div
                    class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd pt-0 pb-0"
                    v-for="centro in entorno.centrosResponsableSeleccionados"
                    :key="centro.id"
                  >
                    <b-field>
                      <b-button
                        class="boton-centro-tabla"
                        type="is-text"
                        @click="quitarResponsableCentro(centro.id, entorno)"
                      >
                        <b-icon icon="delete" type="is-danger"> </b-icon>
                        <span>
                          {{ centro.nombre }}
                        </span>
                      </b-button>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </b-collapse>
      </div>
      <div class="is-desktop is-vcentered mt-6">
        <b-collapse
          class="card"
          animation="slide"
          aria-id="desplegablePrivilegios"
          :open="false"
        >
          <template #trigger="props">
            <div
              class="card-header has-background-danger"
              role="button"
              aria-controls="desplegablePrivilegios"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">
                <span class="is-size-6 has-text-weight-bold has-text-white">
                  <b-icon pack="fas" icon="key" size="is-medium"></b-icon>
                  Privilegios de acceso</span
                >
              </p>
              <a class="card-header-icon has-text-white">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <b-tabs
            position="is-centered"
            class="block"
            @input="seleccionarEntorno"
          >
            <b-tab-item
              v-for="entorno in entornosRevisados"
              :key="entorno.id"
              :value="entorno.id.toString()"
              :label="nombreEntorno(entorno)"
            >
              <div class="column is-4 p-0">
                <label class="label">Centros</label>
                <multiselect
                  v-model="entorno.centrosSeleccionados"
                  :id="`entorno-${entorno.id}`"
                  label="nombre"
                  track-by="id"
                  placeholder="Buscar centro..."
                  selectLabel="Pulsa enter para seleccionar"
                  :preserve-search="true"
                  :options="centros"
                  :multiple="true"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="2000"
                  :max-height="600"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="listarCentros"
                  :limit="0"
                  :limit-text="limitText"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="selectTag"
                      ><span>{{ option.nombre }}</span
                      ><span class="ml-2" @click="remove(option)">
                        <i class="fas fa-times-circle"></i> </span></span
                  ></template>
                  <template slot="afterList" v-if="tienePaginaSiguiente">
                    <div v-observe-visibility="estaAlFinal" />
                    Cargando...
                  </template>
                  <span slot="noResult"
                    >No se ha encontrado ningún centro.</span
                  >
                </multiselect>
              </div>
              <div class="column">
                <div class="columns is-multiline mt-4">
                  <div
                    class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd pt-0 pb-0"
                    v-for="centro in entorno.centrosSeleccionados"
                    :key="centro.id"
                  >
                    <b-field>
                      <b-button
                        class="boton-centro-tabla"
                        type="is-text"
                        @click="quitarCentro(centro.id, entorno)"
                      >
                        <b-icon icon="delete" type="is-danger"> </b-icon>
                        <span>
                          {{ centro.nombre }}
                        </span>
                      </b-button>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </b-collapse>
      </div>
      <div class="is-desktop is-vcentered mt-6">
        <b-collapse
          class="card"
          animation="slide"
          aria-id="desplegableEstadisticas"
          :open="false"
        >
          <template #trigger="props">
            <div
              class="card-header has-background-success"
              role="button"
              aria-controls="desplegableEstadisticas"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">
                <span class="is-size-6 has-text-weight-bold has-text-white">
                  <b-icon
                    pack="fas"
                    icon="chart-line"
                    size="is-medium"
                  ></b-icon>
                  Estadísticas por entornos</span
                >
              </p>
              <a class="card-header-icon has-text-white">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="columns is-multiline is-desktop mb-0 p-6">
            <div
              class="column is-3"
              v-for="entorno in entornosRevisados"
              :key="entorno.id"
              :value="entorno.id.toString()"
            >
              <b-switch v-model="entorno.seleccionado">
                {{ entorno.nombre }}
              </b-switch>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <b-button type="is-primary" native-type="submit" class="mt-5 is-right">
      Guardar
      <span class="icon ml-2">
        <i class="fas fa-save"></i>
      </span>
    </b-button>
  </form>
</template>
<script>
import apiUsuarios from "@/services/usuarios.js";
import apiSedes from "@/services/sedes.js";
import FuerzaPassword from "@/components/FuerzaPassword.vue";
import Roles from "@/enums/Roles";
import Multiselect from "vue-multiselect";
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";

export default {
  name: "FormularioUsuario",
  components: { FuerzaPassword, Multiselect },
  props: {
    id: Number,
    nuevo: Boolean,
  },
  computed: {
    roles() {
      return Roles.combo;
    },
    esMiCuenta() {
      let miCuenta = Boolean(this.id);
      let esNuevo = Boolean(this.nuevo);
      if (esNuevo) return false;
      return !miCuenta;
    },
  },
  data() {
    return {
      usuario: Object,
      sedes: Array,
      clave2: null,
      mensajeError: "",
      tipoMensaje: "",
      claveValida: false,
      centrosSeleccionados: [],
      centrosResponsableSeleccionados: [],
      centros: [],
      centrosResponsable: [],
      isLoading: false,
      entornoSeleccionadoId: 1,
      entornoAnteriorId: 1,
      entornoResponsableSeleccionadoId: 1,
      entornoResponsableAnteriorId: 1,
      pagina: 0,
      tienePaginaSiguiente: true,
      tienePaginaSiguienteResponsable: true,
      entornos: Array,
      entornosResponsable: Array,
      entornosRevisados: Array,
      entornosResponsableRevisados: Array,
    };
  },
  methods: {
    async cargar(id) {
      var resultado = await apiUsuarios.cargar(id);
      this.usuario = resultado.usuario;
      this.sedes = resultado.sedes;
      this.entornos = resultado.entornos;
      this.entornosResponsable = resultado.entornosResponsable;
      var resultadoEntornos = await apiEntornos.listarActivos();
      await this.comprobarEntornos(resultadoEntornos);
      var resultadoEntornosResponsables = await apiEntornos.listarActivos();
      await this.comprobarEntornosResponsables(resultadoEntornosResponsables);
      this.marcarEntornosEstadisticas(resultado.entornosUsuarioEstadisticas);
      if (this.entornosRevisados.length > 0) {
        this.seleccionarEntorno(this.entornosRevisados[0].id);
      }
      if (this.entornosResponsableRevisados.length > 0) {
        this.seleccionarEntornoResponsable(
          this.entornosResponsableRevisados[0].id
        );
      }
    },
    guardarUsuario() {
      if (!this.comprobarClave()) return;
      apiUsuarios
        .actualizar(
          this.usuario,
          this.entornosRevisados,
          this.entornosResponsableRevisados,
          this.esMiCuenta
        )
        .then((resultado) => {
          var msg = "Usuario actualizado.";
          var tipo = "is-success";
          if (!this.usuario.id) msg = "Usuario creado";
          if (resultado.error !== undefined) {
            msg = "Se ha producido un error: <br>" + resultado.error;
            tipo = "is-danger";
            this.mostrarMensaje(msg, tipo);
            return;
          }
          this.mostrarMensaje(msg, tipo);
          if (!this.esMiCuenta) this.$router.push({ path: "/usuarios" });
        });
    },
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
    comprobarClave() {
      this.mensajeError = "";
      this.tipoMensaje = "";
      if (
        this.usuario.claveNueva != undefined &&
        this.usuario.claveNueva != "" &&
        this.usuario.claveNueva != this.clave2
      ) {
        this.mensajeError = "Las contraseñas no coinciden.";
        this.tipoMensaje = "is-danger";
        return false;
      }
      if (
        this.usuario.claveNueva != undefined &&
        this.usuario.claveNueva != "" &&
        this.usuario.claveNueva == this.clave2 &&
        !this.claveValida
      ) {
        this.mensajeError = "Las contraseña es demasiado débil.";
        this.tipoMensaje = "is-danger";
        return false;
      }
      if (
        !this.usuario.id &&
        (this.usuario.claveNueva == undefined || this.usuario.claveNueva == "")
      ) {
        this.mensajeError = "Debe introducir una contraseña.";
        this.tipoMensaje = "is-danger";
        return false;
      }
      return true;
    },
    actualizarClave(esValida) {
      this.claveValida = esValida;
    },
    async listarCentros(term) {
      if (term != undefined && term == "") {
        this.centros = [];
      } else if (term == undefined) {
        term = "";
      }
      var params = {
        entornoId: this.entornoSeleccionadoId,
        path: "centros/listar",
        limite: 50,
        pagina: this.pagina,
        term: term,
      };
      if (term != "") {
        params = {
          entornoId: this.entornoSeleccionadoId,
          path: "centros/listar",
          limite: 0,
          pagina: 0,
          term: term,
        };
      }

      return apiCentros.listar(params).then((respuesta) => {
        if (!respuesta.centros.length) {
          this.tienePaginaSiguiente = false;
        }
        if (
          term != "" ||
          this.entornoSeleccionadoId != this.entornoAnteriorId
        ) {
          this.centros = respuesta.centros;
          this.tienePaginaSiguiente = false;
          return;
        }
        this.tienePaginaSiguiente = true;
        this.centros = this.centros.concat(respuesta.centros);
      });
    },
    async listarCentrosResponsable(term) {
      if (term != undefined && term == "") {
        this.centrosResponsable = [];
      } else if (term == undefined) {
        term = "";
      }
      var params = {
        entornoId: this.entornoResponsableSeleccionadoId,
        path: "centros/listar",
        limite: 50,
        pagina: this.pagina,
        term: term,
      };
      if (term != "") {
        params = {
          entornoId: this.entornoResponsableSeleccionadoId,
          path: "centros/listar",
          limite: 0,
          pagina: 0,
          term: term,
        };
      }

      return apiCentros.listar(params).then((respuesta) => {
        if (!respuesta.centros.length) {
          this.tienePaginaSiguienteResponsable = false;
        }
        if (
          term != "" ||
          this.entornoResponsableSeleccionadoId !=
            this.entornoResponsableAnteriorId
        ) {
          this.centrosResponsable = respuesta.centros;
          this.tienePaginaSiguienteResponsable = false;
          return;
        }
        this.tienePaginaSiguienteResponsable = true;
        this.centrosResponsable = this.centrosResponsable.concat(
          respuesta.centros
        );
      });
    },
    async cargarCentrosUsuario(
      centros,
      entornoId = this.entornoSeleccionadoId
    ) {
      var params = {
        entornoId: entornoId,
        path: "centros/cargarConIdsPaises",
        centrosIds: centros,
      };
      return apiCentros.cargarConIds(params).then((respuesta) => {
        return respuesta.centros;
      });
    },
    async cargarCentrosUsuarioResponsable(
      centros,
      entornoId = this.entornoResponsableSeleccionadoId
    ) {
      var params = {
        entornoId: entornoId,
        path: "centros/cargarConIdsPaises",
        centrosIds: centros,
      };
      return apiCentros.cargarConIds(params).then((respuesta) => {
        return respuesta.centros;
      });
    },
    estaAlFinal(alfinal) {
      if (alfinal) {
        this.pagina++;
        this.listarCentros();
      }
    },
    async comprobarEntornos(entornos) {
      for (var i = 0; i < this.entornos.length; i++) {
        this.entornos[i].marcado = false;
        for (var j = 0; j < entornos.length; j++) {
          if (this.entornos[i].entornoId == entornos[j].id) {
            entornos[j].marcado = true;
            entornos[j].centros = this.entornos[i].centros;
            if (this.entornos[i].centros.length) {
              entornos[j].centrosSeleccionados =
                await this.cargarCentrosUsuario(
                  this.entornos[i].centros,
                  this.entornos[i].entornoId
                );
            }
          }
        }
      }
      this.entornosRevisados = entornos;
    },
    async comprobarEntornosResponsables(entornos) {
      for (var i = 0; i < this.entornosResponsable.length; i++) {
        this.entornosResponsable[i].marcado = false;
        for (var j = 0; j < entornos.length; j++) {
          if (this.entornosResponsable[i].entornoId == entornos[j].id) {
            entornos[j].marcado = true;
            entornos[j].centrosResponsable =
              this.entornosResponsable[i].centros;
            if (this.entornosResponsable[i].centros.length) {
              entornos[j].centrosResponsableSeleccionados =
                await this.cargarCentrosUsuarioResponsable(
                  this.entornosResponsable[i].centros,
                  this.entornosResponsable[i].entornoId
                );
            } else {
              entornos[j].centrosResponsableSeleccionados = [];
            }
          }
        }
      }
      this.entornosResponsableRevisados = entornos;
    },
    seleccionarCentros() {
      return;
    },
    quitarCentro(id, entorno) {
      if (
        Object.prototype.hasOwnProperty.call(entorno, "centrosSeleccionados")
      ) {
        var posicion = entorno.centrosSeleccionados.findIndex((object) => {
          return object.id === id;
        });
        entorno.centrosSeleccionados.splice(posicion, 1);
        return;
      }
    },
    quitarResponsableCentro(id, entorno) {
      if (
        Object.prototype.hasOwnProperty.call(
          entorno,
          "centrosResponsableSeleccionados"
        )
      ) {
        var posicion = entorno.centrosResponsableSeleccionados.findIndex(
          (object) => {
            return object.id === id;
          }
        );
        entorno.centrosResponsableSeleccionados.splice(posicion, 1);
        return;
      }
    },
    limitText() {
      return "";
    },
    nombreEntorno(entorno) {
      var numCentros = " (0)";
      if (
        Object.prototype.hasOwnProperty.call(entorno, "centrosSeleccionados")
      ) {
        numCentros = " (" + entorno.centrosSeleccionados.length + ")";
      }
      return entorno.nombre + numCentros;
    },
    nombreResponsableEntorno(entorno) {
      var numCentros = " (0)";
      if (
        Object.prototype.hasOwnProperty.call(
          entorno,
          "centrosResponsableSeleccionados"
        )
      ) {
        numCentros =
          " (" + entorno.centrosResponsableSeleccionados.length + ")";
      }
      return entorno.nombre + numCentros;
    },
    seleccionarEntorno(value) {
      var entornoAnterior = this.entornoSeleccionadoId;
      this.entornoSeleccionadoId = value;
      this.listarCentros();
      this.entornoAnteriorId = entornoAnterior;
    },
    seleccionarEntornoResponsable(value) {
      var entornoAnterior = this.entornoResponsableSeleccionadoId;
      this.entornoResponsableSeleccionadoId = value;
      this.listarCentrosResponsable();
      this.entornoResponsableAnteriorId = entornoAnterior;
    },
    marcarEntornosEstadisticas(entornos) {
      for (var entorno in entornos) {
        for (var entornoRevisado in this.entornosRevisados) {
          if (this.entornosRevisados[entornoRevisado].id == entornos[entorno]) {
            this.entornosRevisados[entornoRevisado].seleccionado = true;
          }
        }
      }
    },
  },
  async mounted() {
    if (this.id != undefined) {
      await this.cargar(this.id);
    } else {
      this.usuario = apiUsuarios.crear();
      this.entornos = await apiEntornos.listarActivos();
      this.entornosResponsable = await apiEntornos.listarActivos();
      var resultadoEntornos = await apiEntornos.listarActivos();
      await this.comprobarEntornos(resultadoEntornos);
      await this.comprobarEntornosResponsables(resultadoEntornos);
      this.seleccionarEntorno(this.entornosRevisados[0].id);
      this.seleccionarEntornoResponsable(
        this.entornosResponsableRevisados[0].id
      );
    }
    if (!this.esMiCuenta) {
      this.sedes = await apiSedes.autocompletar();
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
