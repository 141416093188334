<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/seguimientos" active
            >Seguimientos</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <div class="columns">
        <div class="p-5 column is-4">
          <b-field label="Selecciona un rango de fechas" id="rangoFechas">
            <b-datepicker
              placeholder="Pulsa para seleccionar..."
              v-model="fechas"
              range
              icon="calendar-today"
              @input="cambiarFecha"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="p-5 column is-4">
          <b-field label="Entorno">
            <b-select
              placeholder="Entorno"
              icon="server"
              v-model="entorno"
              @input="cargarDatosCompletos"
            >
              <option
                v-for="entorno in entornos"
                :key="entorno.id"
                :value="entorno.id"
              >
                {{ entorno.nombre }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="mt-5">
        <div class="level-left">
          <p class="title is-5 is-uppercase borde-puntos" :class="colorTabla">
            {{ tituloTabla }}
          </p>
        </div>
        <div class="level-right">
          <b-switch v-model="seleccionarColumnas"
            >Seleccionar Columnas</b-switch
          >
          <b-switch v-model="mostrarFiltros" class="ml-4"
            >Mostrar filtros</b-switch
          >
          <b-button type="is-primary" class="ml-4" @click="exportarTabla">
            <span class="icon mr-2">
              <i class="far fa-arrow-alt-circle-down"></i>
            </span>
            Exportar
          </b-button>
        </div>
        <tabla-seguimiento
          :datos="datosTabla"
          :mostrarFiltros="mostrarFiltros"
          :seleccionarColumnas="seleccionarColumnas"
          :ordenColumna="ordenColumna"
        ></tabla-seguimiento>
      </div>
      <div class="columns">
        <div class="p-5 column is-3">
          <b-field label="Mes referencia" id="mesReferencia">
            <b-datepicker
              placeholder="Pulsa para seleccionar..."
              v-model="mesReferencia"
              type="month"
              icon="calendar-today"
              @input="cambiarFechaComparativa"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="p-5 column is-3">
          <b-field label="Mes a comparar" id="mesComparar">
            <b-datepicker
              placeholder="Pulsa para seleccionar..."
              v-model="mesComparar"
              type="month"
              icon="calendar-today"
              @input="cambiarFechaComparativa"
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>
      <div class="mt-5">
        <div class="level-left">
          <p class="title is-5 is-uppercase borde-puntos" :class="colorTabla">
            {{ tituloTablaComparativa }}
          </p>
        </div>
        <div class="level-right">
          <b-switch v-model="seleccionarColumnasComparativa"
            >Seleccionar Columnas</b-switch
          >
          <b-switch v-model="mostrarFiltrosComparativa" class="ml-4"
            >Mostrar filtros</b-switch
          >
          <b-button type="is-primary" class="ml-4">
            <span class="icon mr-2">
              <i class="far fa-arrow-alt-circle-down"></i>
            </span>
            Exportar
          </b-button>
        </div>
        <tabla-comparativa
          :datos="datosTablaComparativa"
          :mostrarFiltros="mostrarFiltrosComparativa"
          :seleccionarColumnas="seleccionarColumnasComparativa"
          :ordenColumna="ordenColumnaComparativa"
        ></tabla-comparativa>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { esLector } from "@/services/session";
import TablaSeguimiento from "./TablaSeguimiento.vue";
import apiSeguimiento from "@/services/seguimientos";
import apiEntornos from "@/services/entornos.js";
import TablaComparativa from "./TablaComparativa.vue";

export default {
  components: {
    TablaSeguimiento,
    TablaComparativa,
  },
  name: "SeguimientoPage",
  computed: {
    esLector,
  },
  data() {
    return {
      datosTabla: [],
      datosTablaComparativa: [],
      datosUsuarios: [],
      datosAccesos: [],
      datosTasa: [],
      datosTasaMes: [],
      mostrarFiltros: false,
      mostrarFiltrosComparativa: false,
      seleccionarColumnas: false,
      seleccionarColumnasComparativa: false,
      tituloTabla: "Estadísticas de uso",
      tituloTablaComparativa: "Comparativa periodos",
      tituloGrafico: "",
      tituloGraficoMes: "",
      colorTabla: "",
      colorGrafico: "",
      colorGraficoMes: "",
      mostrarTabla: false,
      mostrarGrafico: false,
      tablaActual: "",
      graficoActual: "",
      tituloGraficoBarras: "",
      tituloGraficoBarrasMes: "",
      fechas: [this.subtractMonths(1, new Date()), new Date()],
      centrosMas: [],
      centrosMenos: [],
      labelsCentros: [],
      labelsMeses: [],
      labelsCentrosMas: [],
      labelsCentrosMenos: [],
      ordenColumna: ["tasaAcceso", "desc"],
      ordenColumnaComparativa: ["tasaAcceso", "desc"],
      ordenMeses: {},
      ordenMesesMas: {},
      ordenMesesMenos: {},
      recargar: false,
      mostrarMas: true,
      mesReferencia: this.subtractMonths(1, new Date()),
      mesComparar: new Date(),
      entornos: [],
      entorno: 0,
    };
  },
  methods: {
    async mostrarTablaMas() {
      if (!this.recargar) {
        this.mostrarTabla = false;
      }
      this.tituloTabla = "Centros con más accesos";
      this.colorTabla = "has-text-success-dark";
      this.datosTabla = this.ordenarTablaDesc(this.datosTabla);
      this.ordenColumna = ["tasaAcceso", "desc"];
      if (this.tablaActual == "mas" && !this.recargar) {
        this.mostrarTabla = false;
        this.tablaActual = "";
      } else {
        this.mostrarTabla = true;
        this.tablaActual = "mas";
      }
    },
    subtractMonths(numOfMonths, date = new Date()) {
      date.setMonth(date.getMonth() - numOfMonths);

      return date;
    },
    async cambiarFecha() {
      if (this.mostrarGrafico) this.mostrarGrafico = !this.mostrarGrafico;
      this.recargar = true;
      await this.cargarDatos();
      if (this.graficoActual == "mas") {
        await this.mostrarGraficoMas();
      } else if (this.graficoActual == "menos") {
        await this.mostrarGraficoMenos();
      }
      this.recargar = false;
    },
    async cambiarFechaComparativa() {
      this.recargar = true;
      await this.cargarDatosComparativa();
      this.recargar = false;
    },
    async cargarDatos() {
      apiSeguimiento.obtenerDatosTabla(this.fechas, this.entorno).then((resultado) => {
        this.datosTabla = resultado.datosTabla;
        if (this.tablaActual == "mas") {
          this.datosTabla = this.ordenarTablaDesc(this.datosTabla);
          this.ordenColumna = ["tasaAcceso", "desc"];
        } else if (this.tablaActual == "menos") {
          this.datosTabla = this.ordenarTablaAsc(this.datosTabla);
          this.ordenColumna = ["tasaAcceso", "asc"];
        }
      });
    },
    async cargarDatosComparativa() {
      apiSeguimiento
        .obtenerDatosTablaComparativa(this.mesReferencia, this.mesComparar, this.entorno)
        .then((resultado) => {
          this.datosTablaComparativa = resultado.datosTabla;
        });
    },
    ordenarTablaAsc(tabla) {
      return tabla.sort(function (e1, e2) {
        if (e1.tasaAcceso > e2.tasaAcceso) {
          return 1;
        }
        if (e1.tasaAcceso < e2.tasaAcceso) {
          return -1;
        }
        return 0;
      });
    },
    ordenarTablaDesc(tabla) {
      return tabla.sort(function (e1, e2) {
        if (e2.tasaAcceso > e1.tasaAcceso) {
          return 1;
        }
        if (e2.tasaAcceso < e1.tasaAcceso) {
          return -1;
        }
        return 0;
      });
    },
    async exportarTabla() {
      await apiSeguimiento.exportarTabla(this.fechas, this.entorno);
    },
    async cargarEntornos() {
      this.entornos = await apiEntornos.listarActivosEstadisticas();
    },
    cargarDatosCompletos() {
      this.cargarDatos();
      this.cargarDatosComparativa();
    },
  },
  async mounted() {
    await this.cargarEntornos();
    this.cargarDatosCompletos();
  },
  watch: {},
};
</script>

<style>
#rangoFechas .datepicker .dropdown-content {
  width: fit-content;
}

.borde-puntos {
  border-bottom: 1px dotted;
  padding: 0 8px 8px 4px;
}
</style>
