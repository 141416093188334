<template>
  <b-table
    :data="isEmpty ? [] : resultado.historicos"
    :bordered="isBordered"
    :striped="isStriped"
    :narrowed="isNarrowed"
    :hoverable="isHoverable"
    :loading="isLoading"
    :focusable="isFocusable"
    :mobile-cards="hasMobileCards"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="isPaginationSimple"
    :pagination-position="paginationPosition"
    :default-sort-direction="defaultSortDirection"
    :pagination-rounded="isPaginationRounded"
    :sort-icon="sortIcon"
    :sort-icon-size="sortIconSize"
    default-sort="fechaImportacion"
    aria-next-label="Siguiente"
    aria-previous-label="Anterior"
    aria-page-label="Página"
    aria-current-label="Actual"
    :page-input="hasInput"
    :pagination-order="paginationOrder"
    :page-input-position="inputPosition"
    :debounce-page-input="inputDebounce"
    detailed
    detail-key="id"
    detail-transition="fade"
    :show-detail-icon="showDetailIcon"
  >
    <b-table-column
      field="id"
      label="Num."
      width="40"
      numeric
      v-slot="props"
      sortable
    >
      {{ props.row.id }}
    </b-table-column>

    <b-table-column
      field="fechaImportacion"
      label="Fecha de Importacion"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ fechaFormateada(props.row.fechaImportacion) }}
    </b-table-column>

    <b-table-column
      field="usuario"
      label="Usuario"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.usuario }}
    </b-table-column>

    <b-table-column
      field="centroOrigen"
      label="Centro de Referencia"
      v-slot="props"
      sortable
      :searchable="mostrarFiltros"
    >
      {{ props.row.centroOrigen }}
    </b-table-column>

    <b-table-column label="Elementos Importados" centered v-slot="props">
      <template>
        <a @click="props.toggleDetails(props.row)">
          <b-icon icon="arrow-down-box"></b-icon>
        </a>
      </template>
    </b-table-column>
    <template #detail="props">
      <div class="columns is-desktop">
        <div class="column is-offset-8">
          <b-table
            :data="isEmpty ? [] : props.row.importacion"
            :bordered="isBordered"
            :striped="isStriped"
            :narrowed="isNarrowed"
            :hoverable="isHoverable"
            :loading="isLoading"
            :focusable="isFocusable"
            :mobile-cards="hasMobileCards"
            :paginated="false"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            default-sort="nombre"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Actual"
            :page-input="hasInput"
            :pagination-order="paginationOrder"
            :page-input-position="inputPosition"
            :debounce-page-input="inputDebounce"
            detailed
            detail-key="nombre"
            detail-transition="fade"
            :show-detail-icon="showDetailIcon"
          >
            <b-table-column
              field="nombre"
              label="Nombre"
              v-slot="propsFila"
              header-class="headerAcciones"
            >
              {{ propsFila.row.nombre }}
            </b-table-column>

            <b-table-column
              v-if="esMapaProcesos(props.row.aplicacion)"
              field="datos"
              label="Con datos"
              v-slot="propsFila"
              header-class="headerAcciones"
            >
              <div class="has-text-centered" v-html="obtenerIconoDatos(propsFila.row.datos)"></div>
            </b-table-column>

            <b-table-column
              v-if="esBPM(props.row.aplicacion)"
              field="tipoCopia"
              label="Copia"
              v-slot="propsFila"
              header-class="headerAcciones"
            >
              {{ propsFila.row.tipoCopia }}
            </b-table-column>

            <b-table-column
              v-if="esBPM(props.row.aplicacion)"
              field="tipoParcial"
              label="Parcial"
              v-slot="propsFila"
              header-class="headerAcciones"
            >
              <span v-if="propsFila.row.tipoCopia == 'Parcial'">{{ propsFila.row.tipoParcial }}</span>
            </b-table-column>

            <b-table-column
              field="accion"
              label="Acción"
              v-slot="propsFila"
              header-class="headerAcciones"
            >
              {{ propsFila.row.accion }}
            </b-table-column>

            <b-table-column
              field="resultado"
              label="Resultado"
              v-slot="propsFila"
              header-class="headerAcciones"
            >
              <div class="has-text-centered" v-html="obtenerIcono(propsFila.row.resultado)"></div>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>

    <template #empty>
      <div class="has-text-centered">No hay resultados</div>
    </template>
  </b-table>
</template>
<script>
import { formatDateTabla } from "@/services/date";

export default {
  props: {
    resultado: {
      historicos: [],
      resultadosPorPagina: 10,
      pagina: 1,
    },
    mostrarFiltros: Boolean,
  },
  computed: {
    currentPage: {
      get() {
        return this.paginaActual;
      },
      set(value) {
        this.paginaActual = value;
      },
    },
    perPage() {
      return this.resultado.resultadosPorPagina;
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "desc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      paginaActual: null,
      showDetailIcon: false,
    };
  },
  methods: {
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    obtenerIcono(resultado) {
      if (resultado == "ok") return '<i class="fas fa-check has-text-success is-size-5"></i>';
      return '<i class="fas fa-exclamation-triangle has-text-danger is-size-5"></i>';
    },
    obtenerIconoDatos(resultado) {
      if (resultado) return '<i class="fas fa-check has-text-success is-size-5"></i>';
      return '<i class="fas fa-times has-text-danger is-size-5"></i>';
    },
    esMapaProcesos(aplicacion) {
      return aplicacion == "mapasProcesos";
    },
    esBPM(aplicacion) {
      return aplicacion == "bpm";
    },
  },
  async mounted() {
    this.paginaActual = this.resultado.paginaActual;
  },
};
</script>

<style>
.dropdown.dropdown-menu-animation.is-bottom-left.is-active.is-mobile-modal
  .button.is-primary {
  background-color: #888;
}
th.headerAcciones {
  background-color: #d9d9d9;
}
</style>
