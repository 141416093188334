export const ACTIVO = 1;
export const BLOQUEADO = 0;

export const nombres = Object.freeze({
  [ACTIVO]: "Activa",
  [BLOQUEADO]: "Bloqueada",
});

export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});

export default { ACTIVO, BLOQUEADO, nombres, combo };
