<template>
  <app-layout :numeroAvisos="numeroAvisos">
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/avisos" active
            >Mis avisos</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <div class="level-right">
        <b-switch v-model="mostrarFiltros">Mostrar filtros</b-switch>
      </div>
      <tabla
        :resultado="resultado"
        @leerAviso="leerAviso"
        :mostrarFiltros="mostrarFiltros"
      ></tabla>
    </div>
    <b-modal
      v-model="mostrarModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Aviso"
      close-button-aria-label="Cerrar"
      aria-modal
      :width="900"
    >
      <div class="card" style="width: 900px">
        <div class="card-content">
          <div class="media">
            <div class="media-content columns">
              <div class="column is-6">
                <p class="title is-4">{{ aviso.centro }}</p>
              </div>
              <div class="column is-6">
                <p class="subtitle is-6 is-pulled-right">
                  {{ aviso.pais }} - {{ aviso.responsable }}
                </p>
              </div>
            </div>
          </div>
          <div class="content">
            <tabla-significancias
              :resultado="crearDatosModal(aviso.campos)"
            ></tabla-significancias>
            <br />
            <small>{{ fechaFormateada(aviso.fecha) }}</small>
          </div>
        </div>
      </div>
    </b-modal>
  </app-layout>
</template>

<script>
import { esLector } from "@/services/session";
import { sessionStore } from "@/store";
import api from "@/services/significancia.js";
import Tabla from "./TablaAvisos.vue";
import TablaSignificancias from "./TablaSignificancias.vue";
import { formatDateTabla } from "@/services/date";
import { recuperar } from "@/services/login";

export default {
  components: { Tabla, TablaSignificancias },
  name: "TablaAvisos",
  computed: {
    esLector,
  },
  data() {
    return {
      resultado: {
        avisos: [],
        resultadosPorPagina: 10,
        pagina: 1,
      },
      mostrarFiltros: false,
      mostrarModal: false,
      aviso: {},
      numeroAvisos: sessionStore.avisos,
    };
  },
  methods: {
    listar() {
      return api.listarAvisos(this.$route.query).then((respuesta) => {
        this.resultado.avisos = respuesta;
      });
    },
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
    async leerAviso(aviso) {
      this.aviso = aviso;
      this.mostrarModal = true;
      if (aviso.estado == 0) {
        await api.leerAviso(aviso.id);
        this.listar();
        await recuperar();
        this.numeroAvisos = sessionStore.avisos;
      }
    },
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    crearDatosModal(campos) {
      return {
        avisos: campos,
        resultadosPorPagina: 10,
        pagina: 1,
      };
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
