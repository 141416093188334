<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/centros"
            >Configurador de Centros</b-breadcrumb-item
          >
          <b-breadcrumb-item
            >{{ centro.nombre }}</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" :to="`/centros/personas/${id}/${entornoId}`" active
            >Importar configuración de Personas</b-breadcrumb-item
          >
        </b-breadcrumb>
        <div class="level-right mr-2">
          <b-button type="is-primary" tag="router-link" :to="`/centros/historico/personas/${id}/${entornoId}`">
            <span class="icon mr-2 alinearBot">
              <b-icon icon="history"></b-icon>
            </span>
            Histórico
          </b-button>
        </div>
      </div>
        <div class="divider is-primary is-right mt-2"></div>
      <formulario-personas :id="id" :entornoId="entornoId" :centroDestinoNombre="centro.nombre"></formulario-personas>
    </div>
  </app-layout>
</template>

<script>
import FormularioPersonas from "./Formulario.vue";
import apiCentros from "@/services/centros.js";

export default {
  components: { FormularioPersonas },
  name: "ImportarPersonas",
  data() {
    return {
      id: parseInt(this.$route.params.id),
      centro: Object,
      entornoId: parseInt(this.$route.params.entorno),
    };
  },
  methods: {
    cargar(id) {
      var params = {
        entornoId: this.entornoId,
        path: "centros/cargarConIds",
        centrosIds: [id],
      };
      return apiCentros.cargarConIds(params).then((respuesta) => {
        this.centro = respuesta.centros[0];
      });
    },
  },
  mounted() {
    this.cargar(this.id);
  },
};
</script>
