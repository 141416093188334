<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/centros"
            >Configurador de Centros</b-breadcrumb-item
          >
          <b-breadcrumb-item
            >{{ centro.nombre }}</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" :to="`/centros/contratistas/${id}/${entornoId}`" active
            >Importar configuración de Contratistas</b-breadcrumb-item
          >
        </b-breadcrumb>
        <div class="level-right mr-2">
          <b-button type="is-primary" tag="router-link" :to="`/centros/historico/contratistas/${id}/${entornoId}`">
            <span class="icon mr-2 alinearBot">
              <b-icon icon="history"></b-icon>
            </span>
            Histórico
          </b-button>
        </div>
      </div>
        <div class="divider is-primary is-right mt-2"></div>
      <formulario-contratistas :id="id" :entornoId="entornoId" :centroDestino="centro.nombre" :entornoDestino="entorno.nombre"></formulario-contratistas>
    </div>
  </app-layout>
</template>

<script>
import FormularioContratistas from "./Formulario.vue";
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";

export default {
  components: { FormularioContratistas },
  name: "ImportarContratistas",
  data() {
    return {
      id: parseInt(this.$route.params.id),
      centro: Object,
      entornoId: parseInt(this.$route.params.entorno),
      entorno: Object,
    };
  },
  methods: {
    cargar(id) {
      var params = {
        entornoId: this.entornoId,
        path: "centros/cargarConIds",
        centrosIds: [id],
      };
      return apiCentros.cargarConIds(params).then((respuesta) => {
        this.centro = respuesta.centros[0];
      });
    },
    cargarEntorno(id) {
      return apiEntornos.cargar(id).then((respuesta) => {
        this.entorno = respuesta.entorno;
      });
    },
  },
  mounted() {
    this.cargar(this.id);
    this.cargarEntorno(this.entornoId);
  },
};
</script>
