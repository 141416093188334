export const ACTIVO = 0;
export const BLOQUEADO = 1;
export const IMPLEMENTACION = 2;

export const nombres = Object.freeze({
  [ACTIVO]: "Activo",
  [BLOQUEADO]: "Bloqueado",
  [IMPLEMENTACION]: "Implementación",
});

export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});

export default { ACTIVO, BLOQUEADO, IMPLEMENTACION, nombres, combo };
