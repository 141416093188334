<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/entornos"
            >Entornos</b-breadcrumb-item
          >
          <b-breadcrumb-item tag="router-link" to="/entornos/crear" active
            >Crear</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <formulario-entorno></formulario-entorno>
    </div>
  </app-layout>
</template>

<script>
import FormularioEntorno from "./Formulario.vue";

export default {
  components: { FormularioEntorno },
  name: "EditarEntorno",
  data() {
    return {};
  },
};
</script>
