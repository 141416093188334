<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/dashboard" active
            >Dashboard</b-breadcrumb-item
          >
        </b-breadcrumb>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <div class="tile is-ancestor">
        <div class="tile is-9 is-parent">
          <div class="tile is-child pr-3">
            <div class="panel is-success">
              <p class="panel-heading">
                <b-icon icon="emoticon-happy"></b-icon>
                Más accesos
              </p>
              <div class="p-5">
                <div class="columns">
                  <div class="column is-8">
                    <strong>Centro</strong>
                  </div>
                  <div class="column is-2 has-text-centered">
                    <strong>Accesos</strong>
                  </div>
                  <div class="column is-2 has-text-centered">
                    <strong>Usuarios</strong>
                  </div>
                </div>
                <div
                  class="columns p-0"
                  v-for="centro in centrosMas"
                  :key="centro.nombreCentro"
                  :value="centro.nombreCentro"
                >
                  <div class="column is-8 pt-0 pb-2">
                    <p>{{ centro.nombreCentro }}</p>
                  </div>
                  <div class="column is-2 has-text-centered pt-0 pb-2">
                    <p>{{ centro.numAccesos }}</p>
                  </div>
                  <div class="column is-2 has-text-centered pt-0 pb-2">
                    <p>{{ centro.numUsuarios }}</p>
                  </div>
                </div>
              </div>
              <div class="level-right p-3">
                <b-button
                  type="is-primary is-light"
                  outlined
                  icon-left="table"
                  class="mr-3"
                  @click="mostrarTablaMas"
                >
                  Ver tabla
                </b-button>
                <b-button
                  type="is-info is-light"
                  outlined
                  icon-left="chart-bar"
                  @click="mostrarGraficoMas"
                >
                  Ver gráficos
                </b-button>
              </div>
            </div>
          </div>
          <div class="tile is-child pl-3">
            <div class="panel is-danger">
              <p class="panel-heading">
                <b-icon icon="emoticon-sad"></b-icon>
                Menos accesos
              </p>
              <div class="p-5">
                <div class="columns">
                  <div class="column is-8">
                    <strong>Centro</strong>
                  </div>
                  <div class="column is-2 has-text-centered">
                    <strong>Accesos</strong>
                  </div>
                  <div class="column is-2 has-text-centered">
                    <strong>Usuarios</strong>
                  </div>
                </div>
                <div
                  class="columns p-0"
                  v-for="centro in centrosMenos"
                  :key="centro.nombreCentro"
                  :value="centro.nombreCentro"
                >
                  <div class="column is-8 pt-0 pb-2">
                    <p>{{ centro.nombreCentro }}</p>
                  </div>
                  <div class="column is-2 has-text-centered pt-0 pb-2">
                    <p>{{ centro.numAccesos }}</p>
                  </div>
                  <div class="column is-2 has-text-centered pt-0 pb-2">
                    <p>{{ centro.numUsuarios }}</p>
                  </div>
                </div>
              </div>
              <div class="level-right p-3">
                <b-button
                  type="is-primary is-light"
                  outlined
                  icon-left="table"
                  class="mr-3"
                  @click="mostrarTablaMenos"
                >
                  Ver tabla
                </b-button>
                <b-button
                  type="is-info is-light"
                  outlined
                  icon-left="chart-bar"
                  @click="mostrarGraficoMenos"
                >
                  Ver gráficos
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-3 is-parent">
          <div class="tile is-child panel is-primary">
            <p class="panel-heading">
              <b-icon icon="calendar"></b-icon>
              Periodo
            </p>
            <div class="p-5">
              <b-field label="Selecciona un rango de fechas" id="rangoFechas">
                <b-datepicker
                  placeholder="Pulsa para seleccionar..."
                  v-model="fechas"
                  range
                  icon="calendar-today"
                  @input="cambiarFecha"
                >
                </b-datepicker>
              </b-field>
              <b-field label="Entorno">
                <b-select
                  placeholder="Entorno"
                  icon="server"
                  v-model="entorno"
                  @input="cargarDatos"
                >
                  <option
                    v-for="entorno in entornos"
                    :key="entorno.id"
                    :value="entorno.id"
                  >
                    {{ entorno.nombre }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5" v-if="mostrarTabla">
        <div class="level-left">
          <p class="title is-5 is-uppercase borde-puntos" :class="colorTabla">
            {{ tituloTabla }}
          </p>
        </div>
        <div class="level-right">
          <b-switch v-model="seleccionarColumnas"
            >Seleccionar Columnas</b-switch
          >
          <b-switch v-model="mostrarFiltros" class="ml-4"
            >Mostrar filtros</b-switch
          >
          <b-button type="is-primary" class="ml-4">
            <span class="icon mr-2">
              <i class="far fa-arrow-alt-circle-down"></i>
            </span>
            Exportar
          </b-button>
        </div>
        <tabla-accesos
          :datos="datosTabla"
          :mostrarFiltros="mostrarFiltros"
          :seleccionarColumnas="seleccionarColumnas"
          :ordenColumna="ordenColumna"
        ></tabla-accesos>
      </div>
      <div class="columns">
        <div class="column mt-5 is-6" v-if="mostrarGrafico">
          <div class="level-left mb-5">
            <p
              class="title is-5 is-uppercase borde-puntos"
              :class="colorGrafico"
            >
              {{ tituloGrafico }}
            </p>
          </div>
          <graficos-accesos
            :datosTasa="datosTasa"
            :titulo="tituloGraficoBarras"
            :labelsCentros="labelsCentros"
          ></graficos-accesos>
        </div>
        <div class="column mt-5 is-6" v-if="mostrarGrafico">
          <div class="level-left mb-5">
            <p
              class="title is-5 is-uppercase borde-puntos"
              :class="colorGraficoMes"
            >
              {{ tituloGraficoMes }}
            </p>
          </div>
          <graficos-accesos-mes
            :datosTasa="datosTasaMes"
            :titulo="tituloGraficoBarrasMes"
            :labelsCentros="labelsCentrosMes"
            :labelsMeses="labelsMeses"
            :ordenMeses="ordenMeses"
            :mostrarMas="mostrarMas"
          ></graficos-accesos-mes>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { esLector } from "@/services/session";
import TablaAccesos from "./TablaAccesos.vue";
import GraficosAccesos from "./GraficosAccesos.vue";
import GraficosAccesosMes from "./GraficosAccesosMes.vue";
import apiDashboard from "@/services/dashboard";
import apiEntornos from "@/services/entornos.js";

export default {
  components: { TablaAccesos, GraficosAccesos, GraficosAccesosMes },
  name: "DashboardPage",
  computed: {
    esLector,
  },
  data() {
    return {
      datosTabla: [],
      datosUsuarios: [],
      datosAccesos: [],
      datosTasa: [],
      datosTasaMes: [],
      mostrarFiltros: false,
      seleccionarColumnas: false,
      tituloTabla: "",
      tituloGrafico: "",
      tituloGraficoMes: "",
      colorTabla: "",
      colorGrafico: "",
      colorGraficoMes: "",
      mostrarTabla: false,
      mostrarGrafico: false,
      tablaActual: "",
      graficoActual: "",
      tituloGraficoBarras: "",
      tituloGraficoBarrasMes: "",
      fechas: [this.subtractMonths(1, new Date()), new Date()],
      centrosMas: [],
      centrosMenos: [],
      labelsCentros: [],
      labelsMeses: [],
      labelsCentrosMas: [],
      labelsCentrosMenos: [],
      ordenColumna: "DESC",
      ordenMeses: {},
      ordenMesesMas: {},
      ordenMesesMenos: {},
      recargar: false,
      mostrarMas: true,
      entornos: [],
      entorno: 0,
    };
  },
  methods: {
    async mostrarTablaMas() {
      if (!this.recargar) {
        this.mostrarTabla = false;
      }
      this.tituloTabla = "Centros con más accesos";
      this.colorTabla = "has-text-success-dark";
      this.datosTabla = this.ordenarTablaDesc(this.datosTabla);
      this.ordenColumna = ["tasaAcceso", "desc"];
      if (this.tablaActual == "mas" && !this.recargar) {
        this.mostrarTabla = false;
        this.tablaActual = "";
      } else {
        this.mostrarTabla = true;
        this.tablaActual = "mas";
      }
    },
    async mostrarTablaMenos() {
      if (!this.recargar) {
        this.mostrarTabla = false;
      }
      this.tituloTabla = "Centros con menos accesos";
      this.colorTabla = "has-text-danger-dark";
      this.datosTabla = this.ordenarTablaAsc(this.datosTabla);
      this.ordenColumna = ["tasaAcceso", "asc"];
      if (this.tablaActual == "menos" && !this.recargar) {
        this.mostrarTabla = false;
        this.tablaActual = "";
      } else {
        this.mostrarTabla = true;
        this.tablaActual = "menos";
      }
    },
    async mostrarGraficoMas() {
      if (!this.recargar) {
        this.mostrarGrafico = false;
      }

      this.mostrarMas = true;
      this.tituloGrafico = "Centros con más accesos";
      this.tituloGraficoMes = "Centros con más accesos por Mes";
      this.tituloGraficoBarras =
        "Número de Accesos / número de usuarios: Diagrama de barras";
      this.tituloGraficoBarrasMes =
        "Número de Accesos / número de usuarios: Diagrama de barras por Mes";
      this.colorGrafico = "has-text-success-dark";
      this.colorGraficoMes = "has-text-success-dark";
      this.datosUsuarios = this.datosUsuariosMas;
      this.datosAccesos = this.datosAccesosMas;
      this.datosTasa = this.datosTasaMas;
      this.datosTasaMes = this.datosTasaMasMes;
      this.labelsMeses = this.obtenerMeses(this.centrosMasTasaMes);
      this.ordenMeses = this.ordenMesesMas;
      this.labelsCentrosMes = this.labelsCentrosMasMes;
      this.labelsCentros = this.labelsCentrosMas;

      if (this.graficoActual == "mas" && !this.recargar) {
        this.graficoActual = "";
      } else {
        this.mostrarGrafico = true;
        this.graficoActual = "mas";
      }
    },
    async mostrarGraficoMenos() {
      if (!this.recargar) {
        this.mostrarGrafico = false;
      }
      this.mostrarMas = false;
      this.tituloGrafico = "Centros con menos accesos";
      this.tituloGraficoMes = "Centros con menos accesos por Mes";
      this.tituloGraficoBarras =
        "Número de Accesos / número de usuarios: Diagrama de barras";
      this.tituloGraficoBarrasMes =
        "Número de Accesos / número de usuarios: Diagrama de barras por Mes";
      this.colorGrafico = "has-text-danger-dark";
      this.colorGraficoMes = "has-text-danger-dark";
      this.datosUsuarios = this.datosUsuariosMenos;
      this.datosAccesos = this.datosAccesosMenos;
      this.datosTasa = this.datosTasaMenos;
      this.datosTasaMes = this.datosTasaMenosMes;
      this.labelsMeses = this.obtenerMeses(this.centrosMenosTasaMes);
      this.ordenMeses = this.ordenMesesMas;
      this.labelsCentrosMes = this.labelsCentrosMenosMes;
      this.labelsCentros = this.labelsCentrosMenos;

      if (this.graficoActual == "menos" && !this.recargar) {
        this.graficoActual = "";
      } else {
        this.mostrarGrafico = true;
        this.graficoActual = "menos";
      }
    },
    subtractMonths(numOfMonths, date = new Date()) {
      date.setMonth(date.getMonth() - numOfMonths);

      return date;
    },
    async cambiarFecha() {
      if (this.mostrarGrafico) this.mostrarGrafico = !this.mostrarGrafico;
      this.recargar = true;
      await this.cargarDatos();
      if (this.graficoActual == "mas") {
        await this.mostrarGraficoMas();
      } else if (this.graficoActual == "menos") {
        await this.mostrarGraficoMenos();
      }
      this.recargar = false;
    },
    async cargarDatos() {
      var centrosAnalizar = await apiDashboard.obtenerCentrosUsuario(
        this.fechas,
        this.entorno
      );
      this.centrosMas = centrosAnalizar.masAccesos;
      this.centrosMenos = centrosAnalizar.menosAccesos;

      this.centrosMasTasa = centrosAnalizar.masTasa;
      this.centrosMenosTasa = centrosAnalizar.menosTasa;

      this.centrosMasTasaMes = centrosAnalizar.masTasaMes;
      this.centrosMenosTasaMes = centrosAnalizar.menosTasaMes;

      var datosGrafico = this.prepararDatosGrafico(this.centrosMasTasa, true);
      this.labelsCentrosMas = datosGrafico.labels;
      this.datosUsuariosMas = datosGrafico.datosUsuarios;
      this.datosAccesosMas = datosGrafico.datosAccesos;
      this.datosTasaMas = datosGrafico.datosTasa;

      datosGrafico = this.prepararDatosGrafico(this.centrosMenosTasa, false);
      this.labelsCentrosMenos = datosGrafico.labels;
      this.datosUsuariosMenos = datosGrafico.datosUsuarios;
      this.datosAccesosMenos = datosGrafico.datosAccesos;
      this.datosTasaMenos = datosGrafico.datosTasa;

      datosGrafico = this.prepararDatosGraficoMes(this.centrosMasTasaMes, true);
      this.labelsCentrosMasMes = datosGrafico.labels;
      this.datosTasaMasMes = datosGrafico.datosTasa;
      this.ordenMesesMas = datosGrafico.ordenMeses;

      datosGrafico = this.prepararDatosGraficoMes(
        this.centrosMenosTasaMes,
        true
      );
      this.labelsCentrosMenosMes = datosGrafico.labels;
      this.datosTasaMenosMes = datosGrafico.datosTasa;
      this.ordenMesesMenos = datosGrafico.ordenMeses;

      if (this.graficoActual == "mas") {
        this.labelsCentros = this.labelsCentrosMas;
        this.datosUsuarios = this.datosUsuariosMas;
        this.datosAccesos = this.datosAccesosMas;
        this.datosTasa = this.datosTasaMas;

        this.labelsCentrosMes = this.labelsCentrosMasMes;
        this.datosTasaMesMas = this.datosTasaMasMes;
      } else if (this.graficoActual == "menos") {
        this.labelsCentros = this.labelsCentrosMenos;
        this.datosUsuarios = this.datosUsuariosMenos;
        this.datosAccesos = this.datosAccesosMenos;
        this.datosTasa = this.datosTasaMenos;

        this.labelsCentrosMes = this.labelsCentrosMenosMes;
        this.datosTasaMesMenos = this.datosTasaMenosMes;
      }

      apiDashboard.obtenerDatosTabla(this.fechas, this.entorno).then((resultado) => {
        this.datosTabla = resultado.datosTabla;
        if (this.tablaActual == "mas") {
          this.datosTabla = this.ordenarTablaDesc(this.datosTabla);
          this.ordenColumna = ["tasaAcceso", "desc"];
        } else if (this.tablaActual == "menos") {
          this.datosTabla = this.ordenarTablaAsc(this.datosTabla);
          this.ordenColumna = ["tasaAcceso", "asc"];
        }
      });
    },
    prepararDatosGrafico(datosCentros, esMas) {
      var labels = [];
      var datosUsuarios = [];
      var datosAccesos = [];
      var datosTasa = [];
      var datosOrdenados = {};

      for (var i = 0; i < datosCentros.length; i++) {
        labels.push(datosCentros[i].nombreCentro);
        datosUsuarios.push(datosCentros[i].numUsuarios);
        datosAccesos.push(datosCentros[i].numAccesos);
        datosTasa.push(datosCentros[i].tasaAcceso);
      }

      if (esMas) {
        datosOrdenados = this.ordenarGraficoMas(labels, datosTasa);
      } else {
        datosOrdenados = this.ordenarGraficoMenos(labels, datosTasa);
      }

      return {
        labels: datosOrdenados.labels,
        datosUsuarios: datosUsuarios,
        datosAccesos: datosAccesos,
        datosTasa: datosOrdenados.datosTasa,
      };
    },
    prepararDatosGraficoMes(datosCentros, esMas) {
      var labels = {};
      var ordenMeses = {};
      var centros = [];
      var datosTasa = {};
      var labelsCentros = [];
      var datasetsCentros = [];
      var j = 0;
      var k = 0;

      for (var mes in datosCentros) {
        var datosTasaMes = [];
        var labelsMes = [];
        var datosOrdenados;
        var datosMes = datosCentros[mes];
        ordenMeses[mes] = j;
        for (var i = 0; i < datosMes.length; i++) {
          if (
            !Object.prototype.hasOwnProperty.call(centros, datosMes[i].idCentro)
          )
            centros[datosMes[i].idCentro] = datosMes[i].nombreCentro;
          labelsMes.push(datosMes[i].idCentro);
          datosTasaMes.push(datosMes[i].tasaAcceso);
        }
        if (esMas) {
          datosOrdenados = this.ordenarGraficoMas(labelsMes, datosTasaMes);
        } else {
          datosOrdenados = this.ordenarGraficoMenos(labelsMes, datosTasaMes);
        }
        labels[mes] = datosOrdenados.labels;
        datosTasa[mes] = datosOrdenados.datosTasa;
        j++;
      }

      for (var centro in centros) {
        k = 0;
        for (var label in labels) {
          var datosLabel = labels[label];
          for (var datoLabel in datosLabel) {
            var idCentro = datosLabel[datoLabel];
            if (centro == idCentro) {
              var datoTasa = datosTasa[label][datoLabel];
              if (
                !Object.prototype.hasOwnProperty.call(datasetsCentros, idCentro)
              )
                datasetsCentros[idCentro] = [];
              if (
                !Object.prototype.hasOwnProperty.call(labelsCentros, idCentro)
              )
                labelsCentros[idCentro] = [];
              datasetsCentros[idCentro][k] = datoTasa;
              labelsCentros[idCentro][k] = centros[idCentro];
            }
          }
          k++;
        }
      }

      return {
        labels: labelsCentros,
        datosTasa: datasetsCentros,
        ordenMeses: ordenMeses,
      };
    },
    ordenarTablaAsc(tabla) {
      return tabla.sort(function (e1, e2) {
        if (e1.tasaAcceso > e2.tasaAcceso) {
          return 1;
        }
        if (e1.tasaAcceso < e2.tasaAcceso) {
          return -1;
        }
        return 0;
      });
    },
    ordenarTablaDesc(tabla) {
      return tabla.sort(function (e1, e2) {
        if (e2.tasaAcceso > e1.tasaAcceso) {
          return 1;
        }
        if (e2.tasaAcceso < e1.tasaAcceso) {
          return -1;
        }
        return 0;
      });
    },
    ordenarGraficoMas(labels, datos) {
      var labelsOrdenadas = [];

      var mapped = datos.map(function (el, i) {
        return { index: i, value: el };
      });

      mapped.sort(function (e1, e2) {
        if (e1.value < e2.value) {
          return 1;
        }
        if (e1.value > e2.value) {
          return -1;
        }
        return 0;
      });

      var datosOrdenados = mapped.map(function (el) {
        labelsOrdenadas.push(labels[el.index]);
        return datos[el.index];
      });

      return {
        labels: labelsOrdenadas,
        datosTasa: datosOrdenados,
      };
    },
    ordenarGraficoMenos(labels, datos) {
      var labelsOrdenadas = [];

      var mapped = datos.map(function (el, i) {
        return { index: i, value: el };
      });

      mapped.sort(function (e1, e2) {
        if (e1.value > e2.value) {
          return 1;
        }
        if (e1.value < e2.value) {
          return -1;
        }
        return 0;
      });

      var datosOrdenados = mapped.map(function (el) {
        labelsOrdenadas.push(labels[el.index]);
        return datos[el.index];
      });

      return {
        labels: labelsOrdenadas,
        datosTasa: datosOrdenados,
      };
    },
    obtenerMeses(datosTasaMasMes) {
      var meses = [];
      for (var mes in datosTasaMasMes) {
        if (datosTasaMasMes[mes].length > 0) meses.push(mes);
      }
      return meses;
    },
    async cargarEntornos() {
      this.entornos = await apiEntornos.listarActivosEstadisticas();
    },
  },
  async mounted() {
    await this.cargarEntornos();
    this.cargarDatos();
  },
  watch: {},
};
</script>

<style>
#rangoFechas .datepicker .dropdown-content {
  width: fit-content;
}

.borde-puntos {
  border-bottom: 1px dotted;
  padding: 0 8px 8px 4px;
}
</style>
