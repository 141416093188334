<template>
  <div>
    <b-table
      :data="isEmpty ? [] : data"
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      default-sort="id"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      :page-input="hasInput"
      :pagination-order="paginationOrder"
      :page-input-position="inputPosition"
      :debounce-page-input="inputDebounce"
    >
      <b-table-column
        field="id"
        label="Num."
        width="40"
        numeric
        v-slot="props"
        sortable
      >
        {{ props.row.id }}
      </b-table-column>

      <b-table-column
        field="nombre"
        label="Centro"
        v-slot="props"
        sortable
        :searchable="mostrarFiltros"
      >
        {{ props.row.nombre }}
      </b-table-column>

      <b-table-column
        field="responsable"
        label="Responsable"
        v-slot="props"
        sortable
        :searchable="mostrarFiltros"
      >
        {{ props.row.responsable }}
      </b-table-column>

      <b-table-column
        field="fechaAlta"
        label="Fecha de Alta"
        v-slot="props"
        sortable
        :searchable="mostrarFiltros"
      >
        {{ fechaFormateada(props.row.fechaAlta) }}
      </b-table-column>

      <b-table-column
        field="sede"
        label="Sede"
        v-slot="props"
        sortable
        :searchable="mostrarFiltros"
      >
        {{ props.row.sede }}
      </b-table-column>

      <b-table-column
        field="pais"
        label="País"
        v-slot="props"
        sortable
        :searchable="mostrarFiltros"
      >
        {{ props.row.pais }}
      </b-table-column>

      <b-table-column
        label="Estado"
        v-slot="props"
        field="estado"
        sortable
        :searchable="mostrarFiltros"
      >
        <span>
          {{ props.row.estado }}
        </span>
      </b-table-column>

      <b-table-column label="Acciones" v-slot="props">
        <b-dropdown position="is-bottom-left" aria-role="menu">
          <template #trigger="{ active }">
            <b-button
              type="is-primary"
              icon-left="menu"
              :icon-right="active ? 'menu-up' : 'menu-down'"
              @click="pulsarLista"
            />
          </template>

          <b-dropdown-item value="ficha" aria-role="menuitem" has-link>
            <router-link :to="`/gestion-centros/editar/ficha/${props.row.id}/${entorno}`">
              <b-icon icon="ballot" class="mr-2"></b-icon>
              Ficha
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item value="configuracion" aria-role="menuitem" has-link>
            <router-link :to="`/gestion-centros/editar/configuracion/${props.row.id}/${entorno}`">
              <b-icon icon="cogs" class="mr-2"></b-icon>
              Configuración
            </router-link>
          </b-dropdown-item>
          <!-- <b-dropdown-item value="procesos" aria-role="menuitem" has-link>
            <router-link
              :to="`/gestion-centros/ficha/${props.row.id}/${entorno}`"
            >
              <b-icon icon="package-variant-closed" class="mr-2"></b-icon>
              Paquetes
            </router-link>
          </b-dropdown-item> -->
          <b-dropdown-item value="aplicaciones" aria-role="menuitem" has-link>
            <router-link :to="`/gestion-centros/editar/aplicaciones/${props.row.id}/${entorno}`">
              <b-icon icon="toolbox" class="mr-2"></b-icon>
              Aplicaciones
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item value="expedientes" aria-role="menuitem" has-link>
            <router-link :to="`/gestion-centros/editar/expedientes/${props.row.id}/${entorno}`">
              <b-icon icon="package-variant-closed" class="mr-2"></b-icon>
              Tipos de Expedientes
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item value="integraciones" aria-role="menuitem" has-link>
            <router-link :to="`/gestion-centros/editar/integraciones/${props.row.id}/${entorno}`">
              <b-icon icon="api" class="mr-2"></b-icon>
              Integraciones
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item
            value="riesgos"
            aria-role="menuitem"
            @click="mostrarRegistroCentro(props.row.id, entorno)"
          >
            <b-icon icon="math-log" class="mr-2"></b-icon>
            Registro Creación
          </b-dropdown-item>
          <!-- <b-dropdown-item value="eliminar" aria-role="menuitem" has-link>
            <router-link :to="`/centros/eliminar/${props.row.id}/${entorno}`">
              <b-icon icon="delete" type="is-danger" class="mr-2"></b-icon>
              Eliminar Centro
            </router-link>
          </b-dropdown-item> -->
        </b-dropdown>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">No hay resultados</div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { formatDateTabla } from "@/services/date";
import apiCentros from "@/services/centros.js";
export default {
  props: {
    resultado: {
      centros: [],
      resultadosPorPagina: 10,
      pagina: 1,
    },
    mostrarFiltros: Boolean,
    entorno: {},
  },
  computed: {
    data() {
      return this.resultado.centros;
    },
    currentPage: {
      get() {
        return this.paginaActual;
      },
      set(value) {
        this.paginaActual = value;
      },
    },
    perPage() {
      return this.resultado.resultadosPorPagina;
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      paginaActual: null,
    };
  },
  methods: {
    editarCentro(centroId) {
      this.$router.push("/centros/editar/" + centroId);
    },
    eliminarCentro(centroId) {
      this.$emit("eliminarCentro", centroId);
    },
    fechaFormateada(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    pulsarLista: function (event) {
      if (event) {
        var elemento = event.target.closest("div.dropdown-trigger").nextSibling
          .nextSibling;
        elemento.style.position = "fixed";
        elemento.style.top = event.clientY / 2.5 + "px";
        elemento.style.right =
          Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          ) -
          event.target.closest("div.dropdown-trigger").getBoundingClientRect()
            .right +
          70 +
          "px";
      }
    },
    filtrarEstado(row, input) {
      var estado = this.nombreEstado(row.estado).toLowerCase();
      return input && estado.includes(input.toLowerCase());
    },
    async mostrarRegistroCentro(centroId, entornoId) {
      console.log(centroId + " - " + entornoId);
      var res = await apiCentros.cargarRegistroCreacionCentro(
        entornoId,
        centroId
      );
      if (res.error === undefined) {
        this.mostrarMensaje(
          "Registro de creación de centro",
          "is-info",
          res.resultado.resultado
        );
      }
    },
    mostrarMensaje(msg, tipo, resumen) {
      this.$buefy.dialog.alert({
        title: msg,
        message: resumen,
        type: tipo,
        hasIcon: false,
        icon: "info-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
  },
  async mounted() {
    this.paginaActual = this.resultado.paginaActual;
  },
};
</script>

<style>
.dropdown.dropdown-menu-animation.is-bottom-left.is-active.is-mobile-modal
  .button.is-primary {
  background-color: #888;
}

div.is-disabled > span {
  padding: 0.375rem 0.8rem;
  color: #888;
  line-height: 2.2rem;
}
</style>
