export const sessionStore = {
  nombre: null,
  email: null,
  rol: null,
  avisos: null,
};

export function sessionSet(data) {
  Object.assign(sessionStore, data);
}

export function sessionClear() {
  sessionSet({
    nombre: null,
    email: null,
    rol: null,
    avisos: null,
  });
  console.log("Sesion limpiada", sessionStore);
}
