<template>
  <app-layout>
    <div class="box container is-fluid minh-100">
      <div class="level mb-0">
        <b-breadcrumb class="level-left mb-0">
          <b-breadcrumb-item tag="router-link" to="/entornos" active
            >Listado de Entornos</b-breadcrumb-item
          >
        </b-breadcrumb>
        <div class="level-right mr-2">
          <b-button type="is-success" class="mr-2" tag="router-link" to="/entornos/crear">
            <span class="icon mr-2">
              <i class="fas fa-plus-circle"></i>
            </span>
            Nuevo Entorno
          </b-button>
        </div>
      </div>
      <div class="divider is-primary is-right mt-2"></div>
      <div class="level-right">
        <b-switch v-model="mostrarFiltros">Mostrar filtros</b-switch>
      </div>
      <tabla :resultado="resultado" @eliminarEntorno="eliminarEntorno" :mostrarFiltros="mostrarFiltros"></tabla>
    </div>
  </app-layout>
</template>

<script>
import { esLector } from "@/services/session";
import api from "@/services/entornos.js";
import Tabla from "./TablaEntornos.vue";

export default {
  components: { Tabla },
  name: "ListarEntornos",
  computed: {
    esLector,
  },
  data() {
    return {
      resultado: {
        entornos: [],
        resultadosPorPagina: 10,
        pagina: 1,
      },
      mostrarFiltros: false,
    };
  },
  methods: {
    listar() {
      return api.listar(this.$route.query).then((respuesta) => {
        this.resultado.entornos = respuesta;
      });
    },
    eliminarEntorno(entornoId) {
      this.confirmarEliminar(entornoId);
    },
    mostrarMensaje(msg, tipo) {
      this.$buefy.toast.open({
        message: msg,
        type: tipo,
      });
    },
    confirmarEliminar(entornoId) {
      this.$buefy.dialog.confirm({
        title: "Atención",
        message: "¿Desea eliminar el entorno?",
        confirmText: "Si",
        cancelText: "No",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          api.eliminar(entornoId).then((respuesta) => {
            if (respuesta.error !== undefined) {
              var msg = "Se ha producido un error: <br>" + respuesta.error;
              var tipo = "is-danger";
              this.mostrarMensaje(msg, tipo);
              return;
            }
            this.mostrarMensaje(respuesta.msg, "is-success");
            this.listar();
          });
        },
      });
    }
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
