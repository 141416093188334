<template>
  <form class="box is-" @submit.prevent="iniciarSesion">
    <h3 class="subtitle">Inicio de sesión</h3>
    <b-field label="Email" :type="tipoMensaje" :message="mensajeError">
      <b-input
        type="email"
        value=""
        maxlength="80"
        icon="email"
        v-model="email"
        autofocus
      >
      </b-input>
    </b-field>
    <b-field label="Contraseña">
      <b-input
        type="password"
        value=""
        password-reveal
        icon="lock"
        v-model="clave"
      >
      </b-input>
    </b-field>
    <b-button
      type="is-primary"
      native-type="submit"
      class="mt-5 is-fullwidth"
    >
      Aceptar
      <span class="icon ml-2">
        <i class="fas fa-sign-in-alt"></i>
      </span>
    </b-button>
  </form>
</template>

<script>
import { login } from "@/services/login.js";

export default {
  data() {
    return {
      email: "",
      clave: "",
      mensajeError: "",
      tipoMensaje: "",
    };
  },
  methods: {
    iniciarSesion() {
      login(this.email, this.clave).then((response) => {
        if (response.error != undefined) {
          this.mensajeError = "Los datos introducidos son incorrectos.";
          this.tipoMensaje = "is-danger";
          this.$buefy.toast.open({
            message: "Los datos introducidos son incorrectos.",
            type: "is-danger",
          });
          return;
        }
        this.$emit("ok");
        this.$parent.$parent.$parent.iniciarSesion();
      });
    },
  },
};
</script>
