import { ADMIN, CONSULTOR, LECTOR } from "@/enums/Roles";
import { sessionClear, sessionSet, sessionStore } from "@/store";
import { backend } from "./backend";

export function iniciarSesion({ nombre, email, rol, avisos }) {
  sessionSet({ nombre, email, rol, avisos });
}

export function haySesion() {
  return Boolean(sessionStore.nombre);
}

export function esAdmin() {
  return sessionStore.rol == ADMIN;
}

export function esConsultor() {
  return sessionStore.rol == CONSULTOR;
}

export function esLector() {
  return sessionStore.rol == LECTOR;
}

export function validarRoles(roles_requeridos){
  if(esAdmin()) {
    return true;
  }
  return roles_requeridos.includes(sessionStore.rol);
}

export function numAvisos() {
  return sessionStore.avisos;
}

/**
 * Realiza el login
 */
export async function login(email, clave) {
  const response = await backend.post("login", { email, clave });
  return response.data;
}

/**
 * Recupera la sesión si el usuario estaba activo
 */
export async function recover() {
  const response = await backend.get("acceso/recuperar");
  return response.data;
}

/**
 * Realiza el logout
 */
export async function logout() {
  sessionClear();
  await backend.get("acceso/logout");
}

// export default { login, recover, logout };
