import { listado } from "./listado";
import { backend } from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    estado: 0,
  };
}

function listar(params) {
  return listado("paises/listar", params);
}

function autocompletar(params) {
  return listado("paises/autocompletar", params);
}

function cargar(id) {
  return backend.get("paises/cargar&id=" + id).then((response) => {
    var data = response;
    data.pais.estado = Boolean(response.pais.estado);
    return data;
  });
}

function cargarConIds(params) {
  var data = {
    "paisesIds": params.paisesIds
  };
  return backend.post("paises/cargarConIds", { data }).then((response) => {
    return response;
  });
}

function insertar(pais) {
  return backend.post("paises/insertar", {
    pais,
  });
}

function actualizar(pais) {
  return backend.post("paises/actualizar", {
    pais,
  });
}

function eliminar(id) {
  return backend.post("paises/eliminar", { id });
}

export default {
  crear,
  listar,
  cargar,
  insertar,
  actualizar,
  eliminar,
  cargarConIds,
  autocompletar,
};
