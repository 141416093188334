const baseUrl = process.env.VUE_APP_ENDPOINT;

class SimpleObserver {
  constructor() {
    this.listeners = [];
  }
  listen(fn) {
    this.listeners.push(fn);
  }
  trigger(data) {
    this.listeners.forEach((fn) => fn(data));
  }
}

class Backend {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.requestObserver = new SimpleObserver();
    this.responseObserver = new SimpleObserver();
  }

  async get(url, params) {
    const response = await this.call({
      url: this.buildUrl(url, params),
      credentials: "same-origin",
      method: "GET",
    });
    return await response.json();
  }

  async download(url, params, nombreArchivo) {
    const response = await this.call({
      url: this.buildUrl(url, params),
      credentials: "same-origin",
      method: "GET",
    });
    return await response.blob().then((data) => {
      var url = window.URL.createObjectURL(data),
        anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = nombreArchivo;
      anchor.click();

      window.URL.revokeObjectURL(url);
    });
  }

  async post(url, data, params) {
    const response = await this.call({
      url: this.buildUrl(url, params),
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  }

  async call(request) {
    this.requestObserver.trigger(request);
    const response = await fetch(request.url, request);
    this.responseObserver.trigger(response);
    return response;
  }

  onRequest(fn) {
    this.requestObserver.listen(fn);
  }

  onResponse(fn) {
    this.responseObserver.listen(fn);
  }

  buildUrl(url, params) {
    let final_url = this.baseUrl + url;
    if (params) {
      final_url += "&" + new URLSearchParams(params);
    }
    return final_url;
  }
}

export const backend = new Backend(baseUrl);
