import {backend} from "./backend";

export async function listado(endpoint, params, filtros) {
  params = params || {};
  filtros = filtros || {};

  var query = {
    pagina: 1,
    filtros: filtros,
    orden: {},
  };

  if ("filtros" in params) {
    for (const filtro in params.filtros) {
      query.filtros[filtro] = params.filtros[filtro];
    }
  }

  if ("busqueda" in params) {
    query.filtros.busqueda = params.busqueda;
  }

  if ("estados" in params) {
    query.filtros.estados = params.estados;
  }

  if ("fecha" in params) {
    query.filtros.fecha = params.fecha;
  }

  if ("fechaAlta" in params) {
    query.filtros.fechaAlta = params.fechaAlta;
  }

  if ("fechaRenovacion" in params) {
    query.filtros.fechaRenovacion = params.fechaRenovacion;
  }

  if ("vencimiento" in params) {
    query.filtros.vencimiento = params.vencimiento;
  }

  if ("paises" in params) {
    var paises = [];
    if (Array.isArray(params.paises)) {
      paises = params.paises;
    } else {
      paises.push(params.paises);
    }
    query.filtros.paises = paises;
  }

  if ("clientes" in params) {
    var clientes = [];
    if (Array.isArray(params.clientes)) {
      clientes = params.clientes;
    } else {
      clientes.push(params.clientes);
    }
    query.filtros.clientes = clientes;
  }

  if ("campanias" in params) {
    var campanias = [];
    if (Array.isArray(params.campanias)) {
      campanias = params.campanias;
    } else {
      campanias.push(params.campanias);
    }
    query.filtros.campanias = campanias;
  }

  if ("usuarios" in params) {
    var usuarios = [];
    if (Array.isArray(params.usuarios)) {
      usuarios = params.usuarios;
    } else {
      usuarios.push(params.usuarios);
    }
    query.filtros.usuarios = usuarios;
  }

  if ("enPapelera" in params) {
    query.filtros.enPapelera = params.enPapelera;
  }

  if ("pagina" in params) {
    query.pagina = params.pagina;
  }

  if ("orden" in params) {
    query.orden = {
      [params.orden]: params.dir || "ASC",
    };
  }

  const response = await backend.post(endpoint, { pagina: query.pagina, filtros: query.filtros, orden: query.orden, entornoId: params.entornoId, path: params.path });
  return response;
}
